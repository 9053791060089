import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  timeZoneList,
  resetErrorAndSuccessState,
} from '../store/actions/userActions'
import {
  availableDeviceList,
  updateAvailableDeviceList,
  modifyDeviceGroup,
  fail,
  deviceGroupDetails,
  deviceGroupDetailsSuccess,
} from '../store/actions/deviceGroupActions'
import { withSnackbar } from 'notistack'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Switch from '@material-ui/core/Switch'
import moment from 'moment'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import DatePicker from 'react-datepicker'

import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import {
  TextField,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  FormControl,
} from '@material-ui/core'
import ForwardIcon from '@material-ui/icons/Forward'
import '../App.css'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'

// import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab'
import { getTimes } from '../helper/commonHelper'
import dictionary from '../store/dictionary.json'

const CheckboxWithGreenCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 5,
        top: 5,
        height: 14,
        width: 14,
        position: 'absolute',
        backgroundColor: '#1e4ca1',
        zIndex: 0,
      },
    },
  },
  checked: {},
})(Checkbox)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(0),
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
    '&:last-child': {
      borderRight: 'solid 1px #cccccc',
    },
  },
}))

const ModifyDeviceGroup = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const classes = useStyles()
  const { timeZone_List } = useSelector((state) => state.user)
  const { available_Devices } = useSelector((state) => state.deviceGroup)
  const { device_GroupDetails } = useSelector((state) => state.deviceGroup)
  const [startDate, setStartDate] = useState(new Date())
  const { deviceGroupOldDetails } = useSelector((state) => state.deviceGroup)

  const [dispatchStartTime, setDispatchStartTime] = React.useState()
  const [dispatchEndTime, setDispatchEndTime] = React.useState()

  useEffect(() => {
    var strtDate1 = new Date('2022-02-02 ' + device_GroupDetails.end_at)
    //setStartDate(strtDate1);
  }, [startDate])

  const dispatch = useDispatch()
  const history = useHistory()

  // const [deviceGroup, setDeviceGroup] = useState([])
  // const [time, settime] = useState({startTime:"", endTime:""})
  const [timeList, settimeList] = useState([])
  const [oldGroupDetails, seOldGroupDetails] = useState(device_GroupDetails)

  useEffect(() => {
    if (
      device_GroupDetails.is_all_day &&
      device_GroupDetails.start_at &&
      device_GroupDetails.end_at
    ) {
      const StartTime = device_GroupDetails.start_at.split(':')
      const EndTime = device_GroupDetails.end_at.split(':')
      setDispatchStartTime(moment().hour(StartTime[0]).minute(StartTime[1]))
      setDispatchEndTime(moment().hour(EndTime[0]).minute(EndTime[1]))
    }
  }, [device_GroupDetails])

  const [values, setValues] = React.useState({
    startAt: '',
    endAt: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    fullName: '',
    displayName: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!device_GroupDetails.full_name) {
      isError = true
      formerr.full_name =
        dictionary[localStorage.getItem('language')].full_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }
    if (!device_GroupDetails.display_name) {
      isError = true
      formerr.display_name =
        dictionary[localStorage.getItem('language')].display_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }
    return isError
  }

  const handleChange = (prop) => (event) => {
    var value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (prop == 'status') {
      value = event.target.checked ? '3' : '1'
    }
    if (prop == 'is_all_day') {
      value = event.target.checked ? '1' : '0'
    }
    dispatch(
      deviceGroupDetailsSuccess({ ...device_GroupDetails, [prop]: value })
    )
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    if (
      device_GroupDetails.sch_monday === false &&
      device_GroupDetails.sch_tuesday === false &&
      device_GroupDetails.sch_wednesday === false &&
      device_GroupDetails.sch_thursday === false &&
      device_GroupDetails.sch_friday === false &&
      device_GroupDetails.sch_saturday === false &&
      device_GroupDetails.sch_sunday === false
    ) {
      dispatch(
        fail(dictionary[localStorage.getItem('language')].days_error_tooltip)
      )
      return
    } else if (device_GroupDetails.deviceDetails.length == 0) {
      dispatch(
        fail(
          dictionary[localStorage.getItem('language')]
            .select_device_error_tooltip
        )
      )
      return
    }

    const Data = {
      id: device_GroupDetails.id,
      display_name: device_GroupDetails.display_name,
      full_name: device_GroupDetails.full_name,
      description: device_GroupDetails.description,
      sch_monday: device_GroupDetails.sch_monday,
      sch_tuesday: device_GroupDetails.sch_tuesday,
      sch_wednesday: device_GroupDetails.sch_wednesday,
      sch_thursday: device_GroupDetails.sch_thursday,
      sch_friday: device_GroupDetails.sch_friday,
      sch_saturday: device_GroupDetails.sch_saturday,
      sch_sunday: device_GroupDetails.sch_sunday,
      start_at:
        device_GroupDetails.is_all_day == '1'
          ? null
          : device_GroupDetails.start_at,
      end_at:
        !device_GroupDetails.is_all_day == '1'
          ? null
          : device_GroupDetails.end_at,
      is_all_day: device_GroupDetails.is_all_day,
      time_zone_id: device_GroupDetails.time_zone_id,
      available_devices: device_GroupDetails.deviceDetails,
      status: device_GroupDetails.status,
    }

    dispatch(modifyDeviceGroup(Data, deviceGroupOldDetails))
  }

  useEffect(() => {
    const id = localStorage.getItem('tempDeviceGroupId')
    if (!id) {
      history.push({
        pathname: '/deviceGroups',
      })
    } else {
      dispatch(deviceGroupDetails(id))
      dispatch(timeZoneList())
      dispatch(availableDeviceList(id, true))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const handleChangeSchedule = (event, type) => {
    if (event) {
      if (type === 'start_at') {
        dispatch(
          deviceGroupDetailsSuccess({ ...device_GroupDetails, start_at: event })
        )
        // settime({...time, startTime: event})
      } else if (type === 'end_at') {
        dispatch(
          deviceGroupDetailsSuccess({ ...device_GroupDetails, end_at: event })
        )
        // settime({...time, endTime: event})
      } else if (type === 'zone') {
        dispatch(
          deviceGroupDetailsSuccess({
            ...device_GroupDetails,
            time_zone_id: event,
          })
        )
      }
    }
  }

  const handleDeviceClick = (event) => {
    dispatch(
      updateAvailableDeviceList(available_Devices.filter((e) => e !== event))
    )
    var details = { ...device_GroupDetails }
    details.deviceDetails.push(event)
    dispatch(deviceGroupDetailsSuccess(details))
  }

  const handleDeviceClickRemove = (event) => {
    dispatch(updateAvailableDeviceList([...available_Devices, event]))

    var details = { ...device_GroupDetails }
    details.deviceDetails = details.deviceDetails.filter((e) => e !== event)
    dispatch(deviceGroupDetailsSuccess(details))
  }

  useEffect(() => {
    settimeList(getTimes())
  }, [getTimes])

  const handleValueChange = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      dispatch(
        deviceGroupDetailsSuccess({ ...device_GroupDetails, start_at: val })
      )
      //setValues({ ...device_GroupDetails, start_at: val });
    }
    setDispatchStartTime(value)
  }

  const handleValueChange1 = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      dispatch(
        deviceGroupDetailsSuccess({ ...device_GroupDetails, end_at: val })
      )
      //setValues({ ...device_GroupDetails, end_at: val });
    }
    setDispatchEndTime(value)
  }

  const isTimeDisabled = useMemo(() => {
    if (device_GroupDetails['is_all_day'] == '1') {
      device_GroupDetails['start_at'] = null
      device_GroupDetails['end_at'] = null
      setDispatchStartTime(null)
      setDispatchEndTime(null)
      return true
    } else {
      // device_GroupDetails["start_at"] = null
      // device_GroupDetails["end_at"] = null
      // setDispatchStartTime("")
      // setDispatchEndTime("")
    }
    return false
  }, [device_GroupDetails])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header">
                <div className="row">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'no-wrap',
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className="mr-3"
                        style={{ width: '12rem' }}
                        onClick={handleSubmitClick}
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .save_changes_button
                        }
                        <i
                          className="fa fa-spinner fa-spin right"
                          style={{
                            display: props.loading ? 'block' : 'none',
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <Link to="/deviceGroups">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: '12rem' }}
                          size="medium"
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .cancel_and_return_to_device_groups_button
                          }
                        </Button>
                      </Link>
                    </div>
                    <Typography
                      className="mt-2 ml-2"
                      variant="subtitle2"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .deactivate_device_group_option
                        }
                      </strong>
                    </Typography>
                    <Switch
                      color="primary"
                      checked={device_GroupDetails.status == '3' ? true : false}
                      inputProps={{ 'aria-label': 'primary' }}
                      name="status"
                      onChange={handleChange('status')}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '-10px' }}>
                  <div className="col-md-4 my-3" style={{ zIndex: '10' }}>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .display_name_field_name +
                            ' ' +
                            dictionary[localStorage.getItem('language')]
                              .required_field_name
                          }
                          variant="outlined"
                          name="display_name"
                          value={device_GroupDetails.display_name}
                          onChange={handleChange('display_name')}
                          error={!!formValidation.display_name}
                          helperText={formValidation.display_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .full_name_field_name +
                            ' ' +
                            dictionary[localStorage.getItem('language')]
                              .required_field_name
                          }
                          variant="outlined"
                          name="full_name"
                          value={device_GroupDetails.full_name}
                          onChange={handleChange('full_name')}
                          error={!!formValidation.full_name}
                          helperText={formValidation.full_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .description_field_name
                          }
                          multiline
                          rows={10}
                          variant="outlined"
                          name="description"
                          value={device_GroupDetails.description}
                          onChange={handleChange('description')}
                        />
                      </div>
                    </div>
                    <Divider
                      variant="middle1"
                      className="mt-4 mb-4"
                      style={{ background: '#1E4CA1' }}
                    />
                    <div className="row">
                      <div className="col-md-12">
                        <Typography
                          variant="subtitle1"
                          className="text-center mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .access_days_and_times_header
                            }
                          </strong>
                        </Typography>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row rowtime mx-auto">
                        <form class="form-inline" role="form">
                          <div class="col-md-12">
                            <Box display="flex" alignItems="center">
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="is_all_day"
                                    color="primary"
                                    checked={
                                      device_GroupDetails.is_all_day == '1'
                                    }
                                  />
                                }
                                style={{
                                  marginLeft: -10,
                                  marginRight: -5,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                                onChange={handleChange('is_all_day')}
                                // disabled={isAllDayDisabled}
                                // InputProps={isAllDayDisabled ? { className: classes.disabledColor } : null}
                              />
                              <Typography
                                variant="subtitle2"
                                className="mr-4"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    alignItems: 'center',
                                  }}
                                >
                                  <strong>
                                    {
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].all_day_option
                                    }
                                  </strong>
                                </span>
                              </Typography>
                              <TimePicker
                                placeholder={
                                  dictionary[localStorage.getItem('language')]
                                    .start_time_field_name
                                }
                                showSecond={false}
                                focusOnOpen={true}
                                format="HH:mm"
                                value={dispatchStartTime}
                                onChange={handleValueChange}
                                style={{ width: 108 }}
                                clearText="Clear"
                                disabled={isTimeDisabled}
                                InputProps={
                                  isTimeDisabled
                                    ? { className: classes.disabledColor }
                                    : null
                                }
                              />

                              <TimePicker
                                placeholder={
                                  dictionary[localStorage.getItem('language')]
                                    .end_time_field_name
                                }
                                showSecond={false}
                                focusOnOpen={true}
                                format="HH:mm"
                                onChange={handleValueChange1}
                                value={dispatchEndTime}
                                style={{ width: 100 }}
                                clearText="Clear"
                                disabled={isTimeDisabled}
                                InputProps={
                                  isTimeDisabled
                                    ? { className: classes.disabledColor }
                                    : null
                                }
                              />
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="mt-3"
                            >
                              <FormControl variant="outlined">
                                <Autocomplete
                                  size="small"
                                  style={{
                                    marginLeft: 5,
                                    marginRight: -5,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    width: 300,
                                  }}
                                  id="combo-box-demo"
                                  value={device_GroupDetails.time_zone_id}
                                  onChange={(event, val) =>
                                    handleChangeSchedule(val, 'zone')
                                  }
                                  options={timeZone_List.map((it) => it.id)}
                                  getOptionLabel={(option) =>
                                    timeZone_List.find((it) => it.id === option)
                                      ?.full_time_zone
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].time_zone_field_name
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Box>
                          </div>
                        </form>
                      </div>
                      <div
                        class="col-md-12"
                        className="mt-3 mx-auto"
                        display="flex"
                      >
                        <FormControl
                          component="fieldset"
                          style={{ marginTop: 3 }}
                        >
                          {/* error={!!formValidationEkey.days} */}
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            className="radioGroup"
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .monday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="mon"
                                  color="primary"
                                  checked={device_GroupDetails.sch_monday}
                                />
                              }
                              value={device_GroupDetails.sch_monday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_monday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .tuesday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="tue"
                                  color="primary"
                                  checked={device_GroupDetails.sch_tuesday}
                                />
                              }
                              value={device_GroupDetails.sch_tuesday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_tuesday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .wednesday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="wed"
                                  color="primary"
                                  checked={device_GroupDetails.sch_wednesday}
                                />
                              }
                              value={device_GroupDetails.sch_wednesday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_wednesday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .thursday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="thu"
                                  color="primary"
                                  checked={device_GroupDetails.sch_thursday}
                                />
                              }
                              value={device_GroupDetails.sch_thursday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_thursday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .friday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="fri"
                                  color="primary"
                                  checked={device_GroupDetails.sch_friday}
                                />
                              }
                              value={device_GroupDetails.sch_friday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_friday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .saturday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="sat"
                                  color="primary"
                                  checked={device_GroupDetails.sch_saturday}
                                />
                              }
                              value={device_GroupDetails.sch_saturday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_saturday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .sunday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="sun"
                                  color="primary"
                                  checked={device_GroupDetails.sch_sunday}
                                />
                              }
                              value={device_GroupDetails.sch_sunday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sch_sunday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />
                          </RadioGroup>
                          {/* <FormHelperText>{formValidationEkey.days}</FormHelperText> */}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-5">
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-2 mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .available_devices_header
                            }
                          </strong>
                        </Typography>
                        <Card
                          variant="outlined"
                          style={{
                            height: '600px',
                            borderRadius: '40px',
                            border: '3px solid #1e4ca1',
                          }}
                        >
                          <CardContent>
                            <List
                              style={{
                                minHeight: 540,
                                maxHeight: 540,
                                overflow: 'auto',
                              }}
                            >
                              {available_Devices.length > 0 ? (
                                available_Devices.map((item, i) => (
                                  <div>
                                    <ListItem
                                      className={classes.active}
                                      onClick={() => handleDeviceClick(item)}
                                    >
                                      <ListItemText primary={item.full_name} />
                                    </ListItem>
                                    <Divider component="li" />
                                  </div>
                                ))
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: 40 + '%',
                                  }}
                                  className="mb-3"
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .no_devices
                                  }
                                </Typography>
                              )}
                            </List>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-md-2">
                        <ForwardIcon
                          color="primary"
                          style={{
                            fontSize: '60px',
                            marginTop: '280px',
                            marginLeft: '18px',
                          }}
                        />
                        <ForwardIcon
                          color="primary"
                          style={{
                            fontSize: '60px',
                            marginTop: '0px',
                            marginLeft: '18px',
                            transform: 'rotate(180deg)',
                          }}
                        />
                      </div>
                      <div className="col-md-5">
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-2 mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .devices_in_group_header
                            }
                          </strong>
                        </Typography>
                        <Card
                          variant="outlined"
                          style={{
                            height: '600px',
                            borderRadius: '40px',
                            border: '3px solid #1e4ca1',
                          }}
                        >
                          <CardContent>
                            <List
                              style={{
                                minHeight: 540,
                                maxHeight: 540,
                                overflow: 'auto',
                              }}
                            >
                              {device_GroupDetails.deviceDetails.length > 0 ? (
                                device_GroupDetails.deviceDetails.map(
                                  (item, i) => (
                                    <div>
                                      <ListItem
                                        className={classes.active}
                                        onClick={() =>
                                          handleDeviceClickRemove(item)
                                        }
                                      >
                                        <ListItemText
                                          primary={item.full_name}
                                        />
                                      </ListItem>
                                      <Divider component="li" />
                                    </div>
                                  )
                                )
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: 40 + '%',
                                  }}
                                  className="mb-3"
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .no_devices
                                  }
                                </Typography>
                              )}
                            </List>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

ModifyDeviceGroup.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ModifyDeviceGroup))
