import * as actionTypes from './actionTypes'
import Axios, { setHeaders } from '../../helper/axios'
import { createActivityLog } from '../../helper/commonHelper'

export const isLoading = () => {
  return {
    type: actionTypes.ISLOADING,
  }
}

export const isPageLoading = () => {
  return {
    type: actionTypes.ISPAGELOADING,
  }
}

export const success = (success) => {
  return {
    type: actionTypes.SUCCESS,
    success: success,
  }
}

export const fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  }
}

export const resetErrorAndSuccessState = () => {
  return {
    type: actionTypes.REST_ERROR_AND_SUCCESS_STATE,
  }
}

export const companyDetailsSuccess = (details) => {
  return {
    type: actionTypes.COMPANY_DETAILS,
    payload: details,
  }
}

export const companyRequestAccepted = (details) => {
  return {
    type: actionTypes.COMPANY_REQUEST_ACCEPTED,
    success: details,
  }
}

export const companyRequestRejected = (details) => {
  return {
    type: actionTypes.COMPANY_REQUEST_REJECTED,
    success: details,
  }
}

export const companyMailerSettingSuccess = (details) => {
  return {
    type: actionTypes.COMPANY_MAILER_SETTING,
    payload: details,
  }
}

export const companyVarPanelListSuccess = (listing) => {
  return {
    type: actionTypes.VAR_PANEL_LIST,
    listing,
  }
}

export const companyInvoiceSuccess = (listing) => {
  return {
    type: actionTypes.INVOICE_LIST,
    listing,
  }
}

export const companyRelatedInvoiceSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_RELATED_INVOICE_LIST,
    listing,
  }
}

export const invoiceDetailSuccess = (listing) => {
  return {
    type: actionTypes.INVOICE_DETAIL,
    listing,
  }
}

export const invoiceDetailForCustomerInvoiceSuccess = (listing) => {
  return {
    type: actionTypes.CREATE_CUSTOMER_INVOICE_DETAIL,
    listing,
  }
}

export const updateCreateCustomerInvoiceLineItem = (list) => {
  return {
    type: actionTypes.UPDATE_CREATE_CUSTOMER_INVOICE_LINE_ITEM,
    list,
  }
}

export const companyStockListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_STOCK_LIST,
    listing,
  }
}

export const companyListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_LIST,
    listing,
  }
}

export const companyListForDropDownSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_DROP_DOWN_LIST,
    listing,
  }
}

export const companyRequestListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_REQUEST_LIST,
    listing,
  }
}

export const companyRelatedDeviceListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_RELATED_DEVICE_LIST,
    listing,
  }
}

export const token = () => {
  return localStorage.getItem('token')
}

export const getVarPanelList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getChildCompanyList', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyVarPanelListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getCompanyStockList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyStock', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyStockListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getAllCompany', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyRequestList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyRequest', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRequestListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const acceptCompanyRequest = (requestId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/acceptCompanyRequest?requestId=' + requestId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRequestAccepted(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const rejectCompanyRequest = (requestId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/rejectCompanyRequest?id=' + requestId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRequestRejected(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const createCompany = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/addCompany', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Created Company', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

//TODO: activity log for create customer invoice
export const createCustomerInvoice = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/addInvoice', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          // createActivityLog('Created Company', "", requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}
//TODO: activity log
export const createCompanyRequest = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/createCompanyRequest', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          // createActivityLog('Created Company Request', "", requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const deviceTransfer = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/transferDevices', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const modifyCompany = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.put('/company/updateCompany', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          //createActivityLog('Modify Company', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyDetails = (id) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyDetails?id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyDetailsSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const updatePassword = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.put('/user/resetPassword', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Changed Password', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getInvoiceList = (companyId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getInvoiceList?company_id=' + companyId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyInvoiceSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getCompanyRelatedInvoices = (companyId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get(
      '/company/getCompanyRelatedInvoices?company_id=' + companyId,
      authCode
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRelatedInvoiceSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getInvoiceDetails = (invoiceId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getInvoiceDetails?invoice_id=' + invoiceId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(invoiceDetailSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getInvoiceDetailsToCreateCustomerInvoice = (invoiceId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getInvoiceDetails?invoice_id=' + invoiceId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            invoiceDetailForCustomerInvoiceSuccess(response.data.payload)
          )
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyListForUsers = () => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getAllCompany?type=user', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyRelatedDeviceList = (id) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyRelatedDevices?company_id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRelatedDeviceListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const deleteCompany = (id, details = '') => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.delete('/user/deleteCompany?id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Removed Company', '', details) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const modifyCompanyMailerSetting = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/modifyCompanyMailerConfig', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          // createActivityLog('Modify Company', "", requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const getCompanyMailerConfig = (id) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyMailerConfig?company_id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload) {
            dispatch(companyMailerSettingSuccess(response.data.payload))
          } else {
            const companyMailerSettingData = {
              id: '',
              company_id: '',
              host_name: '',
              port_name: '',
              user_name: '',
              user_password: '',
            }
            dispatch(companyMailerSettingSuccess(companyMailerSettingData))
          }
        } else {
          // dispatch(fail(response.data.message));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyDropDownList = () => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyListForDropdown', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyListForDropDownSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}
