import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import { getInvoiceDetails } from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import dictionary from '../store/dictionary.json'

const InvoiceDetails = (props) => {
  const companyId = localStorage.getItem('tempCompId')
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { invoice_Detail_List } = useSelector((state) => state.company)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()

  useEffect(() => {
    const id = localStorage.getItem('tempInvoiceId')
    if (!id) {
      history.push({
        pathname: '/billingPortal',
      })
    } else {
      dispatch(getInvoiceDetails(id))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row">
                  <div
                    className="col-md-8"
                    style={{ justifyContent: 'space-evenly', display: 'flex' }}
                  >
                    <div>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .invoice_number_header
                          }
                          :{' '}
                        </strong>
                        {invoice_Detail_List?.invoice_num}
                        <br />
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .amount_header
                          }
                          :{' '}
                        </strong>
                        ${(invoice_Detail_List?.total || 0)?.toFixed(2)}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .due_date_header
                          }
                          :{' '}
                        </strong>
                        {moment
                          .utc(invoice_Detail_List?.due_date)
                          .format('MM/DD/YYYY')}
                        <br />
                        {/* <strong> Paid: </strong><img alt='Sentinel' src={invoice_Detail_List?.is_paid === "1" ? '../assets/img/checked.png' : '../assets/img/unchecked.png'} style={{ width: 20 }} /> */}
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .notes_header
                          }
                          :{' '}
                        </strong>
                        {invoice_Detail_List?.notes}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .customer_header
                          }
                          :{' '}
                        </strong>
                        {
                          invoice_Detail_List?.customer_company_detail
                            ?.company_name
                        }
                        <br />
                        {/* <strong> Notes: </strong>{invoice_Detail_List?.notes} */}
                      </Typography>
                    </div>
                  </div>

                  <div className="col-md-4" style={{ justifyContent: 'end' }}>
                    <Link
                      className="btn btn-primary float-right mr-2"
                      to="/billingPortal"
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .invoices_button
                      }
                    </Link>
                    {invoice_Detail_List?.customer_company_detail?.id !=
                    companyId ? (
                      <Link
                        className="btn btn-primary float-right mr-2"
                        to="/createCustomerInvoice"
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .create_customer_invoice_button
                        }
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="invoiceTableContainer">
                  <table className="invoiceTable">
                    <thead>
                      <tr>
                        <th style={{ width: '25%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .item_header
                          }
                        </th>
                        <th style={{ width: '35%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .description_header
                          }
                        </th>
                        <th style={{ width: '6%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .quantity_header
                          }
                        </th>
                        <th style={{ width: '8%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .msrp_header
                          }
                        </th>
                        <th style={{ width: '8%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .discount_header
                          }
                        </th>
                        <th style={{ width: '8%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .price_per_header
                          }
                        </th>
                        <th style={{ width: '10%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .total_header
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice_Detail_List?.invoice_line_items?.map((val) => (
                        <tr>
                          <td>{val.name}</td>
                          <td>{val.desc}</td>
                          <td>{val.qty}</td>
                          <td>${(val.price || 0).toFixed(2)}</td>
                          <td>{val.discount}%</td>
                          <td>${(val.price_per || 0).toFixed(2)}</td>
                          <td>${(val.total || 0).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr style={{ justifyContent: 'end' }}>
                        <td colspan="4"></td>
                        <td colspan="2" style={{ textAlign: 'right' }}>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .subtotal_header
                            }
                            :
                          </strong>
                          <br></br>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .discount_header
                            }
                            :
                          </strong>
                          <br></br>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .shipping_header
                            }
                            :
                          </strong>
                          <br></br>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .tax_header
                            }
                            :
                          </strong>
                          <br></br>
                          <hr></hr>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .grandtotal_header
                            }
                            :
                          </strong>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          ${invoice_Detail_List?.amount?.toFixed(2)}
                          <br></br>-${invoice_Detail_List?.discount?.toFixed(2)}
                          <br></br>${invoice_Detail_List?.shipping?.toFixed(2)}
                          <br></br>${invoice_Detail_List?.tax?.toFixed(2)}
                          <br></br>
                          <hr></hr>
                          <strong>
                            ${invoice_Detail_List?.total?.toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

InvoiceDetails.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(InvoiceDetails))
