import * as actionTypes from './actionTypes'
import Axios, { setHeaders } from '../../helper/axios'
import { createActivityLog } from '../../helper/commonHelper'

export const isLoading = () => {
  return {
    type: actionTypes.ISLOADING,
  }
}

export const isPageLoading = () => {
  return {
    type: actionTypes.ISPAGELOADING,
  }
}

export const success = (success) => {
  return {
    type: actionTypes.SUCCESS,
    success: success,
  }
}

export const fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  }
}

export const resetErrorAndSuccessState = () => {
  return {
    type: actionTypes.REST_ERROR_AND_SUCCESS_STATE,
  }
}

export const companyDetailsSuccess = (details) => {
  return {
    type: actionTypes.COMPANY_DETAILS,
    payload: details,
  }
}

export const companyMailerSettingSuccess = (details) => {
  return {
    type: actionTypes.COMPANY_MAILER_SETTING,
    payload: details,
  }
}

export const companyListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_LIST,
    listing,
  }
}

export const otaListSuccess = (listing) => {
  return {
    type: actionTypes.OTA_LISTING,
    listing,
  }
}

export const otaReset = (listing) => {
  return {
    type: actionTypes.OTA_RESET,
    listing,
  }
}

export const otaDeleteSuccess = (listing) => {
  return {
    type: actionTypes.OTA_DELETE,
    listing,
  }
}

export const otaAddedSuccess = (listing) => {
  return {
    type: actionTypes.OTA_ADDED,
    listing,
  }
}

export const companyRelatedDeviceListSuccess = (listing) => {
  return {
    type: actionTypes.COMPANY_RELATED_DEVICE_LIST,
    listing,
  }
}

export const token = () => {
  return localStorage.getItem('token')
}

export const otaActive = (id) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    const requestPayload = {
      id: id,
    }

    setHeaders()
    Axios.post('/ota/activeota', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(otaDeleteSuccess(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const otaDelete = (id) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    const requestPayload = {
      id: id,
    }
    setHeaders()
    Axios.post('/ota/deleteota', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(otaDeleteSuccess(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const otaList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    const requestPayload = {
      company_id: '',
    }
    setHeaders()
    Axios.post('/ota/getallota', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(otaListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const uploadOta = (requestPayload) => {
  let formData = new FormData()
  formData.append('version', requestPayload.version)
  formData.append('zip_file', requestPayload.zip_file)
  formData.append('version_description', requestPayload.description)

  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.post('/ota/addota', formData, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(otaAddedSuccess(response.data))
          createActivityLog('Firmware Updated Via OTA', '', requestPayload)
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const reset = (needLoading = false) => {
  return (dispatch) => {
    const obj = []
    dispatch(otaReset(obj))
  }
}

export const createCompany = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/company/addCompany', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Created Company', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const modifyCompany = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.put('/company/updateCompany', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Modify Company', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyDetails = (id) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getCompanyDetails?id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyDetailsSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const updatePassword = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.put('/user/resetPassword', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Changed Password', '', requestPayload) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyListForUsers = () => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/company/getAllCompany?type=user', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const companyRelatedDeviceList = (id) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: {
        Authorization: token(),
        'content-type': 'multipart/form-data',
      },
    }
    setHeaders()
    Axios.get('/company/getCompanyRelatedDevices?company_id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(companyRelatedDeviceListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const deleteCompany = (id, details = '') => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.delete('/user/deleteCompany?id=' + id, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
          createActivityLog('Removed Company', '', details) // create activity log
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}
