import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  getCompanyStockList,
  companyRelatedDeviceListSuccess,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import { Typography, Checkbox } from '@material-ui/core'
import Submenu from '../header_sub_menu'
import dictionary from '../store/dictionary.json'

const columns = [
  {
    field: 'serial_number',
    headerName: 'Serial Number',
    width: 200,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].serial_number_field_name}
      </strong>
    ),
    renderCell: (params) => {
      return params.row.serial_front && params.row.serial_back
        ? params.row.serial_front + ' - ' + params.row.serial_back
        : ''
    },
  },
  {
    field: 'Board Type',
    headerName: 'Board Type',
    width: 200,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].device_type_field_name}
      </strong>
    ),
  },
]

const ViewDeviceStock = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props

  const dispatch = useDispatch()
  const { company_Stock_List } = useSelector((state) => state.company)
  const history = useHistory()

  useEffect(() => {
    dispatch(getCompanyStockList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const currentlySelected = (GridCellParams) => {
    // const action = GridCellParams.colDef.field
    // const id = GridCellParams.row.id
    // if (action == '#') {
    //   localStorage.setItem("tempCompanyId", id);
    //   history.push({
    //     pathname: "/modifyCompany"
    //   });
    // }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-10 text-center">
                    <Typography variant="subtitle1">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .company_name_field_name
                        }
                        :
                      </strong>{' '}
                      {company_Stock_List.company.company_name} <br />
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .company_id_field_name
                        }
                        :
                      </strong>{' '}
                      {company_Stock_List.company.company_id}
                    </Typography>
                  </div>
                  <div className="col-lg-2">
                    <Link class="btn btn-primary float-right" to="/varPanel">
                      {
                        dictionary[localStorage.getItem('language')]
                          .company_list_button
                      }
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                <DataGrid
                  onCellClick={currentlySelected}
                  rows={company_Stock_List.companyStock}
                  columns={columns}
                  pageSize={25}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    pageLoading: state.company.pageLoading,
  }
}

ViewDeviceStock.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ViewDeviceStock))
