import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  modifyCompany,
  companyDetails,
  companyDetailsSuccess,
  resetErrorAndSuccessState,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Switch from '@material-ui/core/Switch'
import validator from 'validator'
import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import ForwardIcon from '@material-ui/icons/Forward'
import '../App.css'
import Submenu from '../header_sub_menu'
import moment from 'moment'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  infoStyle: {
    marginLeft: 10,
    cursor: 'pointer',
  },
}))

const ModifyCompany = (props) => {
  const company_type = [
    { label: 'OEM', value: 'oem' },
    { label: 'EC', value: 'ec' },
    { label: 'VAR', value: 'var' },
  ]
  const discount = [
    { label: '0% (Retail)', value: 0 },
    { label: '15% (1-24 Units/Year)', value: 15 },
    { label: '19% (25-99 Units/Year)', value: 19 },
    { label: '24% (100-249 Units/Year)', value: 24 },
    { label: '30% (250-999 Units/Year)', value: 30 },
    { label: '40% (1000+ Units/Year)', value: 40 },
    { label: '50% (International)', value: 50 },
  ]

  const { enqueueSnackbar, error, success, loading } = props
  const classes = useStyles()
  const { company_Details } = useSelector((state) => state.company)
  const dispatch = useDispatch()
  const history = useHistory()

  const [formValidation, setFormValidation] = React.useState({
    company_name: '',

    company_type: '',
    parent_company_code: '',
    discount_percentage: '',

    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '',
    poc_phone_number: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }

    if (!company_Details.company_name) {
      isError = true
      formerr.company_name = 'Required company name'
      setFormValidation(formerr)
    }

    if (
      company_Details.discount_percentage == null ||
      company_Details.discount_percentage == ''
    ) {
      if (company_Details.discount_percentage !== 0) {
        isError = true
        formerr.discount_percentage = 'Required discount percentage'
        setFormValidation(formerr)
      }
    }

    if (!company_Details.poc_first_name) {
      isError = true
      formerr.poc_first_name = 'Required first name'
      setFormValidation(formerr)
    }

    if (!company_Details.poc_last_name) {
      isError = true
      formerr.poc_last_name = 'Required last name'
      setFormValidation(formerr)
    }

    if (!company_Details.poc_e_mail) {
      isError = true
      formerr.poc_e_mail = 'Required email'
      setFormValidation(formerr)
    }

    if (
      company_Details.poc_e_mail &&
      !validator.isEmail(company_Details.poc_e_mail)
    ) {
      isError = true
      formerr.poc_e_mail = 'Email invalid'
      setFormValidation(formerr)
    }

    if (!company_Details.poc_phone_number) {
      isError = true
      formerr.poc_phone_number = 'Required telephone'
      setFormValidation(formerr)
    } else if (company_Details.poc_phone_number.length < 10) {
      isError = true
      formerr.poc_phone_number = 'Telephone number at least 10 digits'
      setFormValidation(formerr)
    }

    if (!company_Details.poc_country_code) {
      isError = true
      formerr.poc_country_code = 'Required country code'
      setFormValidation(formerr)
    } else if (company_Details.poc_country_code.length > 3) {
      isError = true
      formerr.poc_country_code =
        'The country code must be between 1 to 3 digits'
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    if (prop == 'poc_phone_number') {
      value = value.split('-').join('')
    }

    dispatch(companyDetailsSuccess({ ...company_Details, [prop]: value }))
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e, returnRequired) => {
    e.preventDefault()
    if (handleValidation()) return false

    const Data = {
      id: company_Details.id,
      companyName: company_Details.company_name,
      discountPercentage: company_Details.discount_percentage,
      POCFirstName: company_Details.poc_first_name,
      POCLastName: company_Details.poc_last_name,
      POCEmail: company_Details.poc_e_mail,
      POCPhoneNumber: company_Details.poc_phone_number,
      POCCountryCode: company_Details.poc_country_code,
      companyId: company_Details.company_id,
      isDeactive: company_Details.is_deactive ? '1' : '0',
    }

    dispatch(modifyCompany(Data))
  }

  useEffect(() => {
    const id = localStorage.getItem('tempCompanyId')
    if (!id) {
      history.push({
        pathname: '/company',
      })
    } else {
      dispatch(companyDetails(id))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    if (success == 'Company modified successfully') {
      history.push({
        pathname: '/company',
      })
    }
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-8 text-center">
                  <Typography variant="h5" className="text-center">
                    Modify a Company
                  </Typography>
                </div>
                <div className="col-lg-4">
                  <Link class="btn btn-primary float-right" to="/company">
                    Company List
                  </Link>
                  {/* <Link class='btn btn-primary float-right mr-2' to='/companyMailerSetting'>Company's Mailer Config.</Link> */}
                </div>
              </div>
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          id="cname"
                          fullWidth
                          label="Company Name (required)"
                          variant="outlined"
                          name="company_name"
                          value={company_Details.company_name}
                          onChange={handleChange('company_name')}
                          error={!!formValidation.company_name}
                          helperText={formValidation.company_name}
                        />
                      </div>
                    </div>

                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <FormControl
                          disabled
                          variant="outlined"
                          sx={{ m: 1, width: 300 }}
                          className="col-md-12"
                        >
                          <InputLabel id="demo-multiple-name-label">
                            Company Type
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={company_Details.company_type}
                            onChange={handleChange('company_type')}
                            input={<OutlinedInput label="Company Type" />}
                            MenuProps={MenuProps}
                          >
                            {company_type.map((item) => (
                              <MenuItem
                                key={item.label}
                                value={item.value}
                                // style={getStyles(item.value, companyType, theme)}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="parentCompanyCode"
                          fullWidth
                          label="Parent Company Code (required)"
                          variant="outlined"
                          name="parent_company_code"
                          value={
                            company_Details?.parent_company
                              ? company_Details?.parent_company?.company_name +
                                ' (' +
                                company_Details?.parent_company?.company_id +
                                ') - ' +
                                company_Details?.parent_company?.company_type?.toUpperCase()
                              : ' '
                          }
                          onChange={handleChange('parent_company_code')}
                          error={!!formValidation.parent_company_code}
                          helperText={formValidation.parent_company_code}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <FormControl
                          variant="outlined"
                          sx={{ m: 1, width: 300 }}
                          className="col-md-12"
                        >
                          <InputLabel id="demo-multiple-name-label_discount1">
                            Discount
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label_discount"
                            id="demo-multiple-name_discount"
                            // multiple
                            value={company_Details.discount_percentage}
                            onChange={handleChange('discount_percentage')}
                            input={<OutlinedInput label="Discount" />}
                            MenuProps={MenuProps}
                          >
                            {discount.map((item) => (
                              <MenuItem
                                key={item.label}
                                value={item.value}
                                // style={getStyles(item.value, companyType, theme)}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Typography variant="subtitle2">
                          <strong>Technical POC</strong>
                        </Typography>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          label="Last Name (required)"
                          variant="outlined"
                          name="poc_last_name"
                          value={company_Details.poc_last_name}
                          onChange={handleChange('poc_last_name')}
                          error={!!formValidation.poc_last_name}
                          helperText={formValidation.poc_last_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label="First Name (required)"
                          variant="outlined"
                          name="poc_first_name"
                          value={company_Details.poc_first_name}
                          onChange={handleChange('poc_first_name')}
                          error={!!formValidation.poc_first_name}
                          helperText={formValidation.poc_first_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="email"
                          fullWidth
                          label="E-Mail (required)"
                          variant="outlined"
                          name="poc_e_mail"
                          value={company_Details.poc_e_mail}
                          onChange={handleChange('poc_e_mail')}
                          error={!!formValidation.poc_e_mail}
                          helperText={formValidation.poc_e_mail}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="country_code"
                          fullWidth
                          label="POC Country Code (required)"
                          variant="outlined"
                          name="poc_country_code"
                          value={company_Details.poc_country_code}
                          onChange={(e) => {
                            if (
                              e.target.value === '' ||
                              /^[0-9\b]+$/.test(e.target.value)
                            ) {
                              handleChange('poc_country_code')(e)
                            }
                          }}
                          error={!!formValidation.poc_country_code}
                          helperText={formValidation.poc_country_code}
                          inputProps={{
                            inputMode: 'numeric',
                            maxLength: 3,
                            pattern: '[0-9]{1,3}',
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <InputMask
                          mask="999-999-9999"
                          value={company_Details.poc_phone_number}
                          onChange={handleChange('poc_phone_number')}
                        >
                          {(inputProps) => (
                            <TextField
                              id="telephone"
                              fullWidth
                              label="Telephone (required)"
                              variant="outlined"
                              name="poc_phone_number"
                              error={!!formValidation.poc_phone_number}
                              helperText={formValidation.poc_phone_number}
                            />
                          )}
                        </InputMask>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          size="small"
                          // fullWidth
                          id="id"
                          label="ID"
                          variant="outlined"
                          name="company_id"
                          value={company_Details.company_id}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          Modify &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 "></div>
                  <div className="col-md-5 ">
                    <div className="row">
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: 'justify', marginRight: 18 }}
                      >
                        <strong>
                          Administrator's Note: The Company Name along with the
                          E-Mail address must be unique. It is allowable to have
                          the same Company Name, but the e-mail addresses must
                          be different. It is allowable to have multiple Company
                          Names associated with one E-Mail address. It is not
                          permitted to have a Company Name and E-Mail address
                          pair match another.
                        </strong>
                      </Typography>
                    </div>
                    <div className="row mt-4">
                      <TextField
                        id="billingDueDate"
                        fullWidth
                        label="Billing Due Date"
                        variant="outlined"
                        name="next_billing_date"
                        InputLabelProps={{ shrink: true }}
                        value={
                          company_Details.next_billing_date
                            ? moment
                                .utc(company_Details.next_billing_date)
                                .format('MM/DD/YYYY')
                            : null
                        }
                        onChange={handleChange('next_billing_date')}
                        error={!!formValidation.next_billing_date}
                        helperText={formValidation.next_billing_date}
                        disabled={true}
                      />
                    </div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2">
                          <strong>De-Activate Company </strong>
                        </Typography>
                        <Switch
                          color="primary"
                          checked={company_Details.is_deactive ? true : false}
                          inputProps={{ 'aria-label': 'primary' }}
                          name="is_deactive"
                          onChange={handleChange('is_deactive')}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

ModifyCompany.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ModifyCompany))
