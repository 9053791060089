import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  companyRequestList,
  acceptCompanyRequest,
  rejectCompanyRequest,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import { Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const ViewCompanyRequests = (props) => {
  const COMPANY_TYPE = {
    VAR: 'var',
    EC: 'ec',
    OEM: 'oem',
    ROOT: 'root',
  }
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { company_Request_List } = useSelector((state) => state.company)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()

  const ColorButton = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: 'white',
      backgroundColor: '#008000',
      '&:hover': {
        backgroundColor: '#016e01',
      },
    },
  }))(Button)

  const RedColorButton = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: 'white',
      backgroundColor: '#d41204',
      '&:hover': {
        backgroundColor: '#f21302',
      },
    },
  }))(Button)

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      filterable: false,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <img alt="Company" src="../assets/img/company.svg" />
      ),
    },
    {
      field: 'parent_company_detail.company_name',
      headerName: 'VAR Name',
      width: 175,
      renderHeader: () => <strong>{'VAR Name'}</strong>,
      renderCell: (params) => params.row.parent_company_detail.company_name,
    },
    {
      field: 'parent_company_detail.poc_e_mail',
      headerName: 'VAR Email',
      width: 200,
      renderHeader: () => <strong>{'VAR Email'}</strong>,
      renderCell: (params) => params.row.parent_company_detail.poc_e_mail,
    },
    {
      field: 'parent_company_detail.poc_phone_number',
      headerName: 'VAR Phone',
      width: 150,
      renderHeader: () => <strong>{'VAR Phone'}</strong>,
      renderCell: (params) => {
        return params.row.parent_company_detail.poc_phone_number
          ? (params.row.parent_company_detail.poc_country_code
              ? params.row.parent_company_detail.poc_country_code + ' '
              : '') +
              params.row.parent_company_detail.poc_phone_number.substring(
                0,
                3
              ) +
              '-' +
              params.row.parent_company_detail.poc_phone_number.substring(
                3,
                6
              ) +
              '-' +
              params.row.parent_company_detail.poc_phone_number.substring(
                6,
                params.row.parent_company_detail.poc_phone_number.length
              )
          : ''
      },
    },

    {
      field: 'company_name',
      headerName: 'New Company Name',
      width: 180,
      renderHeader: () => <strong>{'New Company Name'}</strong>,
    },
    {
      field: 'company_type',
      headerName: 'Company Type',
      width: 190,
      renderHeader: () => <strong>{'Company Type'}</strong>,
      renderCell: (params) => {
        return params.row.company_type
          ? params.row.company_type === COMPANY_TYPE.EC
            ? 'End Consumer'
            : params.row.company_type === COMPANY_TYPE.VAR
            ? 'Value Added Reseller'
            : params.row.company_type === COMPANY_TYPE.OEM
            ? 'Original Equipment Manufacturer'
            : ''
          : ''
      },
    },
    {
      field: 'Accept Company',
      headerName: '',
      width: 170,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <div>
          <ColorButton
            variant="contained"
            size="small"
            onClick={handleSubmitClick(params.row.id)}
          >
            Create Company{' '}
            <i
              className="fa fa-spinner fa-spin"
              style={{ display: props.loading ? 'block' : 'none' }}
            />
          </ColorButton>
        </div>
      ),
    },
    {
      field: 'Reject Company',
      headerName: '',
      width: 170,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <div>
          <RedColorButton
            variant="contained"
            size="small"
            onClick={handleSubmitClickToReject(params.row.id)}
          >
            Reject Company{' '}
            <i
              className="fa fa-spinner fa-spin"
              style={{ display: props.loading ? 'block' : 'none' }}
            />
          </RedColorButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    dispatch(companyRequestList(true))
  }, [dispatch])

  const handleSubmitClick = (requestId) => (event) => {
    dispatch(acceptCompanyRequest(requestId))
  }

  const handleSubmitClickToReject = (requestId) => (event) => {
    dispatch(rejectCompanyRequest(requestId))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    dispatch(companyRequestList(true))
  }, [success])

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id
  }

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <Link class="btn btn-primary float-right" to="/company">
                      Companies
                    </Link>
                    {/* <Link class='btn btn-primary float-right mr-2' to='/addCompany'>Add Company</Link> */}
                    {/* <Link class='btn btn-primary float-right mr-2' to='/ViewCompanyRequests'>View Company Requests</Link> */}
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                <DataGrid
                  onCellClick={currentlySelected}
                  rows={company_Request_List}
                  columns={columns}
                  pageSize={25}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

ViewCompanyRequests.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ViewCompanyRequests))
