import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
  Create,
} from '@material-ui/icons'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { ekeyList } from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import dictionary from '../store/dictionary.json'

const columns = [
  {
    field: '#',
    headerName: '',
    width: 85,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{''}</strong>,
    renderCell: (params) => (
      <Tooltip
        title={
          dictionary[localStorage.getItem('language')].device_details_tooltip
        }
        arrow
      >
        <img
          alt="Active"
          src="../assets/img/lock_single_blue.svg"
          style={{ cursor: 'pointer', width: 30 }}
        />
      </Tooltip>
    ),
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
    width: 290,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].full_name_header}
      </strong>
    ),
  },
  {
    field: 'display_name',
    headerName: 'Display Name',
    width: 290,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].display_name_header}
      </strong>
    ),
  },
  {
    field: 'serial_number',
    headerName: 'Serial Number',
    width: 160,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].serial_number_header}
      </strong>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    width: 170,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].date_added_header}
      </strong>
    ),
    renderCell: (params) => moment(params.value).format('MM/DD/YYYY HH:mm'),
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity',
    width: 170,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].last_activity_header}
      </strong>
    ),
    renderCell: (params) =>
      params.value ? (
        <Tooltip
          title={
            dictionary[localStorage.getItem('language')].device_activity_tooltip
          }
          arrow
        >
          <Link to="/activity">
            {moment(params.value).format('MM/DD/YYYY HH:mm')}
          </Link>
        </Tooltip>
      ) : (
        '-'
      ),
  },
]

const DevicesComp = (props) => {
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { ekey_List } = useSelector((state) => state.user)
  const history = useHistory()

  const [isLoad, setIsLoad] = useState(false)

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id
    if (action == '#') {
      localStorage.setItem('tempDeviceId', id)
      history.push({
        pathname: '/deviceDetails',
      })
    } else if (action == 'lastActivity') {
      history.push({
        pathname: 'activity',
        state: { id: id, type: 'Device' },
      })
    }
  }

  useEffect(() => {
    dispatch(ekeyList())
  }, [dispatch])

  const [pageLoading, setPageLoading] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div className="card-header border-0">
                <div className="row align-items-center"></div>
              </div>
              <div
                className="card-body"
                style={{ height: 565, width: '100%', marginTop: '-30px' }}
              >
                {isLoad ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={ekey_List}
                    columns={columns}
                    pageSize={25}
                    loading={props.loading}
                    disableColumnSelector
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

DevicesComp.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(DevicesComp))
