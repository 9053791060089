import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  getGeneratedTicketDetails,
  createSupportNotes,
} from '../store/actions/deviceGroupActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import {
  TextField,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Divider,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import GoogleMapReact from 'google-map-react'
import Tooltip from '@material-ui/core/Tooltip'
import PlaceIcon from '@material-ui/icons/Place'
import dictionary from '../store/dictionary.json'

// Need to keep this to load default language for all pages. Not sure why. -Micah 7/27/2023
if (localStorage.getItem('language') == undefined) {
  localStorage.setItem('language', 'En')
}

const columns = [
  {
    id: 'full_name',
    label: dictionary[localStorage.getItem('language')].device_name_header,
    type: 'text',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'serial',
    label: dictionary[localStorage.getItem('language')].serial_number_header,
    type: 'text',
    align: 'left',
    minWidth: 75,
  },
  {
    id: 'visible',
    label: dictionary[localStorage.getItem('language')].visible_header,
    type: 'check',
    align: 'center',
    showPrefix: true,
    minWidth: 75,
  },
  {
    id: 'device',
    label:
      dictionary[localStorage.getItem('language')].ekeys_reported_by_app_header,
    type: 'check',
    align: 'center',
    minWidth: 75,
  },
  {
    id: 'sentinel',
    label:
      dictionary[localStorage.getItem('language')].ekeys_assigned_in_sentinel,
    type: 'check',
    align: 'center',
    minWidth: 75,
  },
]

const useStyles = makeStyles((theme) => ({
  labelBox: {
    width: 200,
    textAlign: 'right',
  },
  labelScheduleBox: {
    width: 100,
    textAlign: 'right',
  },
}))

const SupportTicketDetails = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { supportTicketDetail } = useSelector((state) => state.deviceGroup)
  const history = useHistory()

  const [values, setValues] = React.useState({
    notes: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    notes: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.notes) {
      isError = true
      formerr.notes =
        dictionary[localStorage.getItem('language')].ticket_notes_header +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const supportId = localStorage.getItem('tempSupportId')
    const userId = localStorage.getItem('logedUserId')

    const Data = {
      notes: values.notes,
      serviceTicketId: supportId,
      userId: userId,
    }
    dispatch(createSupportNotes(Data))
  }

  useEffect(() => {
    const id = localStorage.getItem('tempSupportId')
    if (!id) {
      history.push({
        pathname: '/support',
      })
    }
    dispatch(getGeneratedTicketDetails(id, true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    setValues({ notes: '' })
    const id = localStorage.getItem('tempSupportId')
    dispatch(getGeneratedTicketDetails(id, false))
  }, [success])

  const handleGPS = () => {
    const gpsLocation = supportTicketDetail.gps_location
    if (
      gpsLocation.longitude > -180 &&
      gpsLocation.longitude < 180 &&
      gpsLocation.latitude > -90 &&
      gpsLocation.latitude < 90
    ) {
      localStorage.setItem('gps_location', JSON.stringify(gpsLocation))
      history.push({
        pathname: 'supportTicketGPSDetails',
        state: { id: 1 },
      })
    } else {
      enqueueSnackbar('Invalid latitude or longitude ', {
        variant: 'error',
      })
      return
    }
  }

  const Marker = ({ text }) => {
    return (
      <Tooltip title={text} placement="top">
        <PlaceIcon className="text-red" />
      </Tooltip>
    )
  }

  const handleClickToRedirect = (type, id) => {
    if (type == 'user') {
      localStorage.setItem('tempId', id)
      history.push({
        pathname: '/modifyUser',
        isLoaderRequired: true,
      })
    }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center">
                    <Typography
                      variant="subtitle1"
                      style={{ color: '#1E4CA1' }}
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .ticket_id_header
                        }
                        :{' '}
                      </strong>
                      {supportTicketDetail.ticket_number}
                    </Typography>
                  </div>

                  <div className="col-lg-2">
                    <a class="btn btn-primary float-right" href="/support">
                      {
                        dictionary[localStorage.getItem('language')]
                          .support_title
                      }
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-md-5">
                    <div className="row">
                      <Typography
                        variant="subtitle1"
                        className="text-center ml-9 mb-2"
                      >
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .ticket_submitted_by_header
                          }
                        </strong>
                      </Typography>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .user_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            <Link
                              onClick={() =>
                                handleClickToRedirect(
                                  'user',
                                  supportTicketDetail.User.id
                                )
                              }
                            >
                              <u>{supportTicketDetail.User.dispay_name}</u>
                            </Link>
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .cell_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.User.country_code}{' '}
                            {supportTicketDetail.phoneNumber}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .email_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            <a
                              href={`mailto:${supportTicketDetail.User.e_mail}`}
                              target="_blank"
                            >
                              {supportTicketDetail.User.e_mail}
                            </a>
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .submitted_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {moment(supportTicketDetail.createdAt).format(
                              'MMMM DD, YYYY, HH:mm:ss'
                            )}
                          </Typography>
                        </Box>
                      </div>
                    </div>

                    <Divider
                      variant="middle1"
                      className="mt-4 mb-4"
                      style={{ background: '#1E4CA1' }}
                    />

                    <div className="row">
                      <Typography
                        variant="subtitle1"
                        className="text-center ml-9 mb-2"
                      >
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .mobile_device_details_header
                          }
                        </strong>
                      </Typography>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .brand_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.brand}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .model_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.phone_model}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .software_version_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.software_version}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .app_version_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.app_version}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .mode_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.ticket_mode == 1
                              ? dictionary[localStorage.getItem('language')]
                                  .mode_online
                              : dictionary[localStorage.getItem('language')]
                                  .mode_offline}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .bluetooth_enabled_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.bluetooth_status == 'On'
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .bluetooth_permission_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.bluetooth_permission == 1
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .location_permission_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {supportTicketDetail.location_permission == 1
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                          style={{ width: 400 }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .gps_location_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            <Link onClick={handleGPS}>
                              {supportTicketDetail.phone_gps_location}
                            </Link>
                          </Typography>
                        </Box>
                      </div>
                    </div>
                    {parseFloat(supportTicketDetail.latitude) >= -90 &&
                    parseFloat(supportTicketDetail.latitude) <= 90 &&
                    parseFloat(supportTicketDetail.longitude) >= -180 &&
                    parseFloat(supportTicketDetail.longitude) <= 180 ? (
                      <Box display="flex" alignItems="center" className="mt-1">
                        <div
                          style={{
                            height: '200px',
                            width: '100%',
                            border: '1px dotted',
                          }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: 'AIzaSyCGEnsYoaX-eveQhMcjFueo7NPJtVB_Tw8',
                            }}
                            defaultCenter={{
                              lat: parseFloat(supportTicketDetail.latitude),
                              lng: parseFloat(supportTicketDetail.longitude),
                            }}
                            defaultZoom={15}
                          >
                            <Marker
                              text={`${supportTicketDetail.latitude}, ${supportTicketDetail.longitude}`}
                              lat={supportTicketDetail.latitude}
                              lng={supportTicketDetail.longitude}
                            />
                          </GoogleMapReact>
                        </div>
                      </Box>
                    ) : (
                      ''
                    )}
                    <Divider
                      variant="middle1"
                      className="mt-4 mb-4"
                      style={{ background: '#1E4CA1' }}
                    />

                    <div className="row">
                      <Typography
                        variant="subtitle1"
                        className="text-center ml-9 mb-2"
                      >
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .datetime_comparison_header
                          }
                        </strong>
                        <br />
                        {
                          dictionary[localStorage.getItem('language')]
                            .at_time_of_generation_subheader
                        }
                      </Typography>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                          style={{ width: 400 }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>Sentinel:</strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {moment(
                              supportTicketDetail.sentinel_date_time
                            ).format('MM/DD/YYYY HH:mm:ss')}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                          style={{ width: 400 }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .app_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {moment(
                              supportTicketDetail.reporting_date_time
                            ).format('MM/DD/YYYY HH:mm:ss')}
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <Typography
                      variant="subtitle1"
                      className="text-center mb-2 ml-3"
                      style={{ marginTop: '-30px' }}
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .devices_title
                        }
                      </strong>
                    </Typography>

                    <Card
                      variant="outlined"
                      style={{
                        height: '100%',
                        width: '100%',
                        border: '2px solid #1e4ca1',
                        margin: 'auto',
                      }}
                    >
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {supportTicketDetail.devices_details !=
                                undefined &&
                              Object.keys(supportTicketDetail.devices_details)
                                .length > 0 ? (
                                Object.keys(
                                  supportTicketDetail.devices_details
                                ).map((serial, i) => {
                                  return (
                                    <TableRow>
                                      {columns.map((column) => {
                                        let value =
                                          supportTicketDetail.devices_details[
                                            serial
                                          ][column.id]
                                        const prefix =
                                          supportTicketDetail.devices_details[
                                            serial
                                          ].prefix
                                        const name =
                                          supportTicketDetail.devices_details[
                                            serial
                                          ].full_name

                                        if (column.id == 'visible') {
                                          value =
                                            name == 'Out of Company'
                                              ? 'red'
                                              : prefix == 'PB' || prefix == 'PC'
                                              ? 'blue'
                                              : prefix == 'PS'
                                              ? 'green'
                                              : 'black'
                                        } else if (
                                          column.id == 'device' ||
                                          column.id == 'sentinel'
                                        ) {
                                          value =
                                            value == 'active'
                                              ? 'blue'
                                              : value == 'inactive'
                                              ? 'black'
                                              : ''
                                        }

                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {column.type == 'text' ? (
                                              column.id == 'full_name' &&
                                              value == '' ? (
                                                dictionary[
                                                  localStorage.getItem(
                                                    'language'
                                                  )
                                                ].unconfigured_header
                                              ) : (
                                                value
                                              )
                                            ) : (
                                              <img
                                                alt=""
                                                src={
                                                  '../assets/img/lock_single_' +
                                                  value +
                                                  '.svg'
                                                }
                                                style={{ width: 25 }}
                                              />
                                            )}
                                          </TableCell>
                                        )
                                      })}
                                    </TableRow>
                                  )
                                })
                              ) : (
                                <TableRow>
                                  <TableCell>
                                    {
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].no_devices_or_ekeys
                                    }
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4 mb-2">
                    <Typography variant="subtitle1">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .ticket_notes_header
                        }
                        :
                      </strong>
                    </Typography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <TextField
                      id="fname"
                      label={
                        dictionary[localStorage.getItem('language')]
                          .enter_new_note_tooltip
                      }
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange('notes')}
                      error={!!formValidation.notes}
                      helperText={formValidation.notes}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: '0px 66px',
                      borderRadius: '10px',
                      height: '60px',
                    }}
                    onClick={handleSubmitClick}
                  >
                    {dictionary[localStorage.getItem('language')].add_button}{' '}
                    &nbsp;
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ display: props.loading ? 'block' : 'none' }}
                    />
                  </Button>
                </div>

                {supportTicketDetail.serviceTicketNotes.map((item, i) => (
                  <div>
                    <div className="row ">
                      <div className="col-md-3">
                        <Typography variant="subtitle2" className="mt-3">
                          <strong>
                            {/* {moment(item.createdAt, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY, HH:mm')}, &nbsp; */}
                            {/* {moment(item.createdAt).format('MMMM DD, YYYY, HH:ss')}   */}
                            {moment(item.createdAt).format('MM/DD/YYYY')} &nbsp;
                            &nbsp;
                            {item.User.dispay_name}
                          </strong>
                        </Typography>
                      </div>
                      <div className="col-md-9">
                        <Typography
                          variant="subtitle2"
                          component="p"
                          className="mt-3"
                        >
                          {item.note_description}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

SupportTicketDetails.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(SupportTicketDetails))
