import 'react-toastify/dist/ReactToastify.css'
import '../App.css'
import React, { useEffect } from 'react'
import Loader from '../PageLoader'
import Submenu from '../header_sub_menu'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import { Redirect } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { uploadDeviceType } from '../store/actions/deviceTypeAction'
import { resetErrorAndSuccessState } from '../store/actions/deviceTypeAction'

const AddDeviceType = (props) => {
  var { enqueueSnackbar, error, success, is_redirect } = props
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)
  let authRedirect = null
  if (is_redirect) {
    authRedirect = <Redirect to="/deviceType" />
  }

  const [values, setValues] = React.useState({
    deviceCode: '',
    deviceType: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    deviceCode: '',
    deviceType: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.deviceCode) {
      isError = true
      formerr.deviceCode = 'Device Code is Required'
      setFormValidation(formerr)
    }
    if (!values.deviceType) {
      isError = true
      formerr.deviceType = 'Device Type is Required'
      setFormValidation(formerr)
    }
    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false
    const Data = {
      deviceCode: values.deviceCode,
      deviceType: values.deviceType,
    }
    dispatch(resetErrorAndSuccessState())
    dispatch(uploadDeviceType(Data))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceTypeForm()
  }, [success])

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const resetDeviceTypeForm = () => {
    var resetForm = { ...values }
    resetForm['deviceCode'] = ''
    resetForm['deviceType'] = ''
    setValues(resetForm)
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      {authRedirect}
      <Submenu />
      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header">
                <div className="row" style={{ marginBottom: '170px' }}>
                  <div className="col-md-3 "></div>
                  <div className="col-md-6 ">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="deviceCode"
                          fullWidth
                          label="Device Code (required)"
                          variant="outlined"
                          name="deviceCode"
                          value={values.deviceCode}
                          onChange={handleChange('deviceCode')}
                          error={!!formValidation.deviceCode}
                          helperText={formValidation.deviceCode}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="deviceType"
                          fullWidth
                          label="Device Type (required)"
                          variant="outlined"
                          name="deviceType"
                          value={values.deviceType}
                          onChange={handleChange('deviceType')}
                          error={!!formValidation.deviceType}
                          helperText={formValidation.deviceType}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          Upload &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceType.loading,
    success: state.deviceType.success,
    error: state.deviceType.error,
    is_redirect: state.deviceType.is_redirect,
  }
}

AddDeviceType.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(AddDeviceType))
