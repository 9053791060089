import React, { useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { permissions } from './store/actions/auth'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Person } from '@material-ui/icons'
import { getPageDetails } from './helper/commonHelper'
import { Divider } from '@material-ui/core'
import { useIdleTimer } from 'react-idle-timer'
import {
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useState } from 'react'
import dictionary from './store/dictionary.json'

const MenuProps = {
  PaperProps: {
    style: {
      width: 100,
    },
  },
}

// const Header = (props) => {
const Header = ({ logout, redirectToLogin }) => {
  const displayName = localStorage.getItem('displayName')
  const companyType = localStorage.getItem('companyType')
  const companyName = localStorage.getItem('companyName')

  const userEmail = localStorage.getItem('userEmail')
  // const userRole = localStorage.getItem("userRole");
  const [pageDetails, setPageDetails] = React.useState({
    isDashboard: true,
    title: '',
    icon: '',
  })

  const languages = [
    {
      label: 'English',
      value: 'En',
    },
    {
      label: 'Español',
      value: 'Es',
    },
  ]

  let [current_language, set_language] = useState(() => {
    let value = localStorage.getItem('language')
    let lang = undefined
    if (value != undefined) {
      languages.forEach((item) => {
        if (item.value == value) {
          lang = item
        }
      })
    }
    if (lang == undefined) {
      return languages[0]
    } else {
      return lang
    }
  })

  const getAccountDetails = () => {
    localStorage.setItem('tempId', localStorage.getItem('logedUserId'))
  }

  const language_changed = (language) => {
    localStorage.setItem('language', language.value)
    set_language(language)
    window.location.reload(false)
  }

  const history = useHistory()
  const location = useLocation()
  const { permissionDetails } = useSelector((state) => state.auth)
  const { errorCode } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(async () => {
    dispatch(permissions())
  }, [dispatch])

  // If the session token is expried, return to the login screen.
  if (errorCode == '401') {
    logout()
  }

  useEffect(() => {
    const pDetails = getPageDetails(location.pathname)

    const details = { ...pageDetails }
    details['isDashboard'] = pDetails.isDashboard
    details['title'] = pDetails.title
    details['icon'] = pDetails.icon
    setPageDetails(details)
  }, [location.pathname])

  const [popup, setPopup] = React.useState(false)
  const handleClose = () => {
    activate()
    setPopup(false)
  }

  const onPrompt = () => {
    // Fire a Modal Prompt
    setRemainingTime(60)
    setPopup(true)
  }

  const onIdle = () => {
    if (isLeader()) {
      // I am the leader, perform remote action
      logout()
    } else {
      setPopup(false)
      setTimeout(() => {
        redirectToLogin()
      }, 200)

      // I am not the leader, perform local action
    }
    // Close Modal Prompt
    // Do some idle action like log out your user
  }

  const onActive = (event) => {
    setPopup(false)
    // Close Modal Prompt
    // Do some active action
  }

  const onAction = (event) => {
    // Do something when a user triggers a watched event+
  }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 9,
    promptTimeout: 1000 * 60 * 1,
    events: [
      // 'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // 'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 200,
    leaderElection: true,
  })

  const [remainingTime, setRemainingTime] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainingTime((getRemainingTime() / 1000).toFixed(0))
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  return (
    <div>
      <Dialog open={popup} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dictionary[current_language.value].session_expiration_title}
        </DialogTitle>
        <Box position="absolute" top={0} right={0} onClick={handleClose}>
          <IconButton>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>
            {dictionary[current_language.value].session_expiration_subtitle}
          </Typography>
          <Typography>
            {dictionary[current_language.value]
              .session_expiration_time_remaining +
              ' ' +
              remainingTime +
              ' ' +
              dictionary[current_language.value]
                .session_expiration_time_seconds}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {
              dictionary[current_language.value]
                .session_expiration_continue_button
            }
          </Button>
        </DialogActions>
      </Dialog>

      <nav className="navbar navbar-top navbar-expand navbar-dark bg-white">
        <div className="container-fluid">
          <div
            style={{
              width: '66%',
              display: 'flex',
              flexDirection: pageDetails.isDashboard ? 'row-reverse' : 'row',
              justifyContent: pageDetails.isDashboard
                ? 'center'
                : 'space-between',
            }}
          >
            <div style={{ width: '33%' }}>
              {pageDetails.isDashboard ? (
                <div>
                  <Link>
                    <img
                      alt="Logo"
                      src="../assets/img/pac_blu_logo.png"
                      style={{ height: 70, marginLeft: 65 }}
                    />
                  </Link>
                </div>
              ) : (
                <Link to="/">
                  <img
                    alt="Logo"
                    src="../assets/img/PAC-BLU2.png"
                    style={{ height: 35 }}
                  />
                </Link>
              )}
            </div>

            <div style={{ width: '33%' }}>
              {!pageDetails.isDashboard ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      alt="Users"
                      src={pageDetails.icon}
                      style={{ height: 60 }}
                    />
                    <h1 className="ml-5">{pageDetails.title}</h1>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div
            style={{
              width: '33%',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <div
              className="pr-3 sidenav-toggler sidenav-toggler-dark"
              data-action="sidenav-pin"
              data-target="#sidenav-main"
            >
              <div
                className="sidenav-toggler-inner"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  className="fa fa-bars fa-2x"
                  style={{ color: '#1E4CA1' }}
                ></i>
              </div>
              <div
                className="dropdown-menu dropdown-menu-left"
                style={{
                  left: 'auto',
                  right: '10px',
                  border: '2px solid #1E4CA1',
                }}
              >
                <div style={{ padding: '10px' }}>
                  {companyName}
                  <br />
                  {displayName}
                  <br />
                  {userEmail}
                  <Divider
                    variant="middle1"
                    style={{ background: '#1E4CA1', marginTop: '5px' }}
                  />
                </div>
                {/* : "" */}
                {/* } */}
                <Link
                  to="/modifyUser"
                  className="dropdown-item"
                  onClick={() => getAccountDetails()}
                >
                  <Person
                    className="text-primary"
                    style={{
                      cursor: 'pointer',
                      height: '25px',
                      width: '25px',
                      marginRight: '10px',
                    }}
                  />
                  <span>
                    {
                      dictionary[current_language.value]
                        .account_details_menu_option
                    }
                  </span>
                </Link>

                {permissionDetails.permission_all ||
                permissionDetails.permission_module.change_password ? (
                  <Link to="/changePassword" className="dropdown-item">
                    <img
                      alt="Change Password"
                      src="../assets/img/key.svg"
                      style={{
                        height: '25px',
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />
                    <span>
                      {
                        dictionary[current_language.value]
                          .change_password_menu_option
                      }
                    </span>
                  </Link>
                ) : (
                  ''
                )}

                <div class="dropdown-item btn-group dropleft" id="language">
                  <Link
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: 'black' }}
                    id="language_button"
                  >
                    <img
                      alt="Language"
                      src="../assets/img/language.svg"
                      style={{
                        height: '25px',
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />
                    {current_language.label}
                  </Link>
                  <div id="language_options">
                    {languages.map((item) => (
                      <Link
                        className="dropdown-item"
                        type="button"
                        onClick={() => language_changed(item)}
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </div>

                {permissionDetails.permission_module.var_panel &&
                (companyType === 'var' || companyType === 'root') ? (
                  <Link to="/varPanel" className="dropdown-item">
                    <img
                      alt="VAR Panel"
                      src="../assets/img/company.svg"
                      style={{
                        height: '25px',
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />
                    <span>
                      {dictionary[current_language.value].var_panel_menu_option}
                    </span>
                  </Link>
                ) : (
                  ''
                )}
                {permissionDetails.permission_module.billing_portal ? (
                  <Link to="/billingPortal" className="dropdown-item">
                    <img
                      alt="Billing Portal"
                      src="../assets/img/dollar-icon.svg"
                      style={{
                        height: '25px',
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />
                    <span>
                      {
                        dictionary[current_language.value]
                          .billing_portal_menu_option
                      }
                    </span>
                  </Link>
                ) : (
                  ''
                )}

                <Link
                  to="/login"
                  className="dropdown-item"
                  onClick={() => logout()}
                >
                  <img
                    alt="logout"
                    src="../assets/img/logout_blue.svg"
                    style={{
                      height: '20px',
                      width: '25px',
                      marginRight: '10px',
                    }}
                  />
                  <span>
                    {dictionary[current_language.value].log_out_menu_option}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
}

// export default Header;

export default connect()(withSnackbar(Header))
