import React, { useEffect, useState } from 'react'
import { useHistory, Redirect, NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
  TextField,
  Typography,
  Button,
  Box,
} from '@material-ui/core'

import './argon.css'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { resetPass } from './store/actions/auth'
import { resetErrorAndSuccessState } from './store/actions/userActions'
import { withSnackbar } from 'notistack'
// import { Loader } from "react-loader-spinner"
import Loader from './PageLoader'
import { useParams } from 'react-router-dom'
import dictionary from './store/dictionary.json'
import { logoutAction } from './store/actions/auth'

const Resetpassword = (props) => {
  const { enqueueSnackbar, error, success, loading } = props
  const dispatch = useDispatch()

  const history = useHistory()

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }))

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    email: '',
    companyCode: '',
  })

  const [isSubmit, setIsSubmit] = React.useState(false)
  const { id } = useParams()
  const [formValidation, setFormValidation] = React.useState({
    companyCode: '',
    email: '',
    password: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.password) {
      isError = true
      formerr.password =
        dictionary[localStorage.getItem('language')].new_password_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!values.con_password) {
      isError = true
      formerr.con_password =
        dictionary[localStorage.getItem('language')].confirm_new_password +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowConPassword = () => {
    setValues({ ...values, showConPassword: !values.showConPassword })
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false
    props.onAuth(values.password, values.con_password, id, history)
  }

  const userId = localStorage.getItem('token')
  let authRedirect = null
  if (userId) {
    //authRedirect = <Redirect to="/" />;
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    setValues({ password: '', con_password: '' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const [pageLoading, setPageLoading] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  // Log out any user that was currently logged in
  const token = localStorage.getItem('token')
  if (token !== null) {
    dispatch(logoutAction(token))
    let language = localStorage.getItem('language')
    let companyDetails = localStorage.getItem('companyDetails')
    localStorage.clear()
    localStorage.setItem('language', language)
    if (companyDetails !== null) {
      localStorage.setItem('companyDetails', companyDetails)
    }
  }

  return (
    <div className="main-content">
      {authRedirect}
      <div>
        <ToastContainer />
      </div>
      {pageLoading ? <Loader /> : null}
      <div className="header">
        <div className="container mt-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div
                className="card bg-secondary mb-0"
                style={{
                  border: '1px solid #1E4CA1',
                  borderTop: '20px solid #1E4CA1',
                  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.46)',
                }}
              >
                <div className="card-header bg-transparent text-center">
                  <img
                    alt="Logo"
                    src="../assets/img/pac_blu_logo.png"
                    style={{ width: '60%', height: 'auto' }}
                  />
                </div>
                <div className="card-body px-lg-5">
                  <div style={{ fontSize: 'smaller' }}>
                    {
                      dictionary[localStorage.getItem('language')]
                        .password_requirements
                    }
                    <ul>
                      <li>
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements_list_1
                        }
                      </li>
                      <li>
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements_list_2
                        }
                      </li>
                      <li>
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements_list_3
                        }
                      </li>
                      <li>
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements_list_4
                        }
                      </li>
                      <li>
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements_list_5
                        }
                      </li>
                    </ul>
                  </div>
                  <form noValidate autoComplete="off">
                    <FormControl
                      fullWidth
                      error={formValidation.password ? true : false}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {
                          dictionary[localStorage.getItem('language')]
                            .new_password_field_name
                        }
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onChange={handleChange('password')}
                        error={!!formValidation.password}
                        helperText={formValidation.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formValidation.password ? (
                        <FormHelperText id="component-error-text">
                          {formValidation.password}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={formValidation.con_password ? true : false}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {
                          dictionary[localStorage.getItem('language')]
                            .confirm_new_password
                        }
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={values.showConPassword ? 'text' : 'password'}
                        value={values.con_password}
                        name="con_password"
                        onChange={handleChange('con_password')}
                        error={!!formValidation.con_password}
                        helperText={formValidation.con_password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showConPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formValidation.con_password ? (
                        <FormHelperText id="component-error-text">
                          {formValidation.con_password}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>

                    <div className="row mt-3">
                      <div className="col-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={handleSubmitClick}
                          style={{ display: props.loading ? 'none' : 'block' }}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .update_password_button
                          }
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          style={{ display: props.loading ? 'block' : 'none' }}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .update_password_button
                          }{' '}
                          &nbsp;
                          <i className="fa fa-spinner fa-spin right" />
                        </Button>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <NavLink to="/login">
                          <small>
                            {
                              dictionary[localStorage.getItem('language')]
                                .login_button
                            }
                          </small>
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success_message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (password, con_password, id, history) =>
      dispatch(resetPass(password, con_password, id, history)),
  }
}

Resetpassword.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Resetpassword))
