import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import { getInvoiceList } from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import dictionary from '../store/dictionary.json'

const BillingPortal = (props) => {
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { invoice_List } = useSelector((state) => state.company)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 60,
      filterable: false,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <Tooltip
          title={
            dictionary[localStorage.getItem('language')]
              .go_to_invoice_details_tooltip
          }
          arrow
        >
          <img
            alt="Company"
            src="../assets/img/dollar-icon.svg"
            style={{ height: '25px', width: '25px', cursor: 'pointer' }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'invoice_num',
      headerName: 'Invoice Number',
      width: 200,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].invoice_number_header}
        </strong>
      ),
    },
    {
      field: 'bill_to_company_detail.company_name',
      headerName: 'Bill To',
      width: 200,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].bill_to_header}
        </strong>
      ),
      renderCell: (params) => {
        return params.row.bill_to_company_detail.company_name
      },
    },
    {
      field: 'customer_company_detail.company_name',
      headerName: 'Customer',
      width: 200,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].customer_header}
        </strong>
      ),
      renderCell: (params) => {
        return params.row.customer_company_detail.company_name
      },
    },
    {
      field: 'total',
      headerName: 'Amount',
      width: 110,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].amount_header}
        </strong>
      ),
      renderCell: (params) => {
        return '$' + (params.value || 0).toFixed(2)
      },
    },

    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 170,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].due_date_header}
        </strong>
      ),
      renderCell: (params) => {
        return moment.utc(params.value).format('MM/DD/YYYY')
      },
    },
    // {
    //   field: 'is_paid',
    //   headerName: 'Paid',
    //   width: 90,
    //   renderHeader: () => (
    //     <strong>{'Paid'}</strong>
    //   ),
    //   renderCell: (params) =>
    //   (
    //   <img alt='Stock' src={params.row.is_paid === '1'? '../assets/img/checked.png' : '../assets/img/unchecked.png'} style={{ width: 20 }} />
    //   )
    // },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 220,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].notes_header}
        </strong>
      ),
    },
  ]

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id

    if (action == '#') {
      localStorage.setItem('tempInvoiceId', id)
      history.push({
        pathname: '/invoiceDetails',
      })
    }
  }

  useEffect(() => {
    const id = localStorage.getItem('tempCompId')
    if (!id) {
      history.push({
        pathname: '/company',
      })
    } else {
      dispatch(getInvoiceList(id))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                <DataGrid
                  onCellClick={currentlySelected}
                  rows={invoice_List}
                  columns={columns}
                  pageSize={25}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

BillingPortal.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(BillingPortal))
