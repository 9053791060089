import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  resetErrorAndSuccessState,
  createActivityLogNotes,
  activityLogNotesList,
  getActivityLogDetails,
} from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import {
  TextField,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Divider,
  Typography,
  Switch,
} from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { phoneNuberFormate } from '../helper/commonHelper'

import GoogleMapReact from 'google-map-react'
import Tooltip from '@material-ui/core/Tooltip'
import PlaceIcon from '@material-ui/icons/Place'
import dictionary from '../store/dictionary.json'

const useStyles = makeStyles((theme) => ({
  labelBox: {
    width: 200,
    textAlign: 'right',
  },
  labelScheduleBox: {
    width: 100,
    textAlign: 'right',
  },
}))
const ACCESS_TYPE = {
  ONE_TIME_ACCESS: 'one_time_access',
  CUSTOM_ACCESS: 'custom_access',
  FULL_ACCESS: 'full_access',
}
const ActivityDetails = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activityLogNotes_List } = useSelector((state) => state.user)
  const history = useHistory()
  const location = useLocation()

  const [values, setValues] = React.useState({
    notes: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    notes: '',
  })

  const [details, setDetails] = React.useState({
    actionBy: '',
    action: '',
    details: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.notes) {
      isError = true
      formerr.notes =
        dictionary[localStorage.getItem('language')].activity_notes_header +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false
    const userId = localStorage.getItem('logedUserId')
    const Data = {
      notes: values.notes,
      activityLogId: activityLogId,
      userId: userId,
    }

    dispatch(createActivityLogNotes(Data))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    setValues({ notes: '' })
    // if(activityLogId != ""){
    //dispatch(getActivityLogDetails(activityLogId))
    dispatch(activityLogNotesList(activityLogId))
    // }
  }, [success])

  const [activityLogId, setActivityLogId] = React.useState('')

  useEffect(() => {
    if (location.state) {
      let det = { ...details }
      det['actionBy'] = location.state.details.User
        ? location.state.details.User
        : ''
      det['details'] = location.state.details.activity_description
        ? JSON.parse(location.state.details.activity_description)
        : ''
      det['logType'] = location.state.details.operation_type
        ? location.state.details.operation_type
        : ''
      det['timeStamp'] = location.state.details.createdAt
        ? location.state.details.createdAt
        : ''
      det['deviceDetails'] = location.state.details.Device
        ? location.state.details.Device
        : ''

      if (location.state.details.gps_location) {
        det['loc'] = location.state.details.gps_location
          ? JSON.parse(location.state.details.gps_location)
          : ''
      }
      let summary = location.state.details.summary
      if (summary == 'Configure Device') {
        det['action'] = 'Device Installed'
      } else if (summary == 'Modify Device') {
        det['action'] = 'Device Modified'
      } else if (summary == 'Remove Device') {
        det['action'] = 'Device Removed'
      } else if (summary == 'Change Password') {
        det['action'] = 'User Changed Password'
      } else {
        det['action'] = summary
      }

      det['activity'] = location.state.details.activity
      det['first_section'] = formatData(
        location.state.details.activity.first_section_info,
        location.state.details
      )
      det['details_section'] = formatData(
        location.state.details.activity.details_section_info,
        location.state.details
      )
      setDetails(det)
      setActivityLogId(location.state.details.id)
      // if(activityLogId != ""){
      // dispatch(getActivityLogDetails(location.state.details.id))
      dispatch(activityLogNotesList(location.state.details.id))
      // }
    } else {
      history.push({
        pathname: 'activity',
      })
    }
  }, [location.state])

  const handleClickToRedirect = (type, id) => {
    if (type == 'user') {
      localStorage.setItem('tempId', id)
      history.push({
        pathname: '/modifyUser',
        isLoaderRequired: true,
      })
    } else if (type == 'deviceGroup') {
      localStorage.setItem('tempDeviceGroupId', id)
      history.push({
        pathname: '/modifyDeviceGroup',
        isLoaderRequired: true,
      })
    } else if (type == 'deviceDetails') {
      localStorage.setItem('tempDeviceId', id)
      history.push({
        pathname: '/deviceDetails',
        isLoaderRequired: true,
      })
    } else if (type == 'supportTicketDetails') {
      localStorage.setItem('tempId', id)
      localStorage.setItem('tempSupportId', id)
      history.push({
        pathname: '/supportTicketDetails',
        isLoaderRequired: true,
      })
    }
  }

  const formatData = (format, data) => {
    format = format.toString()
    // Find variables that are defined as [variable_name]
    while (format.includes('[')) {
      // Get the variable name from the format
      let rep = format
        .substring(format.indexOf('[') + 1, format.indexOf(']'))
        .split(',')

      let key = rep[0]
      let type = rep[1]
      let extra = rep[2]

      // Replace the variable with the value in the data
      format = format.replace(
        `[${rep}]`,
        getJSONValueAtPath(data, key, type, extra)
      )
    }
    return format
  }

  const getJSONValueAtPath = (json, path, type, extra_param) => {
    if ((path === null) | (path === undefined)) return ''
    path = path.split('.')
    let cur = json
    path.forEach((key) => {
      if (cur === undefined || cur === null) {
        return ''
      }
      cur = cur[key]
    })
    //if (cur === undefined || cur === null) return ''

    switch (type) {
      // If type is bool, display as a Yes/No
      case 'bool':
        if (cur === undefined || cur === null) return ''
        return cur == 1 ? 'Yes' : 'No'

      // If type is a phone number, then format it like: (xxx) xxx-xxxx
      case 'phone':
        if (cur === undefined || cur === null) return ''
        return (
          '(' +
          cur.substring(0, 3) +
          ') ' +
          cur.substring(3, 6) +
          '-' +
          cur.substring(6)
        )

      case 'date':
        if (cur === undefined || cur === null) return ''
        return moment(cur).format('MM/DD/YYYY')

      case 'time':
        if (cur === undefined || cur === null) return ''
        return cur

      // If type is a timestamp, then format and convert timezone
      case 'timestamp':
        if (cur === undefined || cur === null) return ''
        return (
          moment(cur).format('MM/DD/YYYY HH:mm:ss') +
          ' ' +
          moment().tz(moment.tz.guess()).format('z')
        )

      case 'json_keys_when_value_is_true':
        if (cur === undefined || cur === null) return ''
        cur = JSON.parse(cur)
        let keys = []
        if (cur == null) return ''

        Object.keys(cur).forEach((key) => {
          if (cur[key]) keys.push(key)
        })

        return keys.join(', ')

      case 'json_keys_when_value_equals':
        if (cur === undefined || cur === null) return ''
        cur = JSON.parse(cur)
        keys = []
        Object.keys(cur).forEach((key) => {
          if (cur[key] == extra_param) keys.push(key)
        })

        return keys.join(', ')

      case 'json_array_list':
        if (cur === undefined || cur === null) return ''
        let vals = []
        Object.keys(cur).forEach((key) => {
          vals.push(cur[key][extra_param])
        })

        return '<ul><li>' + vals.join('</li><li>') + '</li></ul>'

      case 'for_each':
        if (cur === undefined || cur === null) return ''
        let res = []
        extra_param = extra_param.replaceAll('{', '[')
        extra_param = extra_param.replaceAll('}', ']')
        extra_param = extra_param.replaceAll(';', ',')
        Object.keys(cur).forEach((key) => {
          let obj = cur[key]
          res.push(formatData(extra_param, obj))
        })
        return '<ul><li>' + res.join('</li><li>') + '</li></ul>'

      case 'human_readable_string':
        if (cur === undefined || cur === null) return ''
        cur = cur.split('_')
        for (let i = 0; i < cur.length; i++) {
          cur[i] = cur[i][0].toUpperCase() + cur[i].substring(1)
        }
        return cur.join(' ')

      // If type is hide_if_empty, then return the style for hiding if the value is empty, null or undefined
      case 'hide_if_empty':
        return cur == '' || cur == null || cur == undefined
          ? 'style="display: none"'
          : ''

      case 'hide_if_any':
        return cur == '' || cur == null || cur == undefined
          ? ''
          : 'style="display: none"'

      case 'hide_if_equals':
        if (cur === undefined || cur === null) return ''
        return cur == extra_param ? 'style="display: none"' : ''

      case 'hide_if_not_equals':
        if (cur === undefined || cur === null) return ''
        return cur == extra_param ? '' : 'style="display: none"'

      case 'hide_if_same':
        return cur == getJSONValueAtPath(json, extra_param, null, null)
          ? 'style="display: none"'
          : ''

      default:
        return cur
    }
  }

  const Marker = ({ text }) => {
    return (
      <Tooltip title={text} placement="top">
        <PlaceIcon className="text-red" />
      </Tooltip>
    )
  }

  if (details.action == 'User Info Modified') {
    if (details.details) {
      var i = 0
      var pattern = '###-###-####'
      var phone = details.details.oldDetails.phone_number.toString()
      var oldphone = pattern.replace(/#/g, (_) => phone[i++])
    }
  }

  // const sortByDate = (a, b) => {
  //   if (a.date < b.date) {
  //     return 1;
  // }
  // if (a.date > b.date) {
  //     return -1;
  // }
  // return 0;
  // }

  return (
    <div>
      {/* { pageLoading ? <Loader /> : null} */}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div class="col-lg-1"></div>
                  <div className="col-lg-10 text-center">
                    <h1 className="text-center mt-3">
                      {details?.activity?.name}
                    </h1>
                  </div>
                  <div className="col-lg-1">
                    <Link class="btn btn-primary float-right" to="/activity">
                      {
                        dictionary[localStorage.getItem('language')]
                          .activity_header
                      }
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div
                      class="activityDetailsFirstSection"
                      dangerouslySetInnerHTML={{
                        __html: details?.first_section,
                      }}
                    ></div>
                    <hr></hr>
                    <div
                      class="activityDetailsDetailsSection"
                      dangerouslySetInnerHTML={{
                        __html: details?.details_section,
                      }}
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4 mb-2">
                    <Typography variant="subtitle1">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .activity_notes_header
                        }
                        :
                      </strong>
                    </Typography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <TextField
                      id="fname"
                      label={
                        dictionary[localStorage.getItem('language')]
                          .enter_new_note_tooltip
                      }
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange('notes')}
                      error={!!formValidation.notes}
                      helperText={formValidation.notes}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: '0px 66px',
                      borderRadius: '10px',
                      height: '60px',
                    }}
                    onClick={handleSubmitClick}
                  >
                    {dictionary[localStorage.getItem('language')].add_button}
                    &nbsp;
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ display: props.loading ? 'block' : 'none' }}
                    />
                  </Button>
                </div>

                {activityLogNotes_List.map((item, i) => (
                  <div>
                    <div className="row ">
                      <div className="col-md-2">
                        <Typography variant="subtitle2" className="mt-3">
                          <strong>
                            {moment
                              .utc(item.createdAt, 'YYYY-MM-DD HH:mm:ss')
                              .local()
                              .format('MM/DD/YYYY HH:mm:ss')}
                          </strong>
                        </Typography>
                      </div>
                      <div className="col-md-2">
                        <Typography
                          variant="subtitle2"
                          component="p"
                          className="mt-3"
                        >
                          {item?.User?.dispay_name}
                        </Typography>
                      </div>
                      <div className="col-md-6">
                        <Typography
                          variant="subtitle2"
                          component="p"
                          className="mt-3"
                        >
                          {item.note_description}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

ActivityDetails.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ActivityDetails))
