import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import {
  resetErrorAndSuccessState,
  updatePassword,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormHelperText from '@material-ui/core/FormHelperText'
import '../App.css'
import dictionary from '../store/dictionary.json'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const ChangePassword = (props) => {
  const { enqueueSnackbar, error, success, loading } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)

  const [values, setValues] = React.useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  const handleClickShowPassword = (field) => (event) => {
    setValues({
      ...values,
      ['showPassword' + field]: !values['showPassword' + field],
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.old_password) {
      isError = true
      formerr.old_password =
        dictionary[localStorage.getItem('language')]
          .current_password_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine

      setFormValidation(formerr)
    }

    if (!values.new_password) {
      isError = true
      formerr.new_password =
        dictionary[localStorage.getItem('language')].new_password_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!values.confirm_password) {
      isError = true
      formerr.confirm_password =
        dictionary[localStorage.getItem('language')].confirm_new_password +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const Data = {
      currentPassword: values.old_password,
      newPassword: values.new_password,
      confirmPassword: values.confirm_password,
    }

    dispatch(updatePassword(Data))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
  }, [success])

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['old_password'] = ''
    resetForm['new_password'] = ''
    resetForm['confirm_password'] = ''
    setValues(resetForm)
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ fontSize: 'smaller' }}
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .password_requirements
                        }
                        <ul>
                          <li>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_requirements_list_1
                            }
                          </li>
                          <li>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_requirements_list_2
                            }
                          </li>
                          <li>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_requirements_list_3
                            }
                          </li>
                          <li>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_requirements_list_4
                            }
                          </li>
                          <li>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_requirements_list_5
                            }
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          error={formValidation.old_password ? true : false}
                        >
                          <InputLabel htmlFor="standard-adornment-old-password">
                            {
                              dictionary[localStorage.getItem('language')]
                                .current_password_field_name
                            }
                          </InputLabel>
                          <Input
                            id="standard-adornment-old-password"
                            type={values.showPasswordOld ? 'text' : 'password'}
                            value={values.old_password}
                            name="old_password"
                            onChange={handleChange('old_password')}
                            error={!!formValidation.old_password}
                            helperText={formValidation.old_password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword('Old')}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPasswordOld ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formValidation.old_password ? (
                            <FormHelperText id="component-error-text">
                              {formValidation.old_password}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          error={formValidation.new_password ? true : false}
                        >
                          <InputLabel htmlFor="standard-adornment-new-password">
                            {
                              dictionary[localStorage.getItem('language')]
                                .new_password_field_name
                            }
                          </InputLabel>
                          <Input
                            id="standard-adornment-new-password"
                            type={values.showPasswordNew ? 'text' : 'password'}
                            value={values.new_password}
                            name="new_password"
                            onChange={handleChange('new_password')}
                            error={!!formValidation.new_password}
                            helperText={formValidation.new_password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword('New')}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPasswordNew ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formValidation.new_password ? (
                            <FormHelperText id="component-error-text">
                              {formValidation.new_password}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          error={formValidation.confirm_password ? true : false}
                        >
                          <InputLabel htmlFor="standard-adornment-confirm-password">
                            {
                              dictionary[localStorage.getItem('language')]
                                .confirm_new_password
                            }
                          </InputLabel>
                          <Input
                            id="standard-adornment-confirm-password"
                            type={
                              values.showPasswordConfirm ? 'text' : 'password'
                            }
                            value={values.confirm_password}
                            name="confirm_password"
                            onChange={handleChange('confirm_password')}
                            error={!!formValidation.confirm_password}
                            helperText={formValidation.confirm_password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword('Confirm')}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPasswordConfirm ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formValidation.confirm_password ? (
                            <FormHelperText id="component-error-text">
                              {formValidation.confirm_password}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .submit_button
                          }{' '}
                          &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
  }
}

ChangePassword.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ChangePassword))
