import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from './store/actions/companyActions'
import { twoFactorAuthenticate } from './store/actions/auth'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Loader from './PageLoader'
import Footer from './footer'
import Submenu from './header_sub_menu'
import { ToastContainer, toast } from 'react-toastify'
import { Redirect, NavLink, useLocation, useHistory } from 'react-router-dom'

import {
  IconButton,
  Input,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
  TextField,
  Typography,
  Button,
  Box,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormHelperText from '@material-ui/core/FormHelperText'
import dictionary from './store/dictionary.json'

// import "../App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const ResetExpiredPassword = (props) => {
  const { enqueueSnackbar, error, success, loading } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)

  const urlParams = new URLSearchParams(useLocation().search)

  const [values, setValues] = React.useState({
    user_name: urlParams.get('username'),
    company_code: urlParams.get('companyCode'),
    code: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    company_code: '',
    user_name: '',
    code: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.company_code) {
      isError = true
      formerr.company_code =
        dictionary[localStorage.getItem('language')].company_id_tooltip
      setFormValidation(formerr)
    }

    if (values.company_code.length !== 4) {
      isError = true
      formerr.company_code =
        dictionary[localStorage.getItem('language')].company_id_tooltip
      setFormValidation(formerr)
    }

    if (!values.user_name) {
      isError = true
      formerr.user_name =
        dictionary[localStorage.getItem('language')].email_or_phone_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.code || values.code.length !== 6) {
      isError = true
      formerr.code =
        dictionary[localStorage.getItem('language')]
          .two_factor_authentication_code_field +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleMouseDownPassword = (prop) => (event) => {
    event.preventDefault()
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const Data = {
      code: values.code,
      companyId: values.company_code,
      userName: values.user_name,
    }

    dispatch(twoFactorAuthenticate(Data))
  }

  useEffect(() => {
    if (error == 'Two Factor Authentication is required to login') {
      error && enqueueSnackbar(error, { variant: 'default' })
    } else {
      error && enqueueSnackbar(error, { variant: 'error' })
    }
    dispatch(resetErrorAndSuccessState())
  }, [error])

  const history = useHistory()

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
    success && history.push('/Login')
  }, [success])

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['code'] = ''
    setValues(resetForm)
  }

  const userId = localStorage.getItem('token')
  let authRedirect = null
  if (userId) {
    localStorage.setItem('needsRefresh', 'True')
    authRedirect = <Redirect to="/" />
  }

  return (
    <div className="main-content">
      {authRedirect}
      <div>
        <ToastContainer />
      </div>
      {pageLoading ? <Loader /> : null}
      <div className="header" />
      <div className="container mt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div
              className="card bg-secondary mb-0"
              style={{
                border: '1px solid #1E4CA1',
                borderTop: '20px solid #1E4CA1',
                boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.46)',
              }}
            >
              <div className="card-header bg-transparent text-center">
                <img
                  alt="Logo"
                  src="../assets/img/pac_blu_logo.png"
                  style={{ width: '60%', height: 'auto' }}
                />
              </div>

              <div className="card-body px-lg-5">
                <form noValidate autoComplete="off">
                  <h1 className="row justify-content-center">
                    {
                      dictionary[localStorage.getItem('language')]
                        .two_factor_authentication
                    }
                  </h1>
                  <p
                    style={{
                      fontSize: 'small',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {dictionary[localStorage.getItem('language')]
                      .two_factor_authentication_subtitle +
                      localStorage.getItem('phoneEnd')}
                    <br />
                    {
                      dictionary[localStorage.getItem('language')]
                        .two_factor_authentication_subtitle_2
                    }
                  </p>
                  <Box mb={4}>
                    <TextField
                      id="compcode"
                      fullWidth
                      label={
                        dictionary[localStorage.getItem('language')]
                          .company_id_field_name +
                        ' ' +
                        dictionary[localStorage.getItem('language')]
                          .company_id_requirements
                      }
                      inputProps={{ maxLength: 4 }}
                      name="company_code"
                      disabled
                      value={values.company_code}
                      onChange={handleChange('company_code')}
                      error={!!formValidation.company_code}
                      helperText={formValidation.company_code}
                    />
                  </Box>
                  <Box mb={4}>
                    <TextField
                      id="user_name"
                      fullWidth
                      label={
                        dictionary[localStorage.getItem('language')]
                          .email_or_phone_field_name
                      }
                      name="user_name"
                      disabled
                      value={values.user_name}
                      onChange={handleChange('user_name')}
                      error={!!formValidation.user_name}
                      helperText={formValidation.user_name}
                    />
                  </Box>

                  <Box mb={4}>
                    <FormControl
                      fullWidth
                      error={formValidation.password ? true : false}
                    >
                      <InputLabel htmlFor="standard-adornment-code">
                        {
                          dictionary[localStorage.getItem('language')]
                            .two_factor_authentication_code_field
                        }
                      </InputLabel>
                      <Input
                        id="standard-adornment-code"
                        type="text"
                        name="code"
                        inputProps={{ maxLength: 6 }}
                        onChange={handleChange('code')}
                        error={!!formValidation.code}
                        helperText={formValidation.code}
                      />
                      {formValidation.code ? (
                        <FormHelperText id="component-error-text">
                          {formValidation.code}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </Box>

                  <div className="row mt-3">
                    <div className="col-12">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        onClick={handleSubmitClick}
                        style={{ display: props.loading ? 'none' : 'block' }}
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .submit_button
                        }
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        style={{ display: props.loading ? 'block' : 'none' }}
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .submit_button
                        }{' '}
                        &nbsp;
                        <i className="fa fa-spinner fa-spin right" />
                      </Button>
                    </div>
                    <div className="col-12 text-center mt-3">
                      <NavLink to="/login">
                        <small>
                          {
                            dictionary[localStorage.getItem('language')]
                              .login_button
                          }
                        </small>
                      </NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  }
}

ResetExpiredPassword.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(ResetExpiredPassword))
