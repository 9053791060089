import React, { useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { generateUniqueNumber } from '../helper/commonHelper'
import {
  createCompanyRequest,
  resetErrorAndSuccessState,
} from '../store/actions/companyActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Switch from '@material-ui/core/Switch'
import validator from 'validator'
import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import '../App.css'
import { Autocomplete } from '@material-ui/lab'
import dictionary from '../store/dictionary.json'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const NewCompanyRequest = (props) => {
  const company_type = [
    // {label: 'OEM', value: 'oem'},
    {
      label: dictionary[localStorage.getItem('language')].company_type_option_1,
      value: 'ec',
    },
    {
      label: dictionary[localStorage.getItem('language')].company_type_option_2,
      value: 'var',
    },
  ]

  function getStyles(name, companyType, theme) {
    return {
      fontWeight:
        companyType.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  const theme = useTheme()
  const [companyType, setCompanyType] = React.useState([])

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCompanyType(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const { enqueueSnackbar, error, success } = props
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)

  const [redirect, setRedirect] = React.useState(false)

  const [values, setValues] = React.useState({
    company_name: '',
    company_type: '',
    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '001',
    poc_phone_number: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    company_name: '',
    company_type: '',
    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '',
    poc_phone_number: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.company_type) {
      isError = true
      formerr.company_type =
        dictionary[localStorage.getItem('language')].company_type_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!values.company_name) {
      isError = true
      formerr.company_name =
        dictionary[localStorage.getItem('language')].company_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!values.poc_first_name) {
      isError = true
      formerr.poc_first_name =
        dictionary[localStorage.getItem('language')].first_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.poc_last_name) {
      isError = true
      formerr.poc_last_name =
        dictionary[localStorage.getItem('language')].last_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.poc_e_mail) {
      isError = true
      formerr.poc_e_mail =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (values.poc_e_mail && !validator.isEmail(values.poc_e_mail)) {
      isError = true
      formerr.poc_e_mail =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].invalid_tooltip
      setFormValidation(formerr)
    }

    if (!values.poc_country_code) {
      isError = true
      formerr.poc_country_code =
        dictionary[localStorage.getItem('language')].country_code_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    } else if (values.poc_country_code.length > 3) {
      isError = true
      formerr.poc_country_code =
        'The country code must be between 1 to 3 digits'
      setFormValidation(formerr)
    }

    if (!values.poc_phone_number) {
      isError = true
      formerr.poc_phone_number =
        dictionary[localStorage.getItem('language')].telephone_header +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    } else if (values.poc_phone_number.length < 10) {
      isError = true
      formerr.poc_phone_number = 'Telephone number at least 10 digits'
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    if (prop == 'poc_phone_number') {
      value = value.split('-').join('')
    }

    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e, returnRequired) => {
    e.preventDefault()
    if (handleValidation()) return false
    const Data = {
      companyName: values.company_name,
      companyType: values.company_type,
      POCFirstName: values.poc_first_name,
      POCLastName: values.poc_last_name,
      POCEmail: values.poc_e_mail,
      POCCountryCode: values.poc_country_code,
      POCPhoneNumber: values.poc_phone_number,
    }

    dispatch(createCompanyRequest(Data))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
    if (success) {
      setRedirect(true)
    }
  }, [success])

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['company_name'] = ''
    resetForm['company_type'] = ''
    resetForm['poc_first_name'] = ''
    resetForm['poc_last_name'] = ''
    resetForm['poc_e_mail'] = ''
    resetForm['poc_country_code'] = '001'
    resetForm['poc_phone_number'] = ''

    setValues(resetForm)
  }

  //   const handleGenerateCompanyId = () => {
  //     const formerr = { ...formValidation };
  //     formerr['company_id'] = null;
  //     setFormValidation(formerr);

  //     const formval = { ...values };
  //     formval['company_id'] = generateUniqueNumber(4, 'alphaNumber');
  //     setValues(formval)
  //   }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      {redirect ? <Redirect to="/varPanel" /> : ''}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-10 text-center">
                  <Typography variant="h5" className="text-center">
                    {
                      dictionary[localStorage.getItem('language')]
                        .create_a_company_title
                    }
                  </Typography>
                </div>
                <div className="col-lg-2">
                  <Link class="btn btn-primary float-right" to="/varPanel">
                    {
                      dictionary[localStorage.getItem('language')]
                        .company_list_button
                    }
                  </Link>
                </div>
              </div>
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          id="cname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .company_name_field_name
                          }
                          variant="outlined"
                          name="company_name"
                          value={values.company_name}
                          onChange={handleChange('company_name')}
                          error={!!formValidation.company_name}
                          helperText={formValidation.company_name}
                        />
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <FormControl
                          variant="outlined"
                          sx={{ m: 1, width: 300 }}
                          error={!!formValidation.company_type}
                          className="col-md-12"
                        >
                          <InputLabel id="demo-multiple-name-label">
                            {
                              dictionary[localStorage.getItem('language')]
                                .company_type_field_name
                            }
                          </InputLabel>
                          <Select
                            name="company_type"
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={values.company_type}
                            onChange={handleChange('company_type')}
                            input={<OutlinedInput label="Company Type" />}
                            MenuProps={MenuProps}
                          >
                            {company_type.map((it) => (
                              <MenuItem
                                key={it.label}
                                value={it.value}
                                style={getStyles(it.value, companyType, theme)}
                              >
                                {it.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {formValidation.company_type}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .technical_poc_header
                            }
                          </strong>
                        </Typography>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .last_name_field_name
                          }
                          variant="outlined"
                          name="poc_last_name"
                          value={values.poc_last_name}
                          onChange={handleChange('poc_last_name')}
                          error={!!formValidation.poc_last_name}
                          helperText={formValidation.poc_last_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .first_name_field_name
                          }
                          variant="outlined"
                          name="poc_first_name"
                          value={values.poc_first_name}
                          onChange={handleChange('poc_first_name')}
                          error={!!formValidation.poc_first_name}
                          helperText={formValidation.poc_first_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="email"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .email_field_name
                          }
                          variant="outlined"
                          name="poc_e_mail"
                          value={values.poc_e_mail}
                          onChange={handleChange('poc_e_mail')}
                          error={!!formValidation.poc_e_mail}
                          helperText={formValidation.poc_e_mail}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="countryCode"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .country_code_field_name
                          }
                          variant="outlined"
                          name="poc_country_code"
                          value={values.poc_country_code}
                          onChange={(e) => {
                            if (
                              e.target.value === '' ||
                              /^[0-9\b]+$/.test(e.target.value)
                            ) {
                              handleChange('poc_country_code')(e)
                            }
                          }}
                          error={!!formValidation.poc_country_code}
                          helperText={formValidation.poc_country_code}
                          inputProps={{
                            inputMode: 'numeric',
                            maxLength: 3,
                            pattern: '[0-9]{1,3}',
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <InputMask
                          mask="999-999-9999"
                          value={values.poc_phone_number}
                          onChange={handleChange('poc_phone_number')}
                        >
                          {(inputProps) => (
                            <TextField
                              id="telephone"
                              fullWidth
                              label={
                                dictionary[localStorage.getItem('language')]
                                  .telephone_header
                              }
                              variant="outlined"
                              name="poc_phone_number"
                              error={!!formValidation.poc_phone_number}
                              helperText={formValidation.poc_phone_number}
                            />
                          )}
                        </InputMask>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .submit_button
                          }{' '}
                          &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
  }
}

NewCompanyRequest.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(NewCompanyRequest))
