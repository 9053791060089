import React, { useEffect, useMemo } from 'react'
import { Redirect, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { generateUniqueNumber } from '../helper/commonHelper'
import {
  createCompany,
  companyDropDownList,
  resetErrorAndSuccessState,
} from '../store/actions/companyActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import { withSnackbar } from 'notistack'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Switch from '@material-ui/core/Switch'
import validator from 'validator'
import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'
import '../App.css'
import { Autocomplete } from '@material-ui/lab'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const AddCompany = (props) => {
  const COMPANY_TYPE = {
    OEM: 'oem',
    VAR: 'var',
    EC: 'ec',
    ROOT: 'root',
  }

  const company_type = [
    { label: 'Original Equipment Manufacturer (OEM)', value: 'oem' },
    { label: 'End Consumer (EC)', value: 'ec' },
    { label: 'Value Added Reseller (VAR)', value: 'var' },
  ]

  const discount = [
    { label: '0% (Retail)', value: 0 },
    { label: '15% (1-24 Units/Year)', value: 15 },
    { label: '19% (25-99 Units/Year)', value: 19 },
    { label: '24% (100-249 Units/Year)', value: 24 },
    { label: '30% (250-999 Units/Year)', value: 30 },
    { label: '40% (1000+ Units/Year)', value: 40 },
    { label: '50% (International)', value: 50 },
  ]
  function getStyles(name, companyType, theme) {
    return {
      fontWeight:
        companyType.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  const theme = useTheme()
  const [companyType, setCompanyType] = React.useState([])

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCompanyType(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const { enqueueSnackbar, error, success } = props
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)

  const [redirect, setRedirect] = React.useState(false)
  const { company_Drop_Down_List } = useSelector((state) => state.company)
  const [values, setValues] = React.useState({
    company_name: '',

    company_type: null,
    parent_company_code: null,
    discount_percentage: 0,
    // next_billing_date : "",

    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '001',
    poc_phone_number: '',
    company_id: '',
    is_deactive: '',
  })

  useEffect(() => {
    dispatch(companyDropDownList())
  }, [dispatch])

  const [formValidation, setFormValidation] = React.useState({
    company_name: '',

    company_type: '',
    parent_company_code: '',
    discount_percentage: '',
    // next_billing_date : ""

    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '',
    poc_phone_number: '',
    company_id: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.company_type) {
      isError = true
      formerr.company_type = 'Required company type'
      setFormValidation(formerr)
    }

    if (!values.parent_company_code) {
      isError = true
      formerr.parent_company_code = 'Required parent company code'
      setFormValidation(formerr)
    }

    if (
      values.discount_percentage == null ||
      values.discount_percentage == ''
    ) {
      if (values.discount_percentage !== 0) {
        isError = true
        formerr.discount_percentage = 'Required discount percentage'
        setFormValidation(formerr)
      }
    }

    if (!values.company_name) {
      isError = true
      formerr.company_name = 'Required company name'
      setFormValidation(formerr)
    }

    if (!values.poc_first_name) {
      isError = true
      formerr.poc_first_name = 'Required first name'
      setFormValidation(formerr)
    }

    if (!values.poc_last_name) {
      isError = true
      formerr.poc_last_name = 'Required last name'
      setFormValidation(formerr)
    }

    if (!values.poc_e_mail) {
      isError = true
      formerr.poc_e_mail = 'Required email'
      setFormValidation(formerr)
    }

    if (values.poc_e_mail && !validator.isEmail(values.poc_e_mail)) {
      isError = true
      formerr.poc_e_mail = 'Email invalid'
      setFormValidation(formerr)
    }

    if (!values.poc_country_code) {
      isError = true
      formerr.poc_country_code = 'Required country code'
      setFormValidation(formerr)
    } else if (values.poc_country_code.length > 3) {
      isError = true
      formerr.poc_country_code =
        'The country code must be between 1 to 3 digits'
      setFormValidation(formerr)
    }

    if (!values.poc_phone_number) {
      isError = true
      formerr.poc_phone_number = 'Required telephone'
      setFormValidation(formerr)
    } else if (values.poc_phone_number.length < 10) {
      isError = true
      formerr.poc_phone_number = 'Telephone number at least 10 digits'
      setFormValidation(formerr)
    }

    if (!values.company_id) {
      isError = true
      formerr.company_id = 'Required company ID'
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    if (prop == 'poc_phone_number') {
      value = value.split('-').join('')
    }

    const newValues = { ...values, [prop]: value }
    const formerr = { ...formValidation }
    if (prop == 'company_type') {
      newValues.parent_company_code =
        getUpdatedParentCompanySelectionValue(newValues)
      newValues.discount_percentage = getUpdatedDiscountValue(newValues)
      if (newValues.discount_percentage) {
        formerr['discount_percentage'] = null
      }
    }
    setValues(newValues)

    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e, returnRequired) => {
    e.preventDefault()
    if (handleValidation()) return false

    const Data = {
      companyName: values.company_name,

      companyType: values.company_type,
      parentCompanyId: values.parent_company_code.id,
      discountPercentage: values.discount_percentage,
      nextBillingDate: values.next_billing_date,

      POCFirstName: values.poc_first_name,
      POCLastName: values.poc_last_name,
      POCEmail: values.poc_e_mail,
      POCCountryCode: values.poc_country_code,
      POCPhoneNumber: values.poc_phone_number,
      companyId: values.company_id,
      isDeactive: values.is_deactive ? '1' : '0',
    }

    dispatch(createCompany(Data))
  }

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
    if (success) {
      setRedirect(true)
    }
  }, [success])

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['company_name'] = ''

    resetForm['company_type'] = null
    resetForm['parent_company_code'] = ''
    resetForm['discount_percentage'] = ''
    // resetForm["next_billing_date"] = ""

    resetForm['poc_first_name'] = ''
    resetForm['poc_last_name'] = ''
    resetForm['poc_e_mail'] = ''
    resetForm['poc_country_code'] = '001'
    resetForm['poc_phone_number'] = ''
    resetForm['company_id'] = ''
    resetForm['is_deactive'] = ''
    setValues(resetForm)
  }

  const handleGenerateCompanyId = () => {
    const formerr = { ...formValidation }
    formerr['company_id'] = null
    setFormValidation(formerr)

    const formval = { ...values }
    formval['company_id'] = generateUniqueNumber(4, 'alphaNumber')
    setValues(formval)
  }

  const companyDropdownFilteredList = useMemo(() => {
    if (values['company_type'] == COMPANY_TYPE.OEM) {
      return company_Drop_Down_List.filter(
        (it) => it.company_type === COMPANY_TYPE.ROOT
      )
    } else if (
      [COMPANY_TYPE.VAR, COMPANY_TYPE.EC].includes(values['company_type'])
    ) {
      return company_Drop_Down_List.filter(
        (it) =>
          it.company_type === COMPANY_TYPE.ROOT ||
          it.company_type === COMPANY_TYPE.VAR
      )
    }
    return company_Drop_Down_List
  }, [values, company_Drop_Down_List])

  const handleChangeSelection = (event, type) => {
    if (event) {
      if (type === 'parent_company_code') {
        setValues({ ...values, parent_company_code: event })
      }

      const formerr = { ...formValidation }
      formerr[type] = null
      setFormValidation(formerr)
    }
  }

  const getUpdatedParentCompanySelectionValue = (tempValues) => {
    if (tempValues['company_type'] == COMPANY_TYPE.OEM) {
      if (
        tempValues['parent_company_code']?.company_type !== COMPANY_TYPE.ROOT
      ) {
        return null
      }
    } else if (
      [COMPANY_TYPE.VAR, COMPANY_TYPE.EC].includes(tempValues['company_type'])
    ) {
      if (
        ![COMPANY_TYPE.VAR, COMPANY_TYPE.ROOT].includes(
          tempValues['parent_company_code']?.company_type
        )
      ) {
        return null
      }
    }
    return tempValues.parent_company_code
  }

  const getUpdatedDiscountValue = (tempValues) => {
    if (tempValues['company_type'] == COMPANY_TYPE.EC) {
      return discount[0].value
    } else {
      return discount[1].value
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      {redirect ? <Redirect to="/company" /> : ''}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-10 text-center">
                  <Typography variant="h5" className="text-center">
                    Create a Company
                  </Typography>
                </div>
                <div className="col-lg-2">
                  <Link class="btn btn-primary float-right" to="/company">
                    Company List
                  </Link>
                </div>
              </div>
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          id="cname"
                          fullWidth
                          label="Company Name (required)"
                          variant="outlined"
                          name="company_name"
                          value={values.company_name}
                          onChange={handleChange('company_name')}
                          error={!!formValidation.company_name}
                          helperText={formValidation.company_name}
                        />
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <FormControl
                          variant="outlined"
                          sx={{ m: 1, width: 300 }}
                          error={!!formValidation.company_type}
                          className="col-md-12"
                        >
                          <InputLabel id="demo-multiple-name-label">
                            Company Type
                          </InputLabel>
                          <Select
                            name="company_type"
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={values.company_type}
                            onChange={handleChange('company_type')}
                            input={<OutlinedInput label="Company Type" />}
                            MenuProps={MenuProps}
                          >
                            {company_type.map((it) => (
                              <MenuItem
                                key={it.label}
                                value={it.value}
                                style={getStyles(it.value, companyType, theme)}
                              >
                                {it.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {formValidation.company_type}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!formValidation.parent_company_code}
                        >
                          <Autocomplete
                            id="parent-company-box-pac-blu"
                            name="parent_company_code"
                            value={values.parent_company_code}
                            onChange={(event, val) =>
                              handleChangeSelection(val, 'parent_company_code')
                            }
                            options={companyDropdownFilteredList}
                            disabled={!values.company_type}
                            getOptionLabel={(option) =>
                              option
                                ? option.company_name +
                                  ' (' +
                                  option.company_id +
                                  ') - ' +
                                  option.company_type.toUpperCase()
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Parent Company Code (required)"
                                variant="outlined"
                                error={!!formValidation.parent_company_code}
                              />
                            )}
                          />
                          <FormHelperText>
                            {formValidation.parent_company_code}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-md-12">
                        {/* <FormControl sx={{ m: 1, width: 300 }}  className="col-md-12">
                        <InputLabel id="demo-multiple-name-label">Discount</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          // multiple
                          value={values.discount_percentage}
                          // onChange={handleChange}
                          input={<OutlinedInput label="Discount" />}
                          MenuProps={MenuProps}
                        >
                          {discount.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              // style={getStyles(name, companyType, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                        <FormControl
                          variant="outlined"
                          sx={{ m: 1, width: 300 }}
                          error={!!formValidation.discount_percentage}
                          className="col-md-12"
                        >
                          <InputLabel id="demo-multiple-name-label">
                            Discount
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            name="discount_percentage"
                            value={values.discount_percentage}
                            onChange={handleChange('discount_percentage')}
                            input={<OutlinedInput label="Discount" />}
                            MenuProps={MenuProps}
                          >
                            {discount.map((it) => (
                              <MenuItem
                                key={it.label}
                                value={it.value}
                                style={getStyles(it.value, companyType, theme)}
                              >
                                {it.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {formValidation.discount_percentage}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Typography variant="subtitle2">
                          <strong>Technical POC</strong>
                        </Typography>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          label="Last Name (required)"
                          variant="outlined"
                          name="poc_last_name"
                          value={values.poc_last_name}
                          onChange={handleChange('poc_last_name')}
                          error={!!formValidation.poc_last_name}
                          helperText={formValidation.poc_last_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label="First Name (required)"
                          variant="outlined"
                          name="poc_first_name"
                          value={values.poc_first_name}
                          onChange={handleChange('poc_first_name')}
                          error={!!formValidation.poc_first_name}
                          helperText={formValidation.poc_first_name}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="email"
                          fullWidth
                          label="E-Mail (required)"
                          variant="outlined"
                          name="poc_e_mail"
                          value={values.poc_e_mail}
                          onChange={handleChange('poc_e_mail')}
                          error={!!formValidation.poc_e_mail}
                          helperText={formValidation.poc_e_mail}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="countryCode"
                          fullWidth
                          label="Country code (required)"
                          variant="outlined"
                          name="poc_country_code"
                          value={values.poc_country_code}
                          onChange={(e) => {
                            if (
                              e.target.value === '' ||
                              /^[0-9\b]+$/.test(e.target.value)
                            ) {
                              handleChange('poc_country_code')(e)
                            }
                          }}
                          error={!!formValidation.poc_country_code}
                          helperText={formValidation.poc_country_code}
                          inputProps={{
                            inputMode: 'numeric',
                            maxLength: 3,
                            pattern: '[0-9]{1,3}',
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <InputMask
                          mask="999-999-9999"
                          value={values.poc_phone_number}
                          onChange={handleChange('poc_phone_number')}
                        >
                          {(inputProps) => (
                            <TextField
                              id="telephone"
                              fullWidth
                              label="Telephone (required)"
                              variant="outlined"
                              name="poc_phone_number"
                              error={!!formValidation.poc_phone_number}
                              helperText={formValidation.poc_phone_number}
                            />
                          )}
                        </InputMask>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className="mr-2"
                          onClick={handleGenerateCompanyId}
                        >
                          Generate Company ID
                        </Button>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          size="small"
                          id="id"
                          label="ID"
                          variant="outlined"
                          name="company_id"
                          value={values.company_id}
                          error={!!formValidation.company_id}
                          helperText={formValidation.company_id}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          Create &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 "></div>
                  <div className="col-md-5 ">
                    <div className="row">
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: 'justify', marginRight: 18 }}
                      >
                        <strong>
                          Administrator's Note: The Company Name along with the
                          E-Mail address must be unique. It is allowable to have
                          the same Company Name, but the e-mail addresses must
                          be different. It is allowable to have multiple Company
                          Names associated with one E-Mail address. It is not
                          permitted to have a Company Name and E-Mail address
                          pair match another.
                        </strong>
                      </Typography>
                    </div>
                    <div className="row mt-4">
                      <TextField
                        id="billingDueDate"
                        fullWidth
                        label="Billing Due Date"
                        variant="outlined"
                        name="next_billing_date"
                        value={values.next_billing_date}
                        onChange={handleChange('next_billing_date')}
                        error={!!formValidation.next_billing_date}
                        helperText={formValidation.next_billing_date}
                        disabled={true}
                      />
                    </div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2">
                          <strong>De-Activate Company </strong>
                        </Typography>
                        <Switch
                          color="primary"
                          checked={values.is_deactive}
                          inputProps={{ 'aria-label': 'primary' }}
                          name="is_deactive"
                          onChange={handleChange('is_deactive')}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
  }
}

AddCompany.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(AddCompany))
