import axios from 'axios'
const Axios = axios.create({
  //baseURL: "http://44.227.243.80:3000/admin",
  //  baseURL: "https://beta.pac-blu.com/admin",
  baseURL: process.env.REACT_APP_BASE_URL + 'admin/',
})

// Axios.defaults.headers.Accept = "";
// Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
// Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

//Axios.defaults.headers.common['timestamp'] = Date.now()

export default Axios

export const setHeaders = () => {
  Axios.defaults.headers.common['timestamp'] = Date.now()
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  Axios.defaults.headers.common['id'] = localStorage.getItem('logedUserId')
}
