import * as actionTypes from '../actions/actionTypes'
const initialState = {
  ota_List: '',
  error: '',
  loading: false,
  authRedirectPath: '/',
  success: '',
  message: null,
  pageLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ISLOADING:
      return {
        ...state,
        error: null,
        success: null,
        loading: true,
      }
    case actionTypes.ISPAGELOADING:
      return {
        ...state,
        error: null,
        pageLoading: true,
      }
    case actionTypes.OTA_LISTING:
      return {
        ...state,
        ota_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.OTA_ADDED:
      return {
        ...state,
        ota_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
        success: action.listing.message,
        is_redirect: true,
      }
    case actionTypes.OTA_DELETE:
      return {
        ...state,
        ota_List: action.listing.payload,
        error: null,
        loading: false,
        pageLoading: false,
        success: action.listing.message,
        is_redirect: true,
      }

    case actionTypes.OTA_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        pageLoading: false,
        success: false,
        is_redirect: false,
      }
    case actionTypes.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
