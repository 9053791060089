import * as actionTypes from './actionTypes'
import Axios, { setHeaders } from '../../helper/axios'
import { createActivityLog } from '../../helper/commonHelper'

export const isPageLoading = () => {
  return {
    type: actionTypes.ISPAGELOADING,
  }
}

export const fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  }
}

export const resetErrorAndSuccessState = () => {
  return {
    type: actionTypes.REST_ERROR_AND_SUCCESS_STATE,
  }
}

export const deviceTypeListSuccess = (listing) => {
  return {
    type: actionTypes.DEVICE_TYPE_LISTING,
    listing,
  }
}

export const deviceTypeDetailsSuccess = (listing) => {
  return {
    type: actionTypes.DEVICE_TYPE_DETAILS,
    listing,
  }
}

export const deviceTypeAddedSuccess = (listing) => {
  return {
    type: actionTypes.DEVICE_TYPE_ADDED,
    listing,
  }
}

export const deviceTypeUpdatedSuccess = (listing) => {
  return {
    type: actionTypes.DEVICE_TYPE_UPDATE,
    listing,
  }
}

export const deviceTypeDeleteSuccess = (listing) => {
  return {
    type: actionTypes.DEVICE_TYPE_DELETE,
    listing,
  }
}

export const token = () => {
  return localStorage.getItem('token')
}

export const deviceTypeList = (needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.get('/deviceType/getAllDeviceTypes', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deviceTypeListSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const deviceTypeDetails = (id, needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.get(`/deviceType/getDeviceTypeDetails?id=${id}`, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deviceTypeDetailsSuccess(response.data.payload))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const uploadDeviceType = (requestPayload) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.post('/deviceType/addDeviceType', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deviceTypeAddedSuccess(response.data))
          createActivityLog('Device Type Added', '', requestPayload)
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const modifyDeviceType = (requestPayload) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.put('/deviceType/updateDeviceType', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deviceTypeUpdatedSuccess(response.data))
          createActivityLog('Device Type updated', '', requestPayload)
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}

export const deviceTypeDelete = (id) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.delete(`/deviceType/deleteDeviceType?id=${id}`, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deviceTypeDeleteSuccess(response.data))
        } else {
          dispatch(fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(fail(err.response.data.message))
        }
      })
  }
}
