import * as actionTypes from '../actions/actionTypes'
const initialState = {
  user: null,
  error: '',
  errorCode: '',
  loading: false,
  authRedirectPath: '/',
  success: null,
  success_message: null,
  permissionDetails: {
    permission_add: false,
    permission_modifly: false,
    permission_delete: false,
    permission_all: false,
    permission_module: {},
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case actionTypes.AUTH_SUCCESS:
      state.error = null
      state.errorCode = null
      return {
        ...state,
        user: action,
        error: null,
        loading: false,
      }
    case actionTypes.ALERT_SUCCESS:
      state.error = null
      state.errorCode = null
      state.alert = action.alert.data.payload
      return {
        ...state,
        alert: action.alert.data.payload,
        error: null,
        loading: false,
      }
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case actionTypes.AUTHERROR:
      return {
        ...state,
        error: action.error,
        errorCode: action.errorCode,
        loading: false,
      }
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        token: null,
      }
    case actionTypes.SUCCESS_MESSAGE_AUTH:
      return {
        ...state,
        success_message: action.msg,
        loading: false,
      }
    case actionTypes.RESET_PASSWORD_EXPIRED:
      return {
        ...state,
        success: action.success_message,
        loading: false,
      }
    case actionTypes.PERMISSION_DETAILS:
      return {
        ...state,
        permissionDetails: action.payload,
      }

    case actionTypes.FORGOT_SUCCESS:
      return {
        ...state,
        success_message: action.success_message,
        loading: false,
        success: true,
      }

    case actionTypes.REST_ERROR_AND_SUCCESS_STATE:
      return {
        ...state,
        error: null,
        errorCode: null,
        loading: false,
        pageLoading: false,
        success: false,
        is_redirect: false,
      }

    default:
      return state
  }
}

export default reducer
