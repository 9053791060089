import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'
import { Close } from '@material-ui/icons'
import {
  deviceTypeDelete,
  deviceTypeList,
  resetErrorAndSuccessState,
} from '../store/actions/deviceTypeAction'

const DeviceType = (props) => {
  const { enqueueSnackbar, error, success } = props

  const dispatch = useDispatch()
  const { device_type_List } = useSelector((state) => state.deviceType)

  const [popups, setpopups] = React.useState(false)

  const history = useHistory()

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      filterable: false,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <Tooltip title="Click to Device Type Modify" arrow>
          <img
            alt="DeviceType"
            src="../assets/img/lock_single_blue.svg"
            style={{ cursor: 'pointer', scale: '0.6' }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'device_code',
      headerName: 'Device Code',
      width: 200,
      renderHeader: () => <strong>{'Device Code'}</strong>,
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 200,
      renderHeader: () => <strong>{'Device Type'}</strong>,
    },
    {
      field: 'Delete',
      headerName: 'Action',
      width: 110,
      renderHeader: () => <strong>{'Action'}</strong>,
      renderCell: (params) => (
        <IconButton onClick={() => setpopups(params.id)}>
          <Delete color="secondary" />
        </IconButton>
      ),
    },
  ]

  useEffect(() => {
    dispatch(deviceTypeList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    setPageLoading(false)
  }, [success])

  const deleteOta = () => {
    //alert(popups)
    setpopups(false)
    setPageLoading(true)
    dispatch(deviceTypeDelete(popups))
  }

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id

    if (action == '#') {
      localStorage.setItem('tempDeviceType', id)
      history.push({
        pathname: '/modifyDeviceType',
      })
    } else if (action == 'Delete') {
      var row = GridCellParams.row
      const id = GridCellParams.row.id
    }
  }

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <Dialog open={popups} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm</DialogTitle>
        <Box
          position="absolute"
          top={0}
          right={0}
          onClick={() => setpopups(false)}
        >
          <IconButton>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to delete this?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={deleteOta}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <Link
                      className="btn btn-primary float-right"
                      to="/addDeviceType"
                    >
                      Add
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card-body" style={{ height: 480, width: '100%' }}>
                {device_type_List.length > 0 ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={device_type_List}
                    columns={columns}
                    pageSize={25}
                    loading={props.loading}
                  />
                ) : (
                  <p>Sorry no records found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceType.loading,
    error: state.deviceType.error,
    success: state.deviceType.success,
  }
}

DeviceType.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(DeviceType))
