//AUTH
export const AUTH_START = 'AUTH_START'
// export const FORCED_PASSWORD_RESET= "FORCED_PASSWORD_RESET"
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const AUTHERROR = 'AUTHERROR'
export const ISLOADING = 'ISLOADING'
export const ERROR = 'ERROR'
export const SUCCESS_MESSAGE_AUTH = 'SUCCESS_MESSAGE_AUTH'
export const PERMISSION_DETAILS = 'PERMISSION_DETAILS'
export const RESET_PASSWORD_EXPIRED = 'RESET_PASSWORD_EXPIRED'
export const ALERT_SUCCESS = 'ALERT_SUCCESS'

//USER ACTIONS
export const ISPAGELOADING = 'ISPAGELOADING'
export const USER_LIST = 'USER_LIST'
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST'
export const ASSIGNED_EKEY_LIST = 'ASSIGNED_EKEY_LIST'
export const EKEY_LIST = 'EKEY_LIST'
export const TIMEZONE_LIST = 'TIMEZONE_LIST'
export const ISLOADINGEKEYASSIGN = 'ISLOADINGEKEYASSIGN'
export const USER_DETAILS = 'USER_DETAILS'
export const DEVICE_DETAILS = 'DEVICE_DETAILS'
export const LAST_REPORTED_LOCATION = 'LAST_REPORTED_LOCATION'
export const MODIFY_SUCCESS = 'MODIFY_SUCCESS'
export const REST_ERROR_AND_SUCCESS_STATE = 'REST_ERROR_AND_SUCCESS_STATE'
export const SUCCESS = 'SUCCESS'
export const ERROR_USER_LISTING = 'ERRORUSERLISTING'
export const SUCCESS_USER_LISTING = 'SUCCESSUSERLISTING'
export const USER_DETAILS_OLD = 'USER_DETAILS_OLD'

//DEVICE GROUP
export const AVAILABLE_DEVICE__LIST = 'AVAILABLE_DEVICE__LIST'
export const UPDATE_DEVICE__LIST = 'UPDATE_DEVICE__LIST'
export const DEVICE_GROUP_LIST = 'DEVICE_GROUP_LIST'
export const UPDATE_DEVICE_GROUP_LIST = 'UPDATE_DEVICE_GROUP_LIST'
export const DEVICE_GROUP_DETAILS = 'DEVICE_GROUP_DETAILS'
export const GENERATED_TICKET_LIST = 'GENERATED_TICKET_LIST'
export const GENERATED_TICKET_DETAILS = 'GENERATED_TICKET_DETAILS'
export const DEVICE_GROUP_DETAILS_OLD = 'DEVICE_GROUP_DETAILS_OLD'

//COMPANY
export const COMPANY_DETAILS = 'COMPANY_DETAILS'
export const COMPANY_LIST = 'COMPANY_LIST'
export const COMPANY_DROP_DOWN_LIST = 'COMPANY_DROP_DOWN_LIST'
export const COMPANY_REQUEST_LIST = 'COMPANY_REQUEST_LIST'
export const COMPANY_REQUEST_ACCEPTED = 'COMPANY_REQUEST_ACCEPTED'
export const COMPANY_REQUEST_REJECTED = 'COMPANY_REQUEST_REJECTED'
export const COMPANY_RELATED_DEVICE_LIST = 'COMPANY_RELATED_DEVICE_LIST'
export const COMPANY_RELATED_INVOICE_LIST = 'COMPANY_RELATED_INVOICE_LIST'
export const COMPANY_MAILER_SETTING = 'COMPANY_MAILER_SETTING'
export const VAR_PANEL_LIST = 'VAR_PANEL_LIST'
export const INVOICE_LIST = 'INVOICE_LIST'
export const INVOICE_DETAIL = 'INVOICE_DETAIL'
export const CREATE_CUSTOMER_INVOICE_DETAIL = 'CREATE_CUSTOMER_INVOICE_DETAIL'
export const UPDATE_CREATE_CUSTOMER_INVOICE_LINE_ITEM =
  'UPDATE_CREATE_CUSTOMER_INVOICE_LINE_ITEM'
export const COMPANY_STOCK_LIST = 'COMPANY_STOCK_LIST'

//COMPANY SETTING
export const COMPANY_SETTING_DETAILS = 'COMPANY_SETTING_DETAILS'
export const FOB_PROGRAMMER_LISTING = 'FOB_PROGRAMMER_LISTING'
export const UPDATE_FOB_PROGRAMMER_LIST = 'UPDATE_FOB_PROGRAMMER_LIST'
export const FOB_PROGRAMMER_LISTING_OLD = 'FOB_PROGRAMMER_LISTING_OLD'
export const COMPANY_SETTING_DETAILS_OLD = 'COMPANY_SETTING_DETAILS_OLD'

// ROLE
export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_USER_LIST = 'ROLE_USER_LIST'
export const ROLE_DETAILS = 'ROLE_DETAILS'
export const ASSINED_ROLE_USER_LIST = 'ASSINED_ROLE_USER_LIST'
// ACTIVITY
export const ACTIVITY_LOG_LIST = 'ACTIVITY_LOG_LIST'
export const ACTIVITY_LOG_NOTES_LIST = 'ACTIVITY_LOG_LOG_LIST'
export const ACTIVITY_LOG_DETAILS = 'ACTIVITY_LOG_DETAILS'
export const ACTIVITY_LOG_REPORT = 'ACTIVITY_LOG_REPORT'

// OTA
export const OTA_LISTING = 'OTA_LISTING'
export const OTA_ADDED = 'OTA_ADDED'
export const OTA_DELETE = 'OTA_DELETE'
export const OTA_RESET = 'OTA_RESET'

// DEVICE TYPE
export const DEVICE_TYPE_LISTING = 'DEVICE_TYPE_LISTING'
export const DEVICE_TYPE_DETAILS = 'DEVICE_TYPE_DETAILS'
export const DEVICE_TYPE_ADDED = 'DEVICE_TYPE_ADDED'
export const DEVICE_TYPE_DELETE = 'DEVICE_TYPE_DELETE'
export const DEVICE_TYPE_UPDATE = 'DEVICE_TYPE_UPDATE'

//Forgot Password
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
