import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom'

import Header from './Header'
import Footer from './footer'
import Sidebar from './Sidebar'
import Dashboard from './Dashboard'
import Users from './Users/Users'
import AddUser from './Users/AddUser'
import Devices from './Devices/Devices'
import DeviceDetail from './Devices/DeviceDetails'
import DeviceGroup from './DeviceGroup/DeviceGroup'
import Company from './Company/Company'
import AddCompany from './Company/AddCompany'
import ModifyCompany from './Company/ModifyCompany'
import AddDeviceGroup from './DeviceGroup/AddDeviceGroup'
import ModifyDeviceGroup from './DeviceGroup/ModifyDeviceGroup'
import CompanySettings from './CompanySettings/CompanySettings'
import ScheduleOpen from './CompanySettings/ScheduleOpen'
import SetFobProgramers from './CompanySettings/SetFobProgramers'
import ChangePassword from './CompanySettings/ChangePassword'
import Activity from './Activity/Activity'
import Support from './Support/Support'
import AdminSupport from './Support/Admin_Support'
import SupportTicketDetails from './Support/SupportTicketDetails'
import SupportTicketGPSDetails from './Support/SupportTicketGPSDetails'
import ModifyUser from './Users/ModifyUser'
import Role from './role/Role'
import AddRole from './role/AddRole'
import ModifyRole from './role/ModifyRole'
import AssignRole from './role/AssignRole'
import { useSelector, useDispatch } from 'react-redux'
import ActivityDetails from './Activity/ActivityDetails'
import ModifyCompanyMailer from './CompanyMailerSetting/ModifyCompanyMailer'
import ViewCompanyRequests from './Company/ViewCompanyRequests'
import VarPanel from './Company/VarPanel'
import BillingPortal from './Company/BillingPortal'
import InvoiceDetails from './Company/InvoiceDetails'
import CompanyRelatedInvoiceDetail from './Company/CompanyRelatedInvoiceDetail'

import CreateCustomerInvoice from './Company/CreateCustomerInvoice'
import ViewDeviceStock from './Company/ViewDeviceStock'
import NewCompanyRequest from './Company/NewCompanyRequest'

import Ota from './Ota/Ota'
import AddOta from './Ota/AddOta'
import DeviceTransfer from './Company/DeviceTransfer'
import CompanyRelatedDeviceKeys from './Company/CompanyRelatedDeviceKeys'
import CompanyRelatedInvoices from './Company/CompanyRelatedInvoices'
import { logoutAction } from './store/actions/auth'
import DeviceType from './DeviceType/DeviceType'
import AddDeviceType from './DeviceType/AddDeviceType'
import ModifyDeviceType from './DeviceType/ModifyDeviceType'

export default function Master() {
  let location = useLocation()
  const history = useHistory()
  const { permissionDetails } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const logout = () => {
    const token = localStorage.getItem('token')

    dispatch(logoutAction(token))

    let language = localStorage.getItem('language')
    let companyDetails = localStorage.getItem('companyDetails')
    localStorage.clear()
    localStorage.setItem('language', language)
    if (companyDetails !== null) {
      localStorage.setItem('companyDetails', companyDetails)
    }
    history.push('/login')
  }

  const redirectToLogin = () => {
    history.push('/login')
  }

  return (
    <Router>
      {/* <Sidebar /> */}
      <div className="main-content" id="panel">
        <Header
          logout={() => logout()}
          redirectToLogin={() => redirectToLogin()}
        />
        {/* <Header /> */}
        <Switch>
          {/* SUPPORT TICKET */}
          {permissionDetails.permission_module.support ? (
            <Route path="/support">
              <Support />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.support ||
          permissionDetails.permission_module.company ? (
            <Route path="/supportTicketDetails">
              <SupportTicketDetails />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.support ||
          permissionDetails.permission_module.company ? (
            <Route path="/supportTicketGPSDetails">
              <SupportTicketGPSDetails />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/adminSupport">
              <AdminSupport />
            </Route>
          ) : (
            ''
          )}
          {/* ACTIVITY */}
          {permissionDetails.permission_module.activity ? (
            <Route path="/activity">
              <Activity />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.activity ? (
            <Route path="/activityDetails">
              <ActivityDetails />
            </Route>
          ) : (
            ''
          )}
          {/* COMAPNY SETTING */}
          {permissionDetails.permission_module.company_settings ? (
            <Route path="/companySettings">
              <CompanySettings />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company_settings ? (
            <Route path="/setFobProgrammers">
              <SetFobProgramers />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company_settings ? (
            <Route path="/scheduleOpen">
              <ScheduleOpen />
            </Route>
          ) : (
            ''
          )}
          {/* DEVICE GROUP */}
          {permissionDetails.permission_module.device_group ? (
            <Route path="/deviceGroups">
              <DeviceGroup />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.device_group ? (
            <Route path="/addDeviceGroup">
              <AddDeviceGroup />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.device_group ? (
            <Route path="/modifyDeviceGroup">
              <ModifyDeviceGroup />
            </Route>
          ) : (
            ''
          )}
          {/* DEVICE */}
          {permissionDetails.permission_module.devices ? (
            <Route path="/devices">
              <Devices />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.devices ? (
            <Route path="/deviceDetails">
              <DeviceDetail />
            </Route>
          ) : (
            ''
          )}
          {/* USERS */}
          {permissionDetails.permission_module.users ? (
            <Route path="/users">
              <Users />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.users ? (
            <Route path="/adduser">
              <AddUser />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.users ? (
            <Route path="/modifyUser">
              <ModifyUser />
            </Route>
          ) : (
            ''
          )}
          {/* COMPANY */}
          {permissionDetails.permission_module.company ? (
            <Route path="/company">
              <Company />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/ota">
              <Ota />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/addota">
              <AddOta />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/addCompany">
              <AddCompany />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/modifyCompany">
              <ModifyCompany />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/companyRelatedDevices">
              <CompanyRelatedDeviceKeys />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/companyRelatedInvoices">
              <CompanyRelatedInvoices />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ? (
            <Route path="/companyRelatedInvoiceDetail">
              <CompanyRelatedInvoiceDetail />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ||
          permissionDetails.permission_module.users ? (
            <Route path="/companyMailerSetting">
              <ModifyCompanyMailer />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.company ||
          permissionDetails.permission_module.users ? (
            <Route path="/viewCompanyRequests">
              <ViewCompanyRequests />
            </Route>
          ) : (
            ''
          )}
          {/* Device Type */}
          {permissionDetails.permission_module.deviceType ? (
            <Route path="/deviceType">
              <DeviceType />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.deviceType ? (
            <Route path="/addDeviceType">
              <AddDeviceType />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.deviceType ? (
            <Route path="/modifyDeviceType">
              <ModifyDeviceType />
            </Route>
          ) : (
            ''
          )}
          {/* CHANGE PASSWORD */}
          {permissionDetails.permission_module.change_password ? (
            <Route path="/changePassword">
              <ChangePassword />
            </Route>
          ) : (
            ''
          )}
          {/* VAR Panel */}
          {permissionDetails.permission_module.var_panel ? (
            <Route path="/varPanel">
              <VarPanel />
            </Route>
          ) : (
            ''
          )}
          ,{/* Billing Portal */}
          {permissionDetails.permission_module.billing_portal ? (
            <Route path="/billingPortal">
              <BillingPortal />
            </Route>
          ) : (
            ''
          )}
          ,
          {permissionDetails.permission_module.billing_portal ? (
            <Route path="/invoiceDetails">
              <InvoiceDetails />
            </Route>
          ) : (
            ''
          )}
          ,
          {permissionDetails.permission_module.billing_portal ? (
            <Route path="/createCustomerInvoice">
              <CreateCustomerInvoice />
            </Route>
          ) : (
            ''
          )}
          ,
          {permissionDetails.permission_module.var_panel ? (
            <Route path="/viewDeviceStock">
              <ViewDeviceStock />
            </Route>
          ) : (
            ''
          )}
          ,
          {permissionDetails.permission_module.var_panel ? (
            <Route path="/newCompanyRequest">
              <NewCompanyRequest />
            </Route>
          ) : (
            ''
          )}
          ,
          {permissionDetails.permission_module.var_panel ? (
            <Route path="/deviceTransfer">
              <DeviceTransfer />
            </Route>
          ) : (
            ''
          )}
          {/* ROLE */}
          {permissionDetails.permission_module.role ? (
            <Route path="/role">
              <Role />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.role ? (
            <Route path="/addRole">
              <AddRole />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.role ? (
            <Route path="/modifyRole">
              <ModifyRole />
            </Route>
          ) : (
            ''
          )}
          {permissionDetails.permission_module.role ? (
            <Route path="/assignRole">
              <AssignRole />
            </Route>
          ) : (
            ''
          )}
          {/* DASHBOARD */}
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
        {/* <Footer/> */}
      </div>
    </Router>
  )
}
