import React, { useEffect, useState, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { Delete } from '@material-ui/icons'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  getInvoiceDetailsToCreateCustomerInvoice,
  createCustomerInvoice,
  updateCreateCustomerInvoiceLineItem,
  invoiceDetailForCustomerInvoiceSuccess,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography, Button, IconButton, TextField } from '@material-ui/core'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import dictionary from '../store/dictionary.json'
const Big = require('big.js')

const CreateCustomerInvoice = (props) => {
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { create_Customer_Invoice_detail } = useSelector(
    (state) => state.company
  )
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()

  const [formValidation, setFormValidation] = React.useState({
    companyId: '',
    customerId: '',
    invoiceNum: '',
    dueDate: '',
    notes: '',
    lineItems: [],
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!create_Customer_Invoice_detail?.customer_company_detail?.id) {
      isError = true
      formerr.companyId =
        dictionary[localStorage.getItem('language')].customer_header
      setFormValidation(formerr)
    }

    if (!create_Customer_Invoice_detail?.invoice_num) {
      isError = true
      formerr.invoiceNum =
        dictionary[localStorage.getItem('language')].invoice_number_header +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!create_Customer_Invoice_detail?.due_date) {
      isError = true
      formerr.dueDate =
        dictionary[localStorage.getItem('language')].due_date_header +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!create_Customer_Invoice_detail?.notes) {
      isError = true
      formerr.notes =
        dictionary[localStorage.getItem('language')].notes_header +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    if (!create_Customer_Invoice_detail?.invoice_line_items.length) {
      isError = true
      formerr.lineItems =
        dictionary[localStorage.getItem('language')].item_header +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    // If we have invoice line items, verify individual line items
    if (create_Customer_Invoice_detail?.invoice_line_items?.length) {
      const errorsObj = create_Customer_Invoice_detail?.invoice_line_items?.map(
        (el) => {
          const item = {}
          item.name = ''
          item.desc = ''
          item.qty = ''
          item.price = ''
          item.discount = ''
          item.price_per = ''
          return item
        }
      )
      create_Customer_Invoice_detail?.invoice_line_items?.forEach(
        (item, index) => {
          if (!item.name) {
            isError = true
            errorsObj[index]['name'] =
              dictionary[localStorage.getItem('language')].item_header +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip
          }
          if (!item.desc) {
            isError = true
            errorsObj[index]['desc'] =
              dictionary[localStorage.getItem('language')].description_header +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip
          }
          if (!item.qty) {
            isError = true
            errorsObj[index]['qty'] =
              dictionary[localStorage.getItem('language')].quantity_header +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip
          }
          if (!item.price || item.price == 0) {
            isError = true
            errorsObj[index]['price'] =
              dictionary[localStorage.getItem('language')].msrp_header +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip
          }
          if (!item.price_per || item.price_per == 0) {
            isError = true
            errorsObj[index]['price_per'] =
              dictionary[localStorage.getItem('language')].price_per_header +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip
          }
        }
      )

      formerr.lineItemsErrors = errorsObj
      setFormValidation(formerr)
    }

    return isError
  }

  const isErrorInLineItems = useMemo(() => {
    let isError = false
    formValidation?.lineItemsErrors?.forEach((item) => {
      if (item.name || item.desc || item.qty || item.price || item.price_per) {
        isError = true
      }
    })
    return isError
  })

  const handleClickOpen = (index) => {
    const newLineItem = [...create_Customer_Invoice_detail?.invoice_line_items]
    if (index > -1) {
      // only splice array when item is found
      newLineItem.splice(index, 1) // 2nd parameter means remove one item only
    }
    dispatch(updateCreateCustomerInvoiceLineItem(newLineItem))
  }

  const handleChange = (prop) => (event) => {
    // const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    dispatch(
      invoiceDetailForCustomerInvoiceSuccess({
        ...create_Customer_Invoice_detail,
        [prop]: event.target.value,
      })
    )
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleChangeLineItemValue = (index, prop) => (event) => {
    const newLineItem = [...create_Customer_Invoice_detail?.invoice_line_items]
    newLineItem[index][prop] = event.target.value
    if (prop == 'price_per') {
      newLineItem[index]['discount'] = Big(newLineItem[index]['price'] || 0)
        .minus(Big(newLineItem[index]['price_per'] || 0))
        .div(Big(newLineItem[index]['price'] || 0))
        .times(Big(100))
        .toFixed(2)
    }
    const result = recalculateAllFields(newLineItem, index, prop)
    dispatch(updateCreateCustomerInvoiceLineItem(result.invoice_line_items))
    dispatch(
      invoiceDetailForCustomerInvoiceSuccess({
        ...create_Customer_Invoice_detail,
        total: result.total,
      })
    )

    // reset error if any
    const formerr = { ...formValidation }
    if (formerr?.lineItemsErrors?.[index]?.[prop]) {
      formerr.lineItemsErrors[index][prop] = null
    }
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e, returnRequired) => {
    e.preventDefault()
    if (handleValidation()) return false

    const Data = {
      companyId: create_Customer_Invoice_detail?.customer_company_detail?.id,
      customerId: create_Customer_Invoice_detail?.customer_company_detail?.id,
      invoiceNum: create_Customer_Invoice_detail?.invoice_num,
      dueDate: create_Customer_Invoice_detail?.due_date,
      notes: create_Customer_Invoice_detail?.notes,
      lineItems: create_Customer_Invoice_detail?.invoice_line_items,
    }

    dispatch(createCustomerInvoice(Data))
  }

  // invoice table container, for scrolling to bottom when the new item is added
  const invoiceTableContainer = useRef()

  // variable used to count number of new item click, and on change of it's value it can trigger scroll to bottom
  const [newLineItemCounter, setNewLineItemCounter] = useState(0)

  const addLineItem = (event) => {
    const newLineItem = [...create_Customer_Invoice_detail?.invoice_line_items]
    newLineItem.push({
      name: '',
      desc: '',
      qty: 0,
      price: 0.0,
      discount: 0,
      price_per: 0,
      total: 0.0,
    })
    dispatch(updateCreateCustomerInvoiceLineItem(newLineItem))
    // increment the counter to trigger scroll to bottom on next render, so that the height can be properly calculated
    setNewLineItemCounter(newLineItemCounter + 1)
  }

  useEffect(() => {
    if (newLineItemCounter !== 0) {
      // avoid going to bottom on the page load
      // scroll to bottom, when new line item is added
      invoiceTableContainer.current.scrollTo({
        top: invoiceTableContainer.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [newLineItemCounter])

  const handleChangeSchedule = (event, type) => {
    if (event) {
      setFormValidation({ ...formValidation, [type]: null })

      if (type === 'dueDate') {
        dispatch(
          invoiceDetailForCustomerInvoiceSuccess({
            ...create_Customer_Invoice_detail,
            due_date: event,
          })
        )
      }
    }
  }

  // recalculate everything based on the change of the line items
  const recalculateAllFields = (
    invoice_line_items,
    ignoreFieldIndex,
    ignoreFieldName
  ) => {
    const updatedValues = invoice_line_items?.map((el, index) => {
      const price_per_count = new Big(1)
        .minus(new Big(el.discount || 0).div(100))
        .times(new Big(el.price || 0))
        .toFixed(2)
      if (index == ignoreFieldIndex && ignoreFieldName == 'price_per') {
      } else {
        el.price_per = price_per_count
      }
      el.total = new Big(price_per_count || 0).times(el.qty || 0).toFixed(2)
      return el
    })
    const sumTotal = updatedValues.reduce((accumulator, object) => {
      return new Big(accumulator).add(new Big(object.total)).toFixed(2)
    }, 0)

    return {
      total: sumTotal,
      invoice_line_items: updatedValues,
    }
  }

  useEffect(() => {
    const id = localStorage.getItem('tempInvoiceId')
    if (!id) {
      history.push({
        pathname: '/billingPortal',
      })
    } else {
      dispatch(getInvoiceDetailsToCreateCustomerInvoice(id))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    if (success === 'Invoice created successfully') {
      history.push({
        pathname: '/billingPortal',
      })
    }
  }, [success])

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row">
                  <div
                    className="col-md-8"
                    style={{ justifyContent: 'space-evenly', display: 'flex' }}
                  >
                    <div>
                      <TextField
                        id="invoiceNo"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .invoice_number_header
                        }
                        variant="outlined"
                        name="InvoiceNum"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={create_Customer_Invoice_detail?.invoice_num}
                        onChange={handleChange('invoice_num')}
                        error={!!formValidation.invoiceNum}
                        helperText={formValidation.invoiceNum}
                      />
                      <Typography variant="subtitle1">
                        {/* <strong> Invoice #: </strong>{create_Customer_Invoice_detail?.invoice_num}<br/> */}
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .amount_header
                          }
                          : ${' '}
                        </strong>
                        {create_Customer_Invoice_detail?.amount}
                      </Typography>
                    </div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disablePast
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="Due Date"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .due_date_header
                          }
                          variant="outlined"
                          size="small"
                          name="dueDate"
                          value={
                            create_Customer_Invoice_detail?.due_date
                              ? moment
                                  .utc(create_Customer_Invoice_detail?.due_date)
                                  .format('MM/DD/YYYY')
                              : null
                          }
                          onChange={(value) =>
                            handleChangeSchedule(value, 'dueDate')
                          }
                          error={!!formValidation.dueDate}
                          helperText={formValidation.dueDate}
                        />
                        {/* <strong> Due Date: </strong>{moment(create_Customer_Invoice_detail?.due_date).format('MM/DD/YYYY')}<br/> */}
                        {/* <strong> Paid: </strong><img alt='Sentinel' src={create_Customer_Invoice_detail?.is_paid === "1" ? '../assets/img/checked.png' : '../assets/img/unchecked.png'} style={{ width: 20 }} /> */}
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            dictionary[localStorage.getItem('language')]
                              .customer_header
                          }
                          :{' '}
                        </strong>
                        {
                          create_Customer_Invoice_detail
                            ?.customer_company_detail?.company_name
                        }
                        <br />
                        <TextField
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .notes_header
                          }
                          variant="outlined"
                          name="Notes"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={create_Customer_Invoice_detail?.notes}
                          onChange={handleChange('notes')}
                          error={!!formValidation.notes}
                          helperText={formValidation.notes}
                        />
                        {/* <strong> Notes: </strong>{create_Customer_Invoice_detail?.notes} */}
                      </Typography>
                    </div>
                  </div>

                  <div className="col-md-4" style={{ justifyContent: 'end' }}>
                    <Link
                      class="btn btn-primary float-right mr-2"
                      to="/billingPortal"
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .back_to_invoice_button
                      }
                    </Link>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ textTransform: 'none' }}
                      onClick={handleSubmitClick}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .send_to_customer_button
                      }
                      &nbsp;
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ display: props.loading ? 'block' : 'none' }}
                      />
                    </Button>
                  </div>
                </div>
                <div
                  className="invoiceTableContainer"
                  ref={invoiceTableContainer}
                >
                  <table className="invoiceTable">
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .item_header
                          }
                        </th>
                        <th style={{ width: '36%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .description_header
                          }
                        </th>
                        <th style={{ width: '8%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .quantity_header
                          }
                        </th>
                        <th style={{ width: '12%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .msrp_header
                          }
                        </th>
                        <th style={{ width: '10%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .discount_header
                          }
                        </th>
                        <th style={{ width: '12%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .price_per_header
                          }
                        </th>
                        <th style={{ width: '10%' }}>
                          {
                            dictionary[localStorage.getItem('language')]
                              .total_header
                          }
                        </th>
                        <th style={{ width: '2%' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {create_Customer_Invoice_detail?.invoice_line_items?.map(
                        (val, index) => (
                          <tr>
                            <td style={{ paddingTop: '10px' }}>
                              <TextField
                                fullWidth
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .item_header
                                }
                                variant="outlined"
                                name="Item Name"
                                size="small"
                                value={val.name}
                                onChange={handleChangeLineItemValue(
                                  index,
                                  'name'
                                )}
                                error={
                                  !!formValidation?.lineItemsErrors?.[index]
                                    ?.name
                                }
                              />
                            </td>
                            <td style={{ paddingTop: '10px' }}>
                              <TextField
                                fullWidth
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .description_header
                                }
                                variant="outlined"
                                name="Description"
                                size="small"
                                value={val.desc}
                                onChange={handleChangeLineItemValue(
                                  index,
                                  'desc'
                                )}
                                error={
                                  !!formValidation?.lineItemsErrors?.[index]
                                    ?.desc
                                }
                              />
                            </td>
                            <td style={!val.id ? { paddingTop: '10px' } : {}}>
                              {!val.id ? ( // If id is present, that means it is prefilled, do not allow editing
                                <TextField
                                  fullWidth
                                  label={
                                    dictionary[localStorage.getItem('language')]
                                      .quantity_header
                                  }
                                  variant="outlined"
                                  name="Qty"
                                  size="small"
                                  value={val.qty}
                                  type="number"
                                  onChange={handleChangeLineItemValue(
                                    index,
                                    'qty'
                                  )}
                                  error={
                                    !!formValidation?.lineItemsErrors?.[index]
                                      ?.qty
                                  }
                                />
                              ) : (
                                val.qty
                              )}
                            </td>
                            <td style={!val.id ? { paddingTop: '10px' } : {}}>
                              {!val.id ? ( // If id is present, that means it is prefilled, do not allow editing
                                <TextField
                                  fullWidth
                                  label={
                                    dictionary[localStorage.getItem('language')]
                                      .msrp_header
                                  }
                                  variant="outlined"
                                  name="MSRP"
                                  size="small"
                                  value={val.price}
                                  type="number"
                                  onChange={handleChangeLineItemValue(
                                    index,
                                    'price'
                                  )}
                                  error={
                                    !!formValidation?.lineItemsErrors?.[index]
                                      ?.price
                                  }
                                />
                              ) : (
                                '$' + val.price
                              )}
                            </td>
                            <td style={{ paddingTop: '10px' }}>
                              <TextField
                                fullWidth
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .discount_header
                                }
                                variant="outlined"
                                name="Discount"
                                size="small"
                                value={val.discount}
                                type="number"
                                onChange={handleChangeLineItemValue(
                                  index,
                                  'discount'
                                )}
                              />
                            </td>
                            <td style={{ paddingTop: '10px' }}>
                              <TextField
                                fullWidth
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .price_per_header
                                }
                                variant="outlined"
                                name="Price Per"
                                size="small"
                                value={val.price_per}
                                type="number"
                                onChange={handleChangeLineItemValue(
                                  index,
                                  'price_per'
                                )}
                                error={
                                  !!formValidation?.lineItemsErrors?.[index]
                                    ?.price_per
                                }
                              />
                            </td>
                            <td>${val.total}</td>
                            <td>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                color="secondary"
                                onClick={() => handleClickOpen(index)}
                              >
                                <Delete />
                              </IconButton>
                            </td>
                          </tr>
                        )
                      )}
                      <tr style={{ justifyContent: 'end' }}>
                        <td>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={addLineItem}
                          >
                            {
                              dictionary[localStorage.getItem('language')]
                                .new_line_button
                            }
                            &nbsp;
                            <i
                              className="fa fa-spinner fa-spin"
                              style={{
                                display: props.loading ? 'block' : 'none',
                              }}
                            />
                          </Button>
                        </td>
                        <td>
                          {isErrorInLineItems ? (
                            <div style={{ color: '#f44336' }}>
                              <strong>
                                Plese enter all the required fields to
                                continue...
                              </strong>
                            </div>
                          ) : (
                            ''
                          )}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: 'right' }}>
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .total_header
                            }
                            :
                          </strong>
                        </td>
                        <td>${create_Customer_Invoice_detail?.total}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

CreateCustomerInvoice.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(CreateCustomerInvoice))
