import React, { useEffect, useState } from 'react'
import { Redirect, NavLink, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
  TextField,
  Typography,
  Button,
  Box,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { auth } from './store/actions/auth'
import { withSnackbar } from 'notistack'
// import { Loader } from "react-loader-spinner"
import Loader from './PageLoader'
import dictionary from './store/dictionary.json'
import { Autocomplete } from '@material-ui/lab'

const MenuProps = {
  PaperProps: {
    style: {
      width: 100,
    },
  },
}

const Login = (props) => {
  const { enqueueSnackbar, error, errorCode, loading } = props

  const languages = [
    {
      label: 'English',
      value: 'En',
    },
    {
      label: 'Español',
      value: 'Es',
    },
  ]

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }))

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    email: '',
    companyCode: '',
  })

  const [isSubmit, setIsSubmit] = React.useState(false)

  let [current_language, set_language] = useState(
    localStorage.getItem('language') == undefined
      ? languages[0].value
      : localStorage.getItem('language')
  )

  let [company_details, set_details] = useState(
    localStorage.getItem('companyDetails') == undefined ||
      localStorage.getItem('companyDetails') == null ||
      localStorage.getItem('companyDetails') == 'null'
      ? JSON.parse('{}')
      : JSON.parse(localStorage.getItem('companyDetails'))
  )

  const [formValidation, setFormValidation] = React.useState({
    companyCode: '',
    email: '',
    password: '',
    language: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.companyCode) {
      isError = true
      formerr.companyCode = dictionary[current_language].company_id_tooltip
      setFormValidation(formerr)
    }

    if (values.companyCode.length !== 4) {
      isError = true
      formerr.companyCode = dictionary[current_language].company_id_tooltip
      setFormValidation(formerr)
    }

    if (!values.email) {
      isError = true
      formerr.email =
        dictionary[current_language].email_or_phone_field_name +
        ' ' +
        dictionary[current_language].required_field_tooltip
      setFormValidation(formerr)
    }
    if (!values.password) {
      isError = true
      formerr.password =
        dictionary[current_language].password_field_name +
        ' ' +
        dictionary[current_language].required_field_tooltip_feminine
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    if (prop == 'language') {
      set_language(event.target.value)
      localStorage.setItem('language', event.target.value)
    } else {
      setValues({ ...values, [prop]: event.target.value })

      const formerr = { ...formValidation }
      formerr[event.target.name] = null
      setFormValidation(formerr)
    }
  }

  values.companyCode = values.companyCode

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false
    localStorage.setItem('language', current_language)
    props.onAuth(
      values.email,
      values.password,
      values.companyCode,
      current_language
    )
  }

  const history = useHistory()
  const userId = localStorage.getItem('token')
  let authRedirect = null
  if (userId) {
    localStorage.setItem('needsRefresh', 'True')
    authRedirect = <Redirect to="/" />
  }

  useEffect(() => {
    if (error == 'Access Denied. Error Code 90') {
      error &&
        enqueueSnackbar('You have been logged out', {
          variant: 'default',
        })
    } else if (errorCode == 'password-expired') {
      error && enqueueSnackbar(error, { variant: 'error' })
      //redirect to reset password page if there is the specific error
      history.push(
        '/ResetExpiredPassword?username=' +
          values.email +
          '&companyCode=' +
          values.companyCode
      )
    } else if (errorCode == '2fa-required') {
      error && enqueueSnackbar(error, { variant: 'default' })
      history.push(
        '/twoFactorAuthentication?username=' +
          values.email +
          '&companyCode=' +
          values.companyCode
      )
    } else {
      error && enqueueSnackbar(error, { variant: 'error' })
    }
  }, [error, errorCode])

  const [pageLoading, setPageLoading] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div className="main-content">
      {authRedirect}
      <div>
        <ToastContainer />
      </div>
      {pageLoading ? <Loader /> : null}
      <div className="header" />
      <div className="container mt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div
              className="card bg-secondary mb-0"
              style={{
                border: '1px solid #1E4CA1',
                borderTop: '20px solid #1E4CA1',
                boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.46)',
              }}
            >
              <div className="card-header bg-transparent text-center">
                <img
                  alt="Logo"
                  src="../assets/img/pac_blu_logo.png"
                  style={{ width: '60%', height: 'auto' }}
                />
                {/* <Typography variant="h4" gutterBottom color="primary">
                  PAC-BLU
                </Typography> */}
              </div>
              <div className="card-body px-lg-5">
                <form noValidate autoComplete="off">
                  <Box mb={4}>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      options={
                        Object.keys(company_details)
                          ? Object.keys(company_details)
                          : ''
                      }
                      value={values.companyCode}
                      inputValue={values.companyCode}
                      onChange={(e, value) => {
                        if (company_details[value] !== undefined) {
                          // If the selected value has a mapping, then substitute
                          setValues({
                            ...values,
                            companyCode: company_details[value],
                          })

                          const formerr = { ...formValidation }
                          formerr['companyCode'] = null
                          setFormValidation(formerr)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="compcode"
                          {...params}
                          fullWidth
                          label={
                            dictionary[current_language].company_id_field_name +
                            ' ' +
                            dictionary[current_language].company_id_requirements
                          }
                          name="companyCode"
                          autoComplete="off"
                          value={values.companyCode}
                          onChange={handleChange('companyCode')}
                          error={!!formValidation.companyCode}
                          helperText={formValidation.companyCode}
                        />
                      )}
                    />
                  </Box>
                  <Box mb={4}>
                    <TextField
                      id="email"
                      fullWidth
                      label={
                        dictionary[current_language].email_or_phone_field_name
                      }
                      name="email"
                      value={values.email}
                      onChange={handleChange('email')}
                      error={!!formValidation.email}
                      helperText={formValidation.email}
                    />
                  </Box>

                  <FormControl
                    fullWidth
                    error={formValidation.password ? true : false}
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      {dictionary[current_language].password_field_name}
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      name="password"
                      onChange={handleChange('password')}
                      error={!!formValidation.password}
                      helperText={formValidation.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formValidation.password ? (
                      <FormHelperText id="component-error-text">
                        {formValidation.password}
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>

                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <p style={{ fontSize: 'smaller' }}>
                        {dictionary[current_language].terms_and_cond_1}{' '}
                        <a
                          href="https://www.paclock.com/privacy-policy/"
                          target="_blank"
                          style={{ textDecoration: 'underline #1e4ca1' }}
                        >
                          {dictionary[current_language].terms_and_cond_2}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        onClick={handleSubmitClick}
                        style={{ display: props.loading ? 'none' : 'block' }}
                      >
                        {dictionary[current_language].login_button}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        style={{ display: props.loading ? 'block' : 'none' }}
                      >
                        {dictionary[current_language].login_button} &nbsp;
                        <i className="fa fa-spinner fa-spin right" />
                      </Button>
                    </div>
                    <div className="col-6 text-center mt-3">
                      <NavLink to="/forgotPassword">
                        <small>
                          {dictionary[current_language].forgot_password}
                        </small>
                      </NavLink>
                    </div>
                    <div className="col-6 text-center mt-2">
                      <FormControl sx={{ m: 1, width: 100 }} size="small">
                        <Select
                          id="language_select"
                          style={{ width: 100 }}
                          value={current_language}
                          onChange={handleChange('language')}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                        >
                          {languages.map((item) => (
                            <MenuItem key={item.label} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    errorCode: state.auth.errorCode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, companyCode, language) =>
      dispatch(auth(email, password, companyCode, language)),
  }
}

Login.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  errorCode: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Login))
