import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  getDeviceDetails,
  getLastReportedLocation,
} from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import Tooltip from '@material-ui/core/Tooltip'
import PlaceIcon from '@material-ui/icons/Place'
import { Alert, Autocomplete } from '@material-ui/lab'
import dictionary from '../store/dictionary.json'

const useStyles = makeStyles((theme) => ({
  active: {
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
    '&:last-child': {
      borderRight: 'solid 1px #cccccc',
    },
  },
  labelBox: {
    width: 200,
    textAlign: 'right',
  },
  labelScheduleBox: {
    width: 100,
    textAlign: 'right',
  },
}))

const DeviceDetails = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { device_Details } = useSelector((state) => state.user)
  const { last_reported_location } = useSelector((state) => state.user)
  const history = useHistory()

  useEffect(() => {
    const id = localStorage.getItem('tempDeviceId')
    if (!id) {
      history.push({
        pathname: '/devices',
      })
    }
    dispatch(getDeviceDetails(id))
    dispatch(getLastReportedLocation(id))
  }, [dispatch])

  const handleClickToRedirect = (type, item) => {
    if (type == 'user') {
      localStorage.setItem('tempId', item.user_id)
      history.push({
        pathname: '/modifyUser',
        isLoaderRequired: true,
      })
    } else if (type == 'deviceGroup') {
      localStorage.setItem('tempDeviceGroupId', item.id)
      history.push({
        pathname: '/modifyDeviceGroup',
        isLoaderRequired: true,
      })
    }
  }

  const Marker = ({ text }) => {
    return (
      <Tooltip title={text} placement="top">
        <PlaceIcon className="text-red" />
      </Tooltip>
    )
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              {device_Details?.low_battery === '1' && (
                <div style={{ overflowX: 'hidden' }}>
                  <div className="row align-items-center justify-content-center">
                    <Alert variant="filled" severity="error" icon={false}>
                      <div
                        className="col"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'baseline',
                        }}
                      >
                        <p
                          className="font-weight-600"
                          style={{ marginBottom: '0px' }}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .coin_cell_battery_level_alert
                          }
                        </p>
                      </div>
                    </Alert>
                  </div>
                </div>
              )}

              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-11 text-center">
                    <Typography variant="subtitle1">
                      <strong>
                        {device_Details.full_name}{' '}
                        {
                          dictionary[localStorage.getItem('language')]
                            .device_details_title
                        }
                      </strong>
                    </Typography>
                  </div>
                  <div className="col-lg-1">
                    <Link class="btn btn-primary float-right" to="/devices">
                      {
                        dictionary[localStorage.getItem('language')]
                          .devices_title
                      }
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col">
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .full_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.full_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .display_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.display_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .date_installed_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.createdAt}
                          </Typography>
                        </Box>
                      </div>
                      <div className="col">
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .serial_number_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.serial_number}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .hardware_id_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.hardware_id}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .device_type_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.device_type}
                          </Typography>
                        </Box>
                      </div>
                    </div>

                    <Divider
                      variant="middle1"
                      className="mt-4 mb-4"
                      style={{ background: '#1E4CA1' }}
                    />

                    <div className="row">
                      <div className="col-sm-4">
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .lock_type_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.lock_type}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .relock_trigger_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.relock_trigger}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {device_Details.relock_trigger == 'Timer'
                                ? dictionary[localStorage.getItem('language')]
                                    .relock_time_field_name
                                : dictionary[localStorage.getItem('language')]
                                    .trigger_on_field_name}
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.relock_trigger == 'Timer'
                              ? device_Details.relock_timer
                              : device_Details.trigger_mode}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .manual_unlock_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.manual_lock == '1' ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                        {device_Details.sleep_mode == '1' &&
                          device_Details.sleep_time && (
                            <Box
                              display="flex"
                              alignItems="center"
                              className="mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .sleep_time_field_name
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.sleep_time}
                              </Typography>
                            </Box>
                          )}

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .power_level_field_name
                              }{' '}
                              (1-5):
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.bluetooth_power_level}
                          </Typography>
                        </Box>
                        {device_Details.relock_trigger !== 'Timer' ? (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelBox}
                              >
                                <strong>DNORT:</strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.dnort_time}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          ''
                        )}

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .coin_cell_battery_level_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.low_battery == '1' ? 'Low' : 'Good'}
                          </Typography>
                        </Box>

                        <Typography
                          variant="subtitle2"
                          className={classes.labelBox}
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .last_reported_location_field_name
                            }
                            :
                          </strong>
                        </Typography>
                        {last_reported_location &&
                        last_reported_location.gps_location !== null ? (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="mt-1"
                            >
                              <div
                                style={{
                                  height: '200px',
                                  width: '100%',
                                  border: '1px dotted',
                                }}
                              >
                                <GoogleMapReact
                                  bootstrapURLKeys={{
                                    key: 'AIzaSyAFcDErMtUN_AhN7_pClSsy_rk6mbqHB5Y',
                                  }}
                                  defaultCenter={{
                                    lat: parseFloat(
                                      last_reported_location.gps_location
                                        .latitude
                                    ),
                                    lng: parseFloat(
                                      last_reported_location.gps_location
                                        .longitude
                                    ),
                                  }}
                                  defaultZoom={15}
                                >
                                  <Marker
                                    text={`${last_reported_location.gps_location.latitude}, ${last_reported_location.gps_location.longitude}`}
                                    lat={
                                      last_reported_location.gps_location
                                        .latitude
                                    }
                                    lng={
                                      last_reported_location.gps_location
                                        .longitude
                                    }
                                  />
                                </GoogleMapReact>
                              </div>
                            </Box>
                          </>
                        ) : (
                          dictionary[localStorage.getItem('language')]
                            .not_available
                        )}
                      </div>

                      <div className="col-sm-4">
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .scheduled_open_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.schedule_open == '1' ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                        {device_Details.schedule_open == '1' ? (
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              className={classes.labelBox}
                            >
                              <strong>FPI:</strong>
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              component="p"
                              className="ml-2"
                            >
                              {device_Details.fpi == '1' ? 'Yes' : 'No'}
                            </Typography>
                          </Box>
                        ) : null}
                        {device_Details.schedule_open == '1' ? (
                          <div>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .monday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Monday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .tuesday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Tuesday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .wednesday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Wednesday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .thursday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Thursday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .friday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Friday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .saturday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Saturday}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              className="ml-5 mt-1"
                            >
                              <Typography
                                variant="subtitle2"
                                className={classes.labelScheduleBox}
                              >
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .sunday_abbreviation
                                  }
                                  :
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="ml-2"
                              >
                                {device_Details.schedule.Sunday}
                              </Typography>
                            </Box>
                          </div>
                        ) : null}

                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .sensor_1_open_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.sensor1_open_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .sensor_1_closed_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.sensor1_close_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .sensor_2_open_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.sensor2_open_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mt-1"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.labelBox}
                          >
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .sensor_2_closed_name_field_name
                              }
                              :
                            </strong>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className="ml-2"
                          >
                            {device_Details.sensor2_close_name}
                          </Typography>
                        </Box>
                      </div>

                      {/* {device_Details.schedule_open} */}
                      {device_Details.schedule_open == 1 ? (
                        <>
                          <div className="col-sm-4">
                            <Typography variant="subtitle2" className="mb-2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .exceptions_field_name
                                }
                                :
                              </strong>
                            </Typography>
                            <Card
                              variant="outlined"
                              style={{ border: '2px solid #1e4ca1' }}
                            >
                              <CardContent style={{ padding: 0 }}>
                                <List
                                  style={{
                                    minHeight: 200,
                                    maxHeight: 319,
                                    overflow: 'auto',
                                  }}
                                >
                                  {device_Details.exceptions.length > 0 ? (
                                    device_Details.exceptions.map((item, i) => (
                                      <div>
                                        <ListItem>
                                          <Typography
                                            variant="subtitle2"
                                            component="p"
                                            className="ml-2"
                                          >
                                            {item.exceptionsDays}
                                          </Typography>
                                        </ListItem>
                                        <Divider
                                          variant="middle"
                                          component="li"
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        textAlign: 'center',
                                        marginTop: 40 + '%',
                                      }}
                                      gutterBottom
                                      className="mb-3"
                                    >
                                      {
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].no_exceptions
                                      }
                                    </Typography>
                                  )}
                                </List>
                              </CardContent>
                            </Card>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Typography variant="subtitle2" className="mb-2">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .users_with_ekeys_header
                        }
                        :
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #1e4ca1' }}
                    >
                      <CardContent style={{ padding: 0 }}>
                        <List
                          style={{
                            minHeight: 200,
                            maxHeight: 250,
                            overflow: 'auto',
                          }}
                        >
                          {device_Details.users.length > 0 ? (
                            device_Details.users.map((item, i) => (
                              <div>
                                <ListItem
                                  className={classes.active}
                                  onClick={() =>
                                    handleClickToRedirect('user', item)
                                  }
                                >
                                  <ListItemText primary={item.display_name} />
                                </ListItem>
                                <Divider variant="middle" component="li" />
                              </div>
                            ))
                          ) : (
                            <Typography
                              variant="subtitle2"
                              style={{
                                textAlign: 'center',
                                marginTop: 25 + '%',
                              }}
                              gutterBottom
                              className="mb-3"
                            >
                              {
                                dictionary[localStorage.getItem('language')]
                                  .no_users
                              }
                            </Typography>
                          )}
                        </List>
                      </CardContent>
                    </Card>

                    <Typography variant="subtitle2" className="mt-5 mb-2">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .device_groups_title
                        }
                        :
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #1e4ca1' }}
                    >
                      <CardContent style={{ padding: 0 }}>
                        <List
                          style={{
                            minHeight: 200,
                            maxHeight: 250,
                            overflow: 'auto',
                          }}
                        >
                          {device_Details.deviceGroup.length > 0 ? (
                            device_Details.deviceGroup.map((item, i) => (
                              <div>
                                <ListItem
                                  className={classes.active}
                                  onClick={() =>
                                    handleClickToRedirect('deviceGroup', item)
                                  }
                                >
                                  <ListItemText primary={item.full_name} />
                                </ListItem>
                                <Divider variant="middle" component="li" />
                              </div>
                            ))
                          ) : (
                            <Typography
                              variant="subtitle2"
                              style={{
                                textAlign: 'center',
                                marginTop: 25 + '%',
                              }}
                              gutterBottom
                              className="mb-3"
                            >
                              {
                                dictionary[localStorage.getItem('language')]
                                  .no_device_groups
                              }
                            </Typography>
                          )}
                        </List>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

DeviceDetails.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(DeviceDetails))
