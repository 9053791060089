import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  companyList,
  companyListSuccess,
  deleteCompany,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'

const Company = (props) => {
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { company_List } = useSelector((state) => state.company)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      filterable: false,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <Tooltip title="Click to Company Modify" arrow>
          <img
            alt="Company"
            src="../assets/img/company.svg"
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'company_name',
      headerName: 'Company Name',
      width: 200,
      renderHeader: () => <strong>{'Company Name'}</strong>,
    },
    {
      field: 'company_id',
      headerName: 'Code',
      width: 110,
      renderHeader: () => <strong>{'Code'}</strong>,
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      width: 190,
      renderHeader: () => <strong>{'Full Name'}</strong>,
    },

    {
      field: 'poc_phone_number',
      headerName: 'Telephone',
      width: 170,
      renderHeader: () => <strong>{'Telephone'}</strong>,
      renderCell: (params) => {
        return params.value
          ? (params.row.poc_country_code
              ? params.row.poc_country_code + ' '
              : '') +
              params.value.substring(0, 3) +
              '-' +
              params.value.substring(3, 6) +
              '-' +
              params.value.substring(6, params.value.length)
          : ''
      },
    },
    {
      field: 'poc_e_mail',
      headerName: 'Email',
      width: 190,
      renderHeader: () => <strong>{'Email'}</strong>,
    },
    {
      field: 'totalDevices',
      headerName: 'Devices',
      width: 120,
      renderHeader: () => <strong>{'Devices'}</strong>,
      renderCell: (params) => <Link>{params.value}</Link>,
    },
    {
      field: 'totalUsers',
      headerName: 'Users',
      width: 100,
      renderHeader: () => <strong>{'Users'}</strong>,
    },
    {
      field: 'totalInvoices',
      headerName: 'Invoices',
      width: 100,
      renderHeader: () => <strong>{'Invoices'}</strong>,
      renderCell: (params) => <Link>{params.value}</Link>,
    },
    {
      field: 'is_deactive',
      headerName: 'Active',
      width: 100,
      renderHeader: () => <strong>{'Active'}</strong>,
      renderCell: (params) => (
        <img
          alt="Active"
          src={
            params.value == '0'
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    // {
    //   ...(permissionDetails.permission_all || permissionDetails.permission_delete ? {
    //     field: 'Delete',
    //     headerName: 'Delete',
    //     width: 95,
    //     renderHeader: () => (
    //       <strong>{'Delete'}</strong>
    //     ),
    //     renderCell: (params) => (
    //       <IconButton >
    //         <Delete color="secondary" />
    //       </IconButton>
    //     )
    //   } : ""),
    // }
  ]

  useEffect(() => {
    dispatch(companyList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id

    if (action == '#') {
      localStorage.setItem('tempCompanyId', id)
      history.push({
        pathname: '/modifyCompany',
      })
    } else if (action == 'totalDevices') {
      const compid = GridCellParams.row.id
      localStorage.setItem('tempCompanyId', compid)
      history.push({
        pathname: '/companyRelatedDevices',
      })
    } else if (action == 'totalInvoices') {
      const compid = GridCellParams.row.id
      localStorage.setItem('tempCompanyId', compid)
      history.push({
        pathname: '/companyRelatedInvoices',
      })
    } else if (action == 'Delete') {
      var row = GridCellParams.row
      const id = GridCellParams.row.id
      dispatch(deleteCompany(id, row))
      dispatch(companyListSuccess(company_List.filter((e) => e !== row)))
    }
  }

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <Link
                      class="btn btn-primary float-right"
                      to="/companyMailerSetting"
                    >
                      Default Mailer Config
                    </Link>
                    <Link
                      class="btn btn-primary float-right mr-2"
                      to="/addCompany"
                    >
                      Add Company
                    </Link>
                    <Link
                      class="btn btn-primary float-right mr-2"
                      to="/viewCompanyRequests"
                    >
                      View Company Requests
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                <DataGrid
                  onCellClick={currentlySelected}
                  rows={company_List}
                  columns={columns}
                  pageSize={25}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

Company.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(Company))
