import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  getCompanyStockList,
  companyRelatedDeviceList,
  deviceTransfer,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import FormHelperText from '@material-ui/core/FormHelperText'
import Loader from '../PageLoader'
import Footer from '../footer'
import {
  IconButton,
  Typography,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  Box,
  List,
  ListItem,
  OutlinedInput,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Submenu from '../header_sub_menu'
import ForwardIcon from '@material-ui/icons/Forward'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import dictionary from '../store/dictionary.json'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  disabledColor: {
    background: '#E8E8E8',
  },
  imgSize: {
    width: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
}))

const DeviceTransfer = (props) => {
  const classes = useStyles()

  const DEVICE_STATUS = {
    STOCK: 'stock',
    UNCONFIGURED: 'unconfigured',
    CONFIGURED: 'configured',
    RETIRED: 'retired',
  }

  const columns = [
    {
      field: '__selection__',
      headerName: ' ',
      width: 50,
      renderCell: (params) => {
        let checked = selectionModel.selectedDevices.includes(params.row.id)
        return (
          <img
            alt="Stock"
            onClick={(e) => {
              checked = !checked
              addOrRemoveDeviceFromSelection(params.row.id)
              // Below line is needed to rerender src value on click
              e.target.src = checked
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }}
            src={
              checked
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20, cursor: 'pointer' }}
          />
        )
      },
    },
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      width: 185,
      renderHeader: () => (
        <strong>
          {
            dictionary[localStorage.getItem('language')]
              .serial_number_field_name
          }
        </strong>
      ),
      renderCell: (params) => {
        return params.row.serial_front && params.row.serial_back
          ? params.row.serial_front + '-' + params.row.serial_back
          : ''
      },
      sortComparator: (v1, v2, param1, param2) =>
        param1.row.serial_front + '-' + param1.row.serial_back >
        param2.row.serial_front + '-' + param2.row.serial_back
          ? 1
          : -1,
    },
    {
      field: 'board_type',
      headerName: 'Board Type',
      width: 185,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].device_type_field_name}
        </strong>
      ),
    },
  ]

  const columns1 = [
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      width: 130,
      renderHeader: () => (
        <strong>
          {
            dictionary[localStorage.getItem('language')]
              .serial_number_field_name
          }
        </strong>
      ),
      renderCell: (params) => {
        return params.row.serial_front && params.row.serial_back
          ? params.row.serial_front + '-' + params.row.serial_back
          : ''
      },
      sortComparator: (v1, v2, param1, param2) =>
        param1.row.serial_front + '-' + param1.row.serial_back >
        param2.row.serial_front + '-' + param2.row.serial_back
          ? 1
          : -1,
    },
    {
      field: 'board_type',
      headerName: 'Board Type',
      width: 130,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].device_type_field_name}
        </strong>
      ),
    },
    // (!)
    {
      field: 'status',
      headerName: 'Stock',
      width: 125,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].stock_header}
        </strong>
      ),
      cellClassName: 'shippingDateInFuture',
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return (
            'Device will be activated on ' + shippingDate.format('MM/DD/YYYY')
          )
        } else {
          return (
            <img
              alt="Stock"
              src={
                params.row.status === DEVICE_STATUS.STOCK
                  ? '../assets/img/checked.png'
                  : '../assets/img/unchecked.png'
              }
              style={{ width: 20 }}
            />
          )
        }
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.row.status == DEVICE_STATUS.STOCK ? 0 : 1) +
          param1.row.serial_front +
          '-' +
          param1.row.serial_back >
        (param2.row.status == DEVICE_STATUS.STOCK ? 0 : 1) +
          param2.row.serial_front +
          '-' +
          param2.row.serial_back
          ? 1
          : -1,
    },
    {
      field: 'status1',
      headerName: 'Unconfigured',
      width: 125,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].unconfigured_header}
        </strong>
      ),
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Unconfigured"
            src={
              params.row.status === DEVICE_STATUS.UNCONFIGURED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.row.status == DEVICE_STATUS.UNCONFIGURED ? 0 : 1) +
          param1.row.serial_front +
          '-' +
          param1.row.serial_back >
        (param2.row.status == DEVICE_STATUS.UNCONFIGURED ? 0 : 1) +
          param2.row.serial_front +
          '-' +
          param2.row.serial_back
          ? 1
          : -1,
    },
    {
      field: 'status2',
      headerName: 'Configured',
      width: 125,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].configured_header}
        </strong>
      ),
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Configured"
            src={
              params.row.status === DEVICE_STATUS.CONFIGURED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.row.status == DEVICE_STATUS.CONFIGURED ? 0 : 1) +
          param1.row.serial_front +
          '-' +
          param1.row.serial_back >
        (param2.row.status == DEVICE_STATUS.CONFIGURED ? 0 : 1) +
          param2.row.serial_front +
          '-' +
          param2.row.serial_back
          ? 1
          : -1,
    },
    {
      field: 'status3',
      headerName: 'Retired',
      width: 125,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].retired_header}
        </strong>
      ),
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Retired"
            src={
              params.row.status === DEVICE_STATUS.RETIRED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.row.status == DEVICE_STATUS.RETIRED ? 0 : 1) +
          param1.row.serial_front +
          '-' +
          param1.row.serial_back >
        (param2.row.status == DEVICE_STATUS.RETIRED ? 0 : 1) +
          param2.row.serial_front +
          '-' +
          param2.row.serial_back
          ? 1
          : -1,
    },
  ]

  const subscriptionTerms = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ]
  const COMPANY_TYPE = {
    OEM: 'oem',
    VAR: 'var',
    EC: 'ec',
    ROOT: 'root',
  }
  const { enqueueSnackbar, error, success, loading } = props

  const dispatch = useDispatch()
  const { company_Stock_List } = useSelector((state) => state.company)
  const { company_related_device_List } = useSelector((state) => state.company)

  const [popups, setpopups] = React.useState(false)
  const history = useHistory()

  const handleChangeCheckboxValue = (key, value) => {
    setValues({ ...values, [key]: value })
  }

  const getDeviceTransferAndStockList = () => {
    dispatch(getCompanyStockList(true))

    const id = localStorage.getItem('tempCompanyId')
    if (!id) {
      history.push({
        pathname: '/company',
      })
    } else {
      dispatch(companyRelatedDeviceList(id))
    }
  }

  useEffect(() => {
    getDeviceTransferAndStockList()
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())

    if (success === 'Stock transferred successfully') {
      setSelectionModel({ selectedDevices: [] })
      getDeviceTransferAndStockList()
    }
  }, [success])

  const [values, setValues] = React.useState({
    status: true,
    shippingDate: '',
    subscriptionTerm: '',
  })

  const [selectionModel, setSelectionModel] = React.useState({
    selectedDevices: [],
  })

  const addOrRemoveDeviceFromSelection = (deviceId) => {
    let updatedSelectionModel = selectionModel.selectedDevices
    if (updatedSelectionModel.includes(deviceId)) {
      updatedSelectionModel = updatedSelectionModel.filter(
        (it) => it != deviceId
      )
    } else {
      updatedSelectionModel.push(deviceId)
    }
    setSelectionModel({ selectedDevices: updatedSelectionModel })
  }

  const [formValidation, setFormValidation] = React.useState({
    status: false,
    shippingDate: '',
    subscriptionTerm: '',
  })

  const resetForm = () => {
    setValues({
      ...values,
      // status: true,
      shippingDate: '',
      subscriptionTerm: '',
    })
    setFormValidation({
      ...formValidation,
      status: false,
      shippingDate: '',
      subscriptionTerm: '',
    })
  }

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }

    //if status is not selected shippingDate and subscriptionTerm is required
    if (values.status === false) {
      if (!values.shippingDate) {
        isError = true
        formerr.shippingDate = 'Required Shipping Date'
        setFormValidation(formerr)
      }

      if (!values.subscriptionTerm) {
        isError = true
        formerr.subscriptionTerm = 'Required Subscription Term'
        setFormValidation(formerr)
      }
    }

    return isError
  }

  //TODO: check this method
  const handleChange = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleChangeDate = (event, type) => {
    if (event) {
      setFormValidation({ ...formValidation, [type]: null })

      if (type === 'shippingDate') {
        setValues({ ...values, shippingDate: event })
      }
    }
  }

  //TODO: validation error
  const transferConfirm = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const toCompanyId = localStorage.getItem('tempCompanyId')
    const fromCompanyId = localStorage.getItem('tempCompId')
    const transferDeviceData = {
      fromCompany: fromCompanyId,
      toCompany: toCompanyId,
      transferDevices: selectionModel.selectedDevices,
      status:
        values.status === true
          ? DEVICE_STATUS.STOCK
          : DEVICE_STATUS.UNCONFIGURED,
      shippingDate: values.shippingDate
        ? moment(values.shippingDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null,
      subscriptionTerm: values.subscriptionTerm
        ? values.subscriptionTerm
        : null,
    }
    setpopups(false)
    // setPageLoading(true);

    dispatch(deviceTransfer(transferDeviceData))
  }
  // && company_related_device_List.company.company_type!= COMPANY_TYPE.EC
  const showSubscriptionTerms = useMemo(() => {
    if (values.status) {
      return false
    }
    return true
  }, [values])

  const showStocks = useMemo(() => {
    if (company_related_device_List.company.company_type == COMPANY_TYPE.EC) {
      const newValues = { ...values, status: false }
      setValues(newValues)
      return false
    }
    return true
  }, [company_related_device_List])

  const isTransferDeviceButtonEnabled = useMemo(() => {
    if (selectionModel.selectedDevices.length) {
      return true
    }
    return false
  }, [selectionModel])

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      company_Stock_List.companyStock.find((row) => row.id === id)
    )
  }

  const handleClose = () => {
    setpopups(false)
  }

  useEffect(() => {
    if (popups) {
      resetForm()
    }
  }, [popups])

  const [pageLoading, setPageLoading] = React.useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <Dialog open={popups} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dictionary[localStorage.getItem('language')].assign_device_header}
        </DialogTitle>
        <Box position="absolute" top={0} right={0} onClick={handleClose}>
          <IconButton>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>
            {dictionary[localStorage.getItem('language')].assign_device_text_1}{' '}
            {selectionModel.selectedDevices.length}{' '}
            {dictionary[localStorage.getItem('language')].assign_device_text_2}{' '}
            {company_related_device_List.company.company_name}.{' '}
            {dictionary[localStorage.getItem('language')].assign_device_text_3}
          </Typography>
        </DialogContent>
        <form autoComplete="off">
          {showStocks ? (
            <Typography
              variant="subtitle2"
              gutterBottom
              className="mb-3"
              style={{ textAlign: 'center' }}
            >
              {values.status ? (
                <img
                  alt="Sentinel"
                  src="../assets/img/checked.png"
                  className={classes.imgSize}
                  onClick={() => handleChangeCheckboxValue('status', false)}
                />
              ) : (
                <img
                  alt="Sentinel"
                  src="../assets/img/unchecked.png"
                  className={classes.imgSize}
                  onClick={() => handleChangeCheckboxValue('status', true)}
                />
              )}
              {dictionary[localStorage.getItem('language')].stock_header}?
            </Typography>
          ) : (
            <div></div>
          )}
          {showSubscriptionTerms ? (
            <div className="row m-4">
              <div className="col-md-6">
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                  error={!!formValidation.subscriptionTerm}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    {
                      dictionary[localStorage.getItem('language')]
                        .assign_device_term
                    }
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    style={{ width: 250 }}
                    value={values.subscriptionTerm}
                    onChange={handleChange('subscriptionTerm')}
                    label="Subscription Term"
                    name="subscriptionTerm"
                  >
                    {subscriptionTerms.map((item) => (
                      <MenuItem
                        key={item.label}
                        value={item.value}
                        // style={getStyles(item.value, companyType, theme)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formValidation.subscriptionTerm}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disablePast
                    label={
                      dictionary[localStorage.getItem('language')]
                        .assign_device_shipping_date
                    }
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    value={values.shippingDate ? values.shippingDate : null}
                    size="small"
                    name="shippingDate"
                    error={!!formValidation.shippingDate}
                    helperText={formValidation.shippingDate}
                    // disabled={isShippingDateDisabled}
                    // InputProps={isShippingDateDisabled ? { className: classes.disabledColor } : null}
                    onChange={(value) =>
                      handleChangeDate(value, 'shippingDate')
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          ) : (
            <div className="row m-4"></div>
          )}
        </form>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {
              dictionary[localStorage.getItem('language')]
                .delete_ekey_cancel_option
            }
          </Button>
          <Button color="primary" variant="contained" onClick={transferConfirm}>
            {dictionary[localStorage.getItem('language')].submit_button}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <Link class="btn btn-primary float-right" to="/varPanel">
                      {
                        dictionary[localStorage.getItem('language')]
                          .company_list_button
                      }
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="card-body"
                style={{ height: '100%', width: '100%' }}
              >
                <div className="row" style={{ height: '100%' }}>
                  <div className="col-md-4" style={{ height: '95%' }}>
                    <Typography variant="subtitle1">
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .var_stock_header
                        }
                      </strong>
                    </Typography>
                    <DataGrid
                      density="compact"
                      disableSelectionOnClick
                      rows={company_Stock_List.companyStock}
                      columns={columns}
                      pageSize={50}
                      loading={props.loading}
                      disableColumnMenu
                      disableColumnSelector
                    />
                  </div>

                  <div
                    className="col-md-1"
                    style={{
                      opacity: isTransferDeviceButtonEnabled ? 1 : 0.5,
                      height: '95%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ForwardIcon
                      className="mx-auto"
                      onClick={() =>
                        isTransferDeviceButtonEnabled
                          ? setpopups(true)
                          : setpopups(false)
                      }
                      color="primary"
                      style={{
                        fontSize: '60px',
                        cursor: isTransferDeviceButtonEnabled
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                    />
                  </div>
                  <div className="col-md-7" style={{ height: '95%' }}>
                    <Typography variant="subtitle1">
                      <strong>
                        {company_related_device_List.company.company_name}'s{' '}
                        {
                          dictionary[localStorage.getItem('language')]
                            .devices_title
                        }
                      </strong>
                    </Typography>
                    <DataGrid
                      disableSelectionOnClick
                      rows={company_related_device_List.details}
                      columns={columns1}
                      pageSize={50}
                      loading={props.loading}
                      density="compact"
                      disableColumnMenu
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    // pageLoading: state.company.pageLoading
  }
}

DeviceTransfer.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(DeviceTransfer))
