import React, { useEffect, useState, useMemo } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { getAllGeneratedTickets } from '../store/actions/deviceGroupActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
  Create,
  Inbox,
} from '@material-ui/icons'

import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import Collapse from '@material-ui/core/Collapse'
import dictionary from '../store/dictionary.json'

const columns = [
  {
    field: '#',
    headerName: '',
    width: 80,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{''}</strong>,
    renderCell: (params) => (
      <Tooltip
        title={
          dictionary[localStorage.getItem('language')].ticket_details_tooltip
        }
        arrow
      >
        <img
          alt="Ticket"
          src="../assets/img/ticket.svg"
          style={{ cursor: 'pointer', width: 30 }}
        />
      </Tooltip>
    ),
  },
  {
    field: 'sequence',
    headerName: 'S.No',
    width: 90,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].ticket_number_header}
      </strong>
    ),
  },
  {
    field: 'ticket_id',
    headerName: 'Ticket Id',
    width: 270,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].ticket_id_header}
      </strong>
    ),
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
    width: 230,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].full_name_header}
      </strong>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
    sortable: false,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].phone_header}
      </strong>
    ),
    renderCell: (params) => {
      return params.value
        ? (params.row.country_code ? params.row.country_code + ' ' : '') +
            params.value.substring(0, 3) +
            '-' +
            params.value.substring(3, 6) +
            '-' +
            params.value.substring(6, params.value.length)
        : ''
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 220,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].email_header}
      </strong>
    ),
    renderCell: (params) => (
      <a href={`mailto:${params.value}`} target="_blank">
        {params.value}
      </a>
    ),
  },
  {
    field: 'created',
    headerName: 'Created Date',
    width: 150,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].created_date_header}
      </strong>
    ),
    renderCell: (params) => moment(params.value).format('MM/DD/YYYY HH:mm'),
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const Support = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props

  const dispatch = useDispatch()
  const { generated_Tickets } = useSelector((state) => state.deviceGroup)
  const history = useHistory()
  const classes = useStyles()

  const [open, setOpen] = React.useState(-1)

  const handleClick = (index) => {
    if (open == index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }

  const questionAnswer = dictionary[localStorage.getItem('language')].faqs

  const [isLoad, setIsLoad] = useState(false)

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  useEffect(() => {
    dispatch(getAllGeneratedTickets(true))
  }, [dispatch])

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id
    if (action == '#') {
      localStorage.setItem('tempSupportId', id)
      history.push({
        pathname: '/supportTicketDetails',
      })
    }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%', overflow: 'hidden' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div className="card-body" style={{ height: 480, width: '100%' }}>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active font-weight-bold"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                      style={{ padding: '10px 30px' }}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .support_tickets_header
                      }
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link  font-weight-bold"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                      style={{ padding: '10px 30px' }}
                    >
                      {dictionary[localStorage.getItem('language')].faqs_header}
                    </a>
                  </li>
                </ul>

                {/* {(isLoad ? <DataGrid onCellClick={currentlySelected} rows={generated_Tickets} columns={columns} pageSize={250} loading={props.loading} /> : '')} */}

                <div className="tab-content" style={{ height: '100%' }}>
                  <div
                    className="tab-pane active"
                    id="tabs-1"
                    role="tabpanel"
                    style={{ height: '100%' }}
                  >
                    <div
                      className="card-body"
                      style={{ height: '90%', width: '100%' }}
                    >
                      {isLoad ? (
                        <DataGrid
                          onCellClick={currentlySelected}
                          rows={generated_Tickets}
                          columns={columns}
                          pageSize={25}
                          loading={props.loading}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="tab-pane" id="tabs-2" role="tabpanel">
                    <div
                      style={{
                        height: 'calc(100vh - 305px)',
                        overflowY: 'auto',
                      }}
                    >
                      {questionAnswer.map((item, index) => (
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={classes.root}
                          // style={{ minHeight: 319, maxHeight:319, overflow:"auto"}}
                        >
                          <ListItem button onClick={() => handleClick(index)}>
                            <ListItemIcon>
                              <QuestionAnswerIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.question} />
                            {open == index ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={open == index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem button className={classes.nested}>
                                {/* <ListItemIcon>
                                  <StarBorder />
                                </ListItemIcon> */}
                                <ListItemText primary={item.answer} />
                              </ListItem>
                            </List>
                          </Collapse>
                        </List>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

Support.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(Support))
