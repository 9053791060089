import React from 'react'
import Loader from 'react-loader-spinner'

const PageLoader = () => {
  return (
    <div className="loaderPosition">
      <Loader
        type="Grid"
        color="#1e4ca1"
        height={100}
        width={100}
        timeout={100000}
      />
    </div>
  )
}

export default PageLoader
