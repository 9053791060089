import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { Person } from '@material-ui/icons'
import {
  DataGrid,
  GridColDef,
  GridApi,
  GridCellValue,
  GridCellParams,
} from '@material-ui/data-grid'
import { Checkbox, Typography, IconButton } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  userList,
  deleteUser,
  resetErrorAndSuccessState,
  updateUserList,
} from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
  Create,
} from '@material-ui/icons'
// import UserDetails from "./UserDetails";
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import dictionary from '../store/dictionary.json'

const UsersList = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const dispatch = useDispatch()
  const { user_List } = useSelector((state) => state.user)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()
  const [isLoad, setIsLoad] = useState(false)

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <Tooltip
          title={
            dictionary[localStorage.getItem('language')].user_details_tooltip
          }
          arrow
        >
          <Person
            className="text-primary"
            style={{ fontSize: 45, cursor: 'pointer' }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'dispay_name',
      headerName: 'Display Name',
      width: 220,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].display_name_header}
        </strong>
      ),
    },
    {
      field: 'e_mail',
      headerName: 'Email',
      width: 220,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].email_header}
        </strong>
      ),
      renderCell: (params) => (
        <a href={`mailto:${params.value}`} target="_blank">
          {params.value}
        </a>
      ),
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 160,
      sortable: false,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].phone_header}
        </strong>
      ),
      renderCell: (params) => {
        return params.value
          ? (params.row.country_code ? params.row.country_code + ' ' : '') +
              params.value.substring(0, 3) +
              '-' +
              params.value.substring(3, 6) +
              '-' +
              params.value.substring(6, params.value.length)
          : ''
      },
    },
    {
      field: 'is_installer',
      headerName: 'Installer',
      width: 120,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].installer_header}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Installer"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'is_sentinel',
      headerName: 'Sentinel',
      width: 120,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].sentinel_header}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Sentinel"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'isSuperAdmin',
      headerName: 'Super Admin',
      width: 160,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].super_admin_option}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Super Admin"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'is_inactive',
      headerName: 'Active',
      width: 120,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].active_header}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Active"
          src={
            !params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      width: 180,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].last_activity_header}
        </strong>
      ),
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            title={
              dictionary[localStorage.getItem('language')].last_activity_tooltip
            }
            arrow
          >
            <Link>{moment(params.value).format('MM/DD/YYYY HH:mm')}</Link>
          </Tooltip>
        ) : (
          '-'
        ),
    },
  ]

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const userId = GridCellParams.row.id
    if (action == '#') {
      localStorage.setItem('tempId', userId)
      history.push({
        pathname: '/modifyUser',
        isLoaderRequired: true,
      })
    } else if (action == 'Delete') {
      var row = GridCellParams.row
      dispatch(deleteUser(userId, row))
      dispatch(updateUserList(user_List.filter((e) => e !== row)))
    } else if (action == 'lastActivity') {
      var row = GridCellParams.row
      if (row.lastActivity) {
        history.push({
          pathname: 'activity',
          state: { id: userId, type: 'Triggered By' },
        })
      }
    }
  }

  useEffect(() => {
    dispatch(userList(true, false))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const [hideInactive, setHideInactive] = useState(true)

  const handleHideInactive = (event) => {
    setHideInactive(event.target.checked)
  }

  const showInactiveStateChanged = () => {
    if (hideInactive) {
      dispatch(userList(false, false))
    } else {
      dispatch(userList(false, true))
    }
  }

  useEffect(() => {
    showInactiveStateChanged()
  }, [hideInactive])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <Typography className="ml-3" variant="subtitle1">
                      <Checkbox
                        color="primary"
                        checked={hideInactive}
                        onChange={handleHideInactive}
                      />
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .hide_inactive_toggle
                        }
                      </strong>
                    </Typography>
                  </div>
                  <div className="col-lg-6">
                    {permissionDetails.permission_all ||
                    permissionDetails.permission_add ? (
                      <Link class="btn btn-primary float-right" to="/addUser">
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_user_button
                        }
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              <div className="card-body" style={{ height: 520, width: '100%' }}>
                {isLoad ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={user_List}
                    columns={columns}
                    pageSize={25}
                    loading={props.loading}
                    disableColumnSelector
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

UsersList.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(UsersList))
