import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Switch from '@material-ui/core/Switch'
import validator from 'validator'
import {
  BrowserRouter as Router,
  useHistory,
  Route,
  Link,
} from 'react-router-dom'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardContent,
  OutlinedInput,
  List,
  ListItem,
  ListItemAvatar,
  Box,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
} from '@material-ui/icons'
import HelpIcon from '@material-ui/icons/Help'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ForwardIcon from '@material-ui/icons/Forward'
import '../App.css'
import Tooltip from '@material-ui/core/Tooltip'
import {
  modifyCompanySettings,
  getCompanySettings,
  getCompanySettingsOld,
  resetErrorAndSuccessState,
  companySettingsDetailsSuccess,
} from '../store/actions/companySettingsActions'
import { timeZoneList } from '../store/actions/userActions'
import { Autocomplete } from '@material-ui/lab'
import moment from 'moment'
import dictionary from '../store/dictionary.json'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  infoStyle: {
    marginLeft: 10,
    cursor: 'pointer',
  },
}))

const CompanySettings = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const { timeZone_List } = useSelector((state) => state.user)
  const { companySettingsDetails, companySettingsDetailsOld } = useSelector(
    (state) => state.companySettings
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const passwordExpiryDuration = [
    {
      label:
        dictionary[localStorage.getItem('language')].force_password_option_1,
      value: 'Never',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].force_password_option_2,
      value: 'Every month',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].force_password_option_3,
      value: 'Every 3 months',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].force_password_option_4,
      value: 'Every 6 months',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].force_password_option_5,
      value: 'Every year',
    },
  ]

  const password_reset_security_options = [
    {
      label:
        dictionary[localStorage.getItem('language')].password_reset_option_1,
      value: 'email',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].password_reset_option_2,
      value: 'text',
    },
    {
      label:
        dictionary[localStorage.getItem('language')].password_reset_option_3,
      value: 'admin',
    },
  ]

  const password_reset_secondary_options = [
    {
      label:
        dictionary[localStorage.getItem('language')]
          .password_reset_secondary_option_1,
      value: 'nothing',
    },
    {
      label:
        dictionary[localStorage.getItem('language')]
          .password_reset_secondary_option_2,
      value: 'alert',
    },
    {
      label:
        dictionary[localStorage.getItem('language')]
          .password_reset_secondary_option_3,
      value: 'flag',
    },
  ]

  const handleChange = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    dispatch(
      companySettingsDetailsSuccess({
        ...companySettingsDetails,
        [prop]: value,
      })
    )
  }

  const oldSettings = companySettingsDetailsOld

  const handleSubmitClick = (e) => {
    const companyId = localStorage.getItem('tempCompId')
    var Data = {
      id: companySettingsDetails.id,
      companyId: companyId,
      isMultiPhoneLogin: companySettingsDetails.is_multi_phone_login,
      offlineReconectTime: companySettingsDetails.offline_reconect,
      defaultTimeZoneId: companySettingsDetails.default_time_zone_id,
      ekeyDuration: companySettingsDetails.ekey_duration,
      defaultCountryCode: companySettingsDetails.default_country_code,
      is_force_firmware_update: companySettingsDetails.is_force_firmware_update,
      allowed_firmware: companySettingsDetails.allowed_firmware,
      password_expiry_duration: companySettingsDetails.password_expiry_duration,
      biometric_security: companySettingsDetails.biometric_security,
      require_2fa: companySettingsDetails.require_2fa,
      password_reset_security: companySettingsDetails.password_reset_security,
      password_reset_secondary_action:
        companySettingsDetails.password_reset_secondary_action,
      password_reset_phone: companySettingsDetails.password_reset_phone,
    }

    if (!companySettingsDetails.id) {
      Data.scheduleOpens = [
        {
          scheduleDay: 'Monday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Tuesday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Wednesday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Thursday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Friday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Saturday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
        {
          scheduleDay: 'Sunday',
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
      ]
      Data.scheduleExceptions = [
        {
          exceptionDate: moment().format('YYYY-MM-DD'),
          openWholeDay: false,
          openTime: '',
          closeTime: '',
        },
      ]
    }

    dispatch(modifyCompanySettings(Data, oldSettings))
  }

  useEffect(() => {
    dispatch(timeZoneList())
    dispatch(getCompanySettings())
    dispatch(getCompanySettingsOld())
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const handleChangeSchedule = (event, type) => {
    if (event) {
      if (type == 'zone') {
        // const zoneDetails = {...companySettingsDetails}
        // zoneDetails['default_time_zone'] = event.full_time_zone
        // zoneDetails['default_time_zone_details'] = event
        // dispatch(companySettingsDetailsSuccess(zoneDetails));
        dispatch(
          companySettingsDetailsSuccess({
            ...companySettingsDetails,
            default_time_zone_id: event,
          })
        )
      }
      if (type == 'offline_reconect') {
        dispatch(
          companySettingsDetailsSuccess({
            ...companySettingsDetails,
            offline_reconect: event.hour,
          })
        )
      }
      if (type == 'ekey_duration') {
        dispatch(
          companySettingsDetailsSuccess({
            ...companySettingsDetails,
            ekey_duration: event.hour,
          })
        )
      }

      if (type == 'allowed_firmware') {
        dispatch(
          companySettingsDetailsSuccess({
            ...companySettingsDetails,
            allowed_firmware: event.user,
          })
        )
      }

      if (type == 'password_expiry_duration') {
        dispatch(
          companySettingsDetailsSuccess({
            ...companySettingsDetails,
            password_expiry_duration: event.value,
          })
        )
      }
    }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder" style={{ height: 'auto' }}>
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <Link
                      class="btn btn-primary float-right mr-2"
                      to="/companyMailerSetting"
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .mail_config_button
                      }
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <List>
                      <ListItem>
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .enable_multi_phone_login_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .enable_multi_phone_login_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            checked={
                              companySettingsDetails.is_multi_phone_login
                                ? true
                                : false
                            }
                            inputProps={{ 'aria-label': 'primary' }}
                            name="is_multi_phone_login"
                            onChange={handleChange('is_multi_phone_login')}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .user_offline_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .user_offline_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl variant="outlined">
                            <Autocomplete
                              size="small"
                              style={{ width: 250 }}
                              id="combo-box-demo"
                              value={
                                companySettingsDetails.offline_reconect
                                  ? {
                                      id: 1,
                                      hour: companySettingsDetails.offline_reconect,
                                    }
                                  : null
                              }
                              onChange={(event, val) =>
                                handleChangeSchedule(val, 'offline_reconect')
                              }
                              options={Hours}
                              getOptionLabel={(option) => option.hour}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="hrs"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '12px', marginBottom: '12px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .scheduled_open_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .scheduled_open_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Link
                            to="/scheduleOpen"
                            style={{
                              display: companySettingsDetails.id
                                ? 'block'
                                : 'none',
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ width: 250 }}
                            >
                              Set
                            </Button>
                          </Link>
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              display: companySettingsDetails.id
                                ? 'none'
                                : 'block',
                              width: 200,
                            }}
                          >
                            Set
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '17px', marginBottom: '17px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .default_time_zone_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .default_time_zone_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl variant="outlined">
                            <Autocomplete
                              size="small"
                              style={{ width: 250 }}
                              id="combo-box-demo"
                              value={
                                companySettingsDetails.default_time_zone_id
                              }
                              onChange={(event, val) =>
                                handleChangeSchedule(val, 'zone')
                              }
                              options={timeZone_List.map((it) => it.id)}
                              getOptionLabel={(option) =>
                                timeZone_List.find((it) => it.id === option)
                                  ?.full_time_zone
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Zone"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .temp_ekey_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .temp_ekey_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl variant="outlined">
                            <Autocomplete
                              size="small"
                              style={{ width: 250 }}
                              id="combo-box-demo"
                              value={
                                companySettingsDetails.ekey_duration
                                  ? {
                                      id: 1,
                                      hour: companySettingsDetails.ekey_duration,
                                    }
                                  : null
                              }
                              onChange={(event, val) =>
                                handleChangeSchedule(val, 'ekey_duration')
                              }
                              options={Hours}
                              getOptionLabel={(option) => option.hour}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="hrs"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '17px', marginBottom: '17px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .default_phone_code_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .default_phone_code_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <TextField
                            id="outlined-basic"
                            label="Code"
                            variant="outlined"
                            size="small"
                            style={{ width: 250 }}
                            name="default_country_code"
                            value={companySettingsDetails.default_country_code}
                            onChange={handleChange('default_country_code')}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {/* <ListItem style={{ marginTop: '12px' }}>
                        <Typography variant="subtitle2">
                          <strong>Set Fob Programmers</strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title="Set the Users that have Fob Programming permissions"
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Link
                            to="/setFobProgrammers"
                            style={{
                              display: companySettingsDetails.id
                                ? 'block'
                                : 'none',
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ width: 250 }}
                            >
                              Set
                            </Button>
                          </Link>
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              display: companySettingsDetails.id
                                ? 'none'
                                : 'block',
                              width: 200,
                            }}
                          >
                            Set
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      /> */}

                      {/* <ListItem>
                        <Typography variant="subtitle2">
                          <strong>Force Firmware Updates</strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title="If this option is disabled, then firmware updates can be delayed until later. If this option is enabled, then the next user with permisions to update firmware is required to update the firmware before performing any options on the device."
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            checked={
                              companySettingsDetails.is_force_firmware_update
                                ? true
                                : false
                            }
                            inputProps={{ 'aria-label': 'primary' }}
                            name="is_force_firmware_update"
                            onChange={handleChange('is_force_firmware_update')}
                          />
                        </ListItemSecondaryAction>
                      </ListItem> */}

                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '17px', marginBottom: '17px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>Allowed to Update Firmware</strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title="What users are allowed to update firmware on the boards."
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl variant="outlined">
                            <Autocomplete
                              size="small"
                              style={{ width: 250 }}
                              id="combo-box-demo"
                              value={
                                companySettingsDetails.allowed_firmware
                                  ? {
                                      id: 1,
                                      user: companySettingsDetails.allowed_firmware,
                                    }
                                  : null
                              }
                              onChange={(event, val) =>
                                handleChangeSchedule(val, 'allowed_firmware')
                              }
                              options={AllowedFirmware}
                              getOptionLabel={(option) => option.user}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Users"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .force_password_change_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .force_password_change_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            size="small"
                            className="col-md-12"
                          >
                            <InputLabel id="password_expiry_duration">
                              &nbsp;
                            </InputLabel>
                            <Select
                              labelId="password_expiry_duration"
                              id="password_expiry_duration_select"
                              style={{ width: 250 }}
                              value={
                                companySettingsDetails.password_expiry_duration
                              }
                              onChange={handleChange(
                                'password_expiry_duration'
                              )}
                              input={<OutlinedInput />}
                              MenuProps={MenuProps}
                            >
                              {passwordExpiryDuration.map((item) => (
                                <MenuItem
                                  key={item.label}
                                  value={item.value}
                                  // style={getStyles(item.value, companyType, theme)}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem>
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .require_biometric_authentication_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .require_biometric_authentication_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            checked={
                              companySettingsDetails.biometric_security == '1'
                                ? true
                                : false
                            }
                            inputProps={{ 'aria-label': 'primary' }}
                            name="biometric_security"
                            onChange={handleChange('biometric_security')}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem>
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .require_2fa_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .require_2fa_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            checked={
                              companySettingsDetails.require_2fa == '1'
                                ? true
                                : false
                            }
                            inputProps={{ 'aria-label': 'primary' }}
                            name="require_2fa"
                            onChange={handleChange('require_2fa')}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider
                        variant="middle1"
                        style={{ background: '#1E4CA1' }}
                      />
                      <ListItem
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <Typography variant="subtitle2">
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .password_reset_security_option
                            }
                          </strong>
                        </Typography>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              dictionary[localStorage.getItem('language')]
                                .password_reset_security_tooltip
                            }
                            arrow
                          >
                            <HelpIcon className={classes.infoStyle} />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            size="small"
                            className="col-md-12"
                          >
                            <InputLabel id="password_reset_security">
                              &nbsp;
                            </InputLabel>
                            <Select
                              labelId="password_reset_security"
                              id="password_reset_security_select"
                              style={{ width: 250 }}
                              value={
                                companySettingsDetails.password_reset_security
                              }
                              onChange={handleChange('password_reset_security')}
                              input={<OutlinedInput />}
                              MenuProps={MenuProps}
                            >
                              {password_reset_security_options.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </ListItemSecondaryAction>
                      </ListItem>

                      {companySettingsDetails.password_reset_security ===
                      'text' ? (
                        <>
                          <ListItem
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .password_reset_secondary_option
                                }
                              </strong>
                            </Typography>
                            <ListItemAvatar>
                              <Tooltip
                                title={
                                  dictionary[localStorage.getItem('language')]
                                    .password_reset_secondary_tooltip
                                }
                                arrow
                              >
                                <HelpIcon className={classes.infoStyle} />
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemSecondaryAction>
                              <FormControl
                                sx={{ m: 1, width: 300 }}
                                size="small"
                                className="col-md-12"
                              >
                                <InputLabel id="password_reset_secondary_action">
                                  &nbsp;
                                </InputLabel>
                                <Select
                                  labelId="password_reset_secondary_action"
                                  id="password_reset_secondary_action_select"
                                  style={{ width: 250 }}
                                  value={
                                    companySettingsDetails.password_reset_secondary_action
                                  }
                                  onChange={handleChange(
                                    'password_reset_secondary_action'
                                  )}
                                  input={<OutlinedInput />}
                                  MenuProps={MenuProps}
                                >
                                  {password_reset_secondary_options.map(
                                    (item) => (
                                      <MenuItem
                                        key={item.label}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </>
                      ) : null}
                      {companySettingsDetails.password_reset_security ===
                      'admin' ? (
                        <>
                          <ListItem>
                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .password_reset_contact_phone
                                }
                              </strong>
                            </Typography>
                            <ListItemAvatar>
                              <Tooltip
                                title={
                                  dictionary[localStorage.getItem('language')]
                                    .password_reset_contact_tooltip
                                }
                                arrow
                              >
                                <HelpIcon className={classes.infoStyle} />
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemSecondaryAction>
                              <TextField
                                id="outlined-basic"
                                label="Code"
                                variant="outlined"
                                size="small"
                                style={{ width: 250 }}
                                name="password_reset_phone"
                                value={
                                  companySettingsDetails.password_reset_phone
                                }
                                onChange={handleChange('password_reset_phone')}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </>
                      ) : null}
                    </List>

                    <Button
                      className="mt-4"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmitClick}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .save_changes_button
                      }
                      &nbsp;
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ display: props.loading ? 'block' : 'none' }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.companySettings.loading,
    error: state.companySettings.error,
    success: state.companySettings.success,
    pageLoading: state.companySettings.pageLoading,
  }
}

CompanySettings.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(CompanySettings))

var Hours = new Array()
for (let index = 1; index < 25; index++) {
  var key = { id: index, hour: String(index) }
  Hours.push(key)
}

var AllowedFirmware = new Array()
const users = ['All Users', 'Installer Only']
for (let index = 1; index < 3; index++) {
  var key = { id: index, user: users[index - 1] }
  AllowedFirmware.push(key)
}
