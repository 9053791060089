import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { resetErrorAndSuccessState } from '../store/actions/userActions'
import {
  companyRelatedDeviceList,
  companyRelatedDeviceListSuccess,
} from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import { Typography, Checkbox } from '@material-ui/core'
import Submenu from '../header_sub_menu'
import moment from 'moment'

const CompanyRelatedDeviceKeys = (props) => {
  const DEVICE_STATUS = {
    STOCK: 'stock',
    UNCONFIGURED: 'unconfigured',
    CONFIGURED: 'configured',
    RETIRED: 'retired',
  }

  const columns = [
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      width: 200,
      renderHeader: () => <strong>{'Serial Number'}</strong>,
      renderCell: (params) => {
        return params.row.serial_front && params.row.serial_back
          ? params.row.serial_front + ' - ' + params.row.serial_back
          : ''
      },
    },
    {
      field: 'ccd',
      headerName: 'Board Type',
      width: 200,
      renderHeader: () => <strong>{'Board Type'}</strong>,
    },
    {
      field: 'status',
      headerName: 'Stock',
      width: 150,
      renderHeader: () => <strong>{'Stock'}</strong>,
      cellClassName: 'shippingDateInFuture',
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return (
            'Device will be activated on ' + shippingDate.format('MM/DD/YYYY')
          )
        } else {
          return (
            <img
              alt="Stock"
              src={
                params.row.status === DEVICE_STATUS.STOCK
                  ? '../assets/img/checked.png'
                  : '../assets/img/unchecked.png'
              }
              style={{ width: 20 }}
            />
          )
        }
      },
    },
    {
      field: 'status1',
      headerName: 'Unconfigured',
      width: 150,
      renderHeader: () => <strong>{'Unconfigured'}</strong>,
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Unconfigured"
            src={
              params.row.status === DEVICE_STATUS.UNCONFIGURED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
    },
    {
      field: 'status2',
      headerName: 'Configured',
      width: 150,
      renderHeader: () => <strong>{'Configured'}</strong>,
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Configured"
            src={
              params.row.status === DEVICE_STATUS.CONFIGURED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
    },
    {
      field: 'status3',
      headerName: 'Retired',
      width: 150,
      renderHeader: () => <strong>{'Retired'}</strong>,
      renderCell: (params) => {
        const currentDate = moment()
        const shippingDate = moment(params.row.start_date, 'YYYY-MM-DD')
        if (
          params.row.status == DEVICE_STATUS.UNCONFIGURED &&
          shippingDate.isAfter(currentDate)
        ) {
          return null
        }
        return (
          <img
            alt="Retired"
            src={
              params.row.status === DEVICE_STATUS.RETIRED
                ? '../assets/img/checked.png'
                : '../assets/img/unchecked.png'
            }
            style={{ width: 20 }}
          />
        )
      },
    },
  ]

  const { enqueueSnackbar, error, success, loading, pageLoading } = props

  const dispatch = useDispatch()
  const { company_related_device_List } = useSelector((state) => state.company)
  const history = useHistory()

  useEffect(() => {
    const id = localStorage.getItem('tempCompanyId')
    if (!id) {
      history.push({
        pathname: '/company',
      })
    } else {
      dispatch(companyRelatedDeviceList(id))
    }
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id
    if (action == '#') {
      localStorage.setItem('tempCompanyId', id)
      history.push({
        pathname: '/modifyCompany',
      })
    }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder" id="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-10 text-center">
                    <Typography variant="subtitle1">
                      <strong> Company: </strong>{' '}
                      {company_related_device_List.company.company_name} <br />
                      <strong> Company ID: </strong>{' '}
                      {company_related_device_List.company.company_id}
                    </Typography>
                  </div>
                  <div className="col-lg-2">
                    <Link class="btn btn-primary float-right" to="/company">
                      Company List
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                <DataGrid
                  onCellClick={currentlySelected}
                  rows={company_related_device_List.details}
                  disableColumnMenu
                  columns={columns.map((column) => ({
                    ...column,
                    sortable: false,
                  }))}
                  pageSize={25}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    error: state.company.error,
    success: state.company.success,
    pageLoading: state.company.pageLoading,
  }
}

CompanyRelatedDeviceKeys.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(CompanyRelatedDeviceKeys))
