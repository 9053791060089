import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  timeZoneList,
  resetErrorAndSuccessState,
} from '../store/actions/userActions'
import {
  availableDeviceList,
  updateAvailableDeviceList,
  createDeviceGroup,
  fail,
} from '../store/actions/deviceGroupActions'
import { withSnackbar } from 'notistack'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Switch from '@material-ui/core/Switch'
import { format } from 'date-fns'
import { getTimes } from '../helper/commonHelper'

import {
  TextField,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  FormControl,
} from '@material-ui/core'
import ForwardIcon from '@material-ui/icons/Forward'
import '../App.css'
import DateFnsUtils from '@date-io/date-fns'
// import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab'
import { getCompanySettings } from '../store/actions/companySettingsActions'

import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import dictionary from '../store/dictionary.json'

const CheckboxWithGreenCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 5,
        top: 5,
        height: 14,
        width: 14,
        position: 'absolute',
        backgroundColor: '#1e4ca1',
        zIndex: 0,
      },
    },
  },
  checked: {},
})(Checkbox)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(0),
  },
  active: {
    // background: "#80808094",
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
    '&:last-child': {
      borderRight: 'solid 1px #cccccc',
    },
  },
}))

const AddDeviceGroup = (props) => {
  const { enqueueSnackbar, error, success, loading } = props
  const classes = useStyles()
  const { timeZone_List } = useSelector((state) => state.user)
  const { available_Devices } = useSelector((state) => state.deviceGroup)
  const dispatch = useDispatch()
  const history = useHistory()
  const [zoneValue, setZoneValue] = useState({})
  const [deviceGroup, setDeviceGroup] = useState([])
  const [timeList, settimeList] = useState([])

  const [dispatchStartTime, setDispatchStartTime] = React.useState()
  const [dispatchEndTime, setDispatchEndTime] = React.useState()

  const [values, setValues] = React.useState({
    fullName: '',
    displayName: '',
    startAt: '',
    endAt: '',
    isAllDay: true,
    zone: '',
    description: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    status: '1',
  })

  const [formValidation, setFormValidation] = React.useState({
    fullName: '',
    displayName: '',
  })

  const setDefaultTimeZone = (state) => {
    if (
      !values.zone &&
      state.companySettings?.companySettingsDetails?.default_time_zone_id
    ) {
      setValues({
        ...values,
        zone: state.companySettings?.companySettingsDetails
          ?.default_time_zone_id,
      })
    }
  }

  const { companySettingsDetails } = useSelector((state) => {
    setDefaultTimeZone(state)
    return state.companySettings
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.fullName) {
      isError = true
      formerr.fullName =
        dictionary[localStorage.getItem('language')].full_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.displayName) {
      isError = true
      formerr.displayName =
        dictionary[localStorage.getItem('language')].display_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    var value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (prop == 'status') {
      value = event.target.checked ? '3' : '1'
    }
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleSubmitClick = (e, returnRequired) => {
    e.preventDefault()
    if (handleValidation()) return false

    if (
      values.monday === false &&
      values.tuesday === false &&
      values.wednesday === false &&
      values.thursday === false &&
      values.friday === false &&
      values.saturday === false &&
      values.sunday === false
    ) {
      dispatch(
        fail(dictionary[localStorage.getItem('language')].days_error_tooltip)
      )
      return
    } else if (deviceGroup.length == 0) {
      dispatch(
        fail(
          dictionary[localStorage.getItem('language')]
            .select_device_error_tooltip
        )
      )
      return
    } else if (
      values.zone == null ||
      values.zone == '' ||
      values.zone.length === 0
    ) {
      dispatch(fail(dictionary[localStorage.getItem('language')].tz_required))
      return
    }

    const uData = {
      display_name: values.displayName,
      full_name: values.fullName,
      description: values.description,
      sch_monday: values.monday,
      sch_tuesday: values.tuesday,
      sch_wednesday: values.wednesday,
      sch_thursday: values.thursday,
      sch_friday: values.friday,
      sch_saturday: values.saturday,
      sch_sunday: values.sunday,
      start_at: !values.isAllDay ? values.startAt : null, // ? format(values.startAt, "HH:mm:ss") : null,
      end_at: !values.isAllDay ? values.endAt : null, // ? format(values.endAt, "HH:mm:ss") : null,
      is_all_day: values.isAllDay ? '1' : '0',
      time_zone_id: values.zone,
      available_devices: deviceGroup,
    }

    history.push({
      isReturn: returnRequired,
    })
    dispatch(createDeviceGroup(uData, history))
  }

  useEffect(() => {
    dispatch(timeZoneList())
    dispatch(availableDeviceList(true))
    dispatch(getCompanySettings())
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
  }, [success])

  const handleChangeSchedule = (event, type) => {
    if (event) {
      if (type === 'startAt') {
        setValues({ ...values, startAt: event })
      } else if (type === 'endAt') {
        setValues({ ...values, endAt: event })
      } else if (type === 'zone') {
        setValues({ ...values, zone: event })
      }
    }
  }

  const handleDeviceClick = (event) => {
    dispatch(
      updateAvailableDeviceList(available_Devices.filter((e) => e !== event))
    )
    setDeviceGroup([...deviceGroup, event])
  }

  const handleDeviceClickRemove = (event) => {
    dispatch(updateAvailableDeviceList([...available_Devices, event]))
    setDeviceGroup(deviceGroup.filter((e) => e !== event))
  }

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['fullName'] = ''
    resetForm['displayName'] = ''
    resetForm['startAt'] = ''
    resetForm['endAt'] = ''
    resetForm['isAllDay'] = true
    resetForm['description'] = ''
    resetForm['startTime'] = ''
    resetForm['endTime'] = ''
    resetForm['monday'] = false
    resetForm['tuesday'] = false
    resetForm['wednesday'] = false
    resetForm['thursday'] = false
    resetForm['friday'] = false
    resetForm['saturday'] = false
    resetForm['sunday'] = false
    setValues(resetForm)
    setZoneValue({})
    setDeviceGroup([])
    dispatch(availableDeviceList(true))
  }

  const [pageLoading, setPageLoading] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    settimeList(getTimes())
  }, [getTimes])

  const handleValueChange = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      setValues({ ...values, startAt: val })
    }
    setDispatchStartTime(value)
  }

  const handleValueChange1 = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      setValues({ ...values, endAt: val })
    }
    setDispatchEndTime(value)
  }

  const isTimeDisabled = useMemo(() => {
    if (values['isAllDay'] == true) {
      values['startAt'] = null
      values['endAt'] = null
      setDispatchStartTime(null)
      setDispatchEndTime(null)
      return true
    }
    return false
  }, [values])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header">
                <div className="row">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'no-wrap',
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className="mr-3"
                        style={{ width: '12rem' }}
                        onClick={(event, value) =>
                          handleSubmitClick(event, false)
                        }
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_and_create_another_button
                        }
                        <i
                          className="fa fa-spinner fa-spin right"
                          style={{
                            display: props.loading ? 'block' : 'none',
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        style={{ width: '12rem' }}
                        onClick={(event, value) =>
                          handleSubmitClick(event, true)
                        }
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_and_return_to_device_groups_button
                        }
                      </Button>
                    </div>
                    <Typography
                      className="mt-2 ml-2"
                      variant="subtitle2"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .deactivate_device_group_option
                        }
                      </strong>
                    </Typography>
                    <Switch
                      color="primary"
                      checked={values.status == '3' ? true : false}
                      inputProps={{ 'aria-label': 'primary' }}
                      name="status"
                      onChange={handleChange('status')}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '-10px' }}>
                  <div className="col-md-4 my-3" style={{ zIndex: '10' }}>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .display_name_field_name +
                            ' ' +
                            dictionary[localStorage.getItem('language')]
                              .required_field_name
                          }
                          variant="outlined"
                          name="displayName"
                          value={values.displayName}
                          onChange={handleChange('displayName')}
                          error={!!formValidation.displayName}
                          helperText={formValidation.displayName}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .full_name_field_name +
                            ' ' +
                            dictionary[localStorage.getItem('language')]
                              .required_field_name
                          }
                          variant="outlined"
                          name="fullName"
                          value={values.fullName}
                          onChange={handleChange('fullName')}
                          error={!!formValidation.fullName}
                          helperText={formValidation.fullName}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="fname"
                          fullWidth
                          label={
                            dictionary[localStorage.getItem('language')]
                              .description_field_name
                          }
                          multiline
                          rows={10}
                          variant="outlined"
                          name="description"
                          value={values.description}
                          onChange={handleChange('description')}
                        />
                      </div>
                    </div>
                    <Divider
                      variant="middle1"
                      className="mt-4 mb-4"
                      style={{ background: '#1E4CA1' }}
                    />
                    <div className="row">
                      <div className="col-md-12">
                        <Typography
                          variant="subtitle1"
                          className="text-center mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .access_days_and_times_header
                            }
                          </strong>
                        </Typography>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row rowtime mx-auto">
                        <form class="form-inline" role="form">
                          <div class="col-md-12">
                            <Box display="flex" alignItems="center">
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="isAllDay"
                                    color="primary"
                                    checked={values.isAllDay}
                                  />
                                }
                                value={values.isAllDay}
                                style={{
                                  marginLeft: -10,
                                  marginRight: -5,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                                onChange={handleChange('isAllDay')}
                                // disabled={isAllDayDisabled}
                                // InputProps={isAllDayDisabled ? { className: classes.disabledColor } : null}
                              />
                              <Typography
                                variant="subtitle2"
                                className="mr-4"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    alignItems: 'center',
                                  }}
                                >
                                  <strong>
                                    {
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].all_day_option
                                    }
                                  </strong>
                                </span>
                              </Typography>
                              <TimePicker
                                placeholder={
                                  dictionary[localStorage.getItem('language')]
                                    .start_time_field_name
                                }
                                showSecond={false}
                                focusOnOpen={true}
                                format="HH:mm"
                                value={dispatchStartTime}
                                onChange={handleValueChange}
                                style={{ width: 108 }}
                                clearText="Clear"
                                disabled={isTimeDisabled}
                                InputProps={
                                  isTimeDisabled
                                    ? { className: classes.disabledColor }
                                    : null
                                }
                              />

                              <TimePicker
                                placeholder={
                                  dictionary[localStorage.getItem('language')]
                                    .end_time_field_name
                                }
                                showSecond={false}
                                focusOnOpen={true}
                                format="HH:mm"
                                onChange={handleValueChange1}
                                value={dispatchEndTime}
                                style={{ width: 100 }}
                                clearText="Clear"
                                disabled={isTimeDisabled}
                                InputProps={
                                  isTimeDisabled
                                    ? { className: classes.disabledColor }
                                    : null
                                }
                              />
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="mt-3"
                            >
                              <FormControl variant="outlined">
                                <Autocomplete
                                  size="small"
                                  style={{
                                    marginLeft: 5,
                                    marginRight: -5,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    width: 300,
                                  }}
                                  id="combo-box-demo"
                                  value={values.zone}
                                  onChange={(event, val) =>
                                    handleChangeSchedule(val, 'zone')
                                  }
                                  options={timeZone_List.map((it) => it.id)}
                                  getOptionLabel={(option) =>
                                    timeZone_List.find((it) => it.id === option)
                                      ?.full_time_zone
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].time_zone_field_name
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Box>
                          </div>
                        </form>
                      </div>
                      <div
                        class="col-md-12"
                        className="mt-3 mx-auto"
                        display="flex"
                      >
                        <FormControl
                          component="fieldset"
                          style={{ marginTop: 3 }}
                        >
                          {/* error={!!formValidationEkey.days} */}
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            className="radioGroup"
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .monday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="mon"
                                  color="primary"
                                  checked={values.monday}
                                />
                              }
                              value={values.monday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('monday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .tuesday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="tue"
                                  color="primary"
                                  checked={values.tuesday}
                                />
                              }
                              value={values.tuesday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('tuesday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .wednesday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="wed"
                                  color="primary"
                                  checked={values.wednesday}
                                />
                              }
                              value={values.wednesday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('wednesday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .thursday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="thu"
                                  color="primary"
                                  checked={values.thursday}
                                />
                              }
                              value={values.thursday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('thursday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .friday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="fri"
                                  color="primary"
                                  checked={values.friday}
                                />
                              }
                              value={values.friday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('friday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .saturday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="sat"
                                  color="primary"
                                  checked={values.saturday}
                                />
                              }
                              value={values.saturday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('saturday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />

                            <Typography variant="subtitle2">
                              <strong>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .sunday_abbreviation
                                }
                              </strong>
                            </Typography>
                            <FormControlLabel
                              control={
                                <CheckboxWithGreenCheck
                                  name="sun"
                                  color="primary"
                                  checked={values.sunday}
                                />
                              }
                              value={values.sunday}
                              style={{ marginLeft: -32, marginTop: 18 }}
                              onChange={handleChange('sunday')}
                              // disabled={isWeekDisabled}
                              // InputProps={isWeekDisabled ? { className: classes.disabledColor } : null}
                            />
                          </RadioGroup>
                          {/* <FormHelperText>{formValidationEkey.days}</FormHelperText> */}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-5">
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-2 mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .available_devices_header
                            }
                          </strong>
                        </Typography>
                        <Card
                          variant="outlined"
                          style={{
                            height: '100%',
                            borderRadius: '40px',
                            border: '3px solid #1e4ca1',
                          }}
                        >
                          <CardContent>
                            <List
                              style={{
                                height: 540,
                                maxHeight: 540,
                                overflow: 'auto',
                              }}
                            >
                              {available_Devices.length > 0 ? (
                                available_Devices.map((item, i) => (
                                  <div>
                                    <ListItem
                                      className={classes.active}
                                      onClick={() => handleDeviceClick(item)}
                                    >
                                      <ListItemText primary={item.full_name} />
                                    </ListItem>
                                    <Divider component="li" />
                                  </div>
                                ))
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: 40 + '%',
                                  }}
                                  className="mb-3"
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .no_devices
                                  }
                                </Typography>
                              )}
                            </List>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-md-2">
                        <ForwardIcon
                          color="primary"
                          style={{
                            fontSize: '60px',
                            marginTop: '280px',
                            marginLeft: '18px',
                          }}
                        />
                        <ForwardIcon
                          color="primary"
                          style={{
                            fontSize: '60px',
                            marginTop: '0px',
                            marginLeft: '18px',
                            transform: 'rotate(180deg)',
                          }}
                        />
                      </div>
                      <div className="col-md-5">
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-2 mb-2"
                        >
                          <strong>
                            {
                              dictionary[localStorage.getItem('language')]
                                .devices_in_group_header
                            }
                          </strong>
                        </Typography>
                        <Card
                          variant="outlined"
                          style={{
                            height: '100%',
                            borderRadius: '40px',
                            border: '3px solid #1e4ca1',
                          }}
                        >
                          <CardContent>
                            <List
                              style={{
                                minHeight: 540,
                                maxHeight: 540,
                                overflow: 'auto',
                              }}
                            >
                              {deviceGroup.length > 0 ? (
                                deviceGroup.map((item, i) => (
                                  <div>
                                    {/* <Button size="small"> */}
                                    <ListItem
                                      className={classes.active}
                                      onClick={() =>
                                        handleDeviceClickRemove(item)
                                      }
                                    >
                                      <ListItemText primary={item.full_name} />
                                    </ListItem>

                                    <Divider component="li" />
                                    {/* </Button> */}
                                  </div>
                                ))
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: 40 + '%',
                                  }}
                                  className="mb-3"
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .no_devices
                                  }
                                </Typography>
                              )}
                            </List>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

AddDeviceGroup.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(AddDeviceGroup))
