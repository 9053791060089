import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { Checkbox, Typography, IconButton } from '@material-ui/core'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
  Create,
} from '@material-ui/icons'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  timeZoneList,
  resetErrorAndSuccessState,
} from '../store/actions/userActions'
import {
  deviceGroupList,
  deleteDeviceGroup,
  updateDeviceGroupList,
} from '../store/actions/deviceGroupActions'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import moment from 'moment'
import timeZone from 'moment-timezone'
import { parse } from 'date-fns'
import Tooltip from '@material-ui/core/Tooltip'
import dictionary from '../store/dictionary.json'

const DeviceGroups = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props

  const dispatch = useDispatch()
  const { device_Group } = useSelector((state) => state.deviceGroup)
  const { permissionDetails } = useSelector((state) => state.auth)
  const { timeZone_List } = useSelector((state) => state.user)
  const history = useHistory()

  const [isLoad, setIsLoad] = useState(false)

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  const columns = [
    {
      field: 'status',
      headerName: '',
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) =>
        params.value == '1' || params.value == '2' ? (
          <Tooltip
            title={dictionary[localStorage.getItem('language')].active_tooltip}
            arrow
          >
            <img
              alt="Active"
              src="../assets/img/lock-group.png"
              style={{ cursor: 'pointer', width: 40 }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              dictionary[localStorage.getItem('language')].inactive_tooltip
            }
            arrow
          >
            <img
              alt="Inactive"
              src="../assets/img/lock-group-black.png"
              style={{ cursor: 'pointer', width: 40 }}
            />
          </Tooltip>
        ),
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      width: 167,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].full_name_header}
        </strong>
      ),
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'display_name',
      headerName: 'Display Name',
      width: 167,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].display_name_header}
        </strong>
      ),
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'sch_monday',
      headerName: 'Mon',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].monday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Monday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_tuesday',
      headerName: 'Tue',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].tuesday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Tuesday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_wednesday',
      headerName: 'Wed',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].wednesday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Wednesday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_thursday',
      headerName: 'Thu',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].thursday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Thursday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_friday',
      headerName: 'Fri',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].friday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Friday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_saturday',
      headerName: 'Sat',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].saturday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Saturday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'sch_sunday',
      headerName: 'Sun',
      width: 80,
      type: 'boolean',
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].sunday_abbreviation}
        </strong>
      ),
      renderCell: (params) => (
        <img
          alt="Sunday"
          src={
            params.value
              ? '../assets/img/checked.png'
              : '../assets/img/unchecked.png'
          }
          style={{ width: 20 }}
        />
      ),
    },
    {
      field: 'start_at',
      headerName: 'Start At',
      width: 100,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].start_time_field_name}
        </strong>
      ),
      renderCell: (params) =>
        params.value
          ? moment(moment().format('YYYY-MM-DD ' + params.value)).format(
              'HH:mm'
            )
          : '-',
    },
    {
      field: 'end_at',
      headerName: 'End At',
      width: 100,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].end_time_field_name}
        </strong>
      ),
      renderCell: (params) =>
        params.value
          ? moment(moment().format('YYYY-MM-DD ' + params.value)).format(
              'HH:mm'
            )
          : '-',
    },
    {
      field: 'time_zone_id',
      headerName: 'Zone',
      width: 150,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].time_zone_field_name}
        </strong>
      ),
      renderCell: (params) =>
        params.value
          ? timeZone_List.find((it) => it.id === params.value)?.full_time_zone
          : '-',
    },
  ]

  useEffect(() => {
    dispatch(timeZoneList())
    dispatch(deviceGroupList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
  }, [success])

  const handleHideInactive = (prop) => (event) => {
    if (event.target.checked) {
      dispatch(
        updateDeviceGroupList(device_Group.filter((e) => e.status !== '3'))
      )
    } else {
      dispatch(deviceGroupList(false))
    }
  }

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const id = GridCellParams.row.id

    if (action == 'status') {
      localStorage.setItem('tempDeviceGroupId', id)
      history.push({
        pathname: '/modifyDeviceGroup',
        isLoaderRequired: true,
      })
    } else if (action == 'Delete') {
      var row = GridCellParams.row
      dispatch(deleteDeviceGroup(id, row))
      dispatch(updateDeviceGroupList(device_Group.filter((e) => e !== row)))
    }
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <Typography className="ml-3" variant="subtitle1">
                      <Checkbox
                        color="primary"
                        onClick={handleHideInactive()}
                      />
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .hide_inactive_toggle
                        }
                      </strong>
                    </Typography>
                  </div>
                  <div className="col-lg-6">
                    {permissionDetails.permission_all ||
                    permissionDetails.permission_add ? (
                      <Link
                        class="btn btn-primary float-right"
                        to="/addDeviceGroup"
                      >
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_device_group_button
                        }
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: 520, width: '100%' }}>
                {isLoad ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={device_Group}
                    columns={columns}
                    pageSize={25}
                    loading={props.loading}
                    disableColumnSelector
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.deviceGroup.loading,
    error: state.deviceGroup.error,
    success: state.deviceGroup.success,
    pageLoading: state.deviceGroup.pageLoading,
  }
}

DeviceGroups.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(DeviceGroups))
