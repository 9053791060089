import React from 'react'
import PropTypes from 'prop-types'

const Footer = () => {
  return (
    <footer className="footer pt-0">
      <div className="row align-items-center justify-content-lg-between">
        <div className="col-lg-6">
          <div className="copyright text-center text-lg-left  text-muted mt-4 ml-4">
            &copy; 2021 Pac-Blu
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
