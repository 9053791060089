//import React from 'react';
import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
// { loginCheck } from "../store/actions/userActions";

import { connect, useSelector, useDispatch } from 'react-redux'

import { is_login } from './store/actions/userActions'
import { Description, ContactSupport, Settings } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import dictionary from './store/dictionary.json'

const Submenu = () => {
  const { permissionDetails } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(is_login())
  }, [])

  return (
    <div className="header bg-primary">
      <div className="container-fluid">
        <div className="header-body">
          {permissionDetails.permission_module.company ? (
            <ul className="headerIcons">
              <li>
                <Tooltip title="Home" arrow>
                  <Link to="/">
                    <img
                      alt="Home"
                      src="../assets/img/home_white.svg"
                      style={{ width: '33px', height: '40px' }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Company" arrow>
                  <Link to="/company">
                    <img
                      alt="Company"
                      src="../assets/img/comp.png"
                      style={{ width: '40px', height: '40px', fill: '#fff' }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="OTA" arrow>
                  <Link to="/ota">
                    <img
                      alt="OTA"
                      src="../assets/img/ota.png"
                      style={{
                        width: '30px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Device Type" arrow>
                  <Link to="/deviceType">
                    <img
                      alt="Device Type"
                      src="../assets/img/lock_white.svg"
                      style={{
                        width: '30px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Support" arrow>
                  <Link to="/adminSupport">
                    <img
                      alt="Support"
                      src="../assets/img/support_white.svg"
                      style={{
                        width: '30px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
            </ul>
          ) : (
            <ul className="headerIcons">
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')].home_title
                  }
                  arrow
                >
                  <Link to="/">
                    <img
                      alt="Home"
                      src="../assets/img/home_white.svg"
                      style={{ width: '33px', height: '40px' }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')].users_title
                  }
                  arrow
                >
                  <Link to="/users">
                    <img
                      alt="Users"
                      src="../assets/img/users_white.svg"
                      style={{ width: '40px', height: '40px' }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')].devices_title
                  }
                  arrow
                >
                  <Link to="/devices">
                    <img
                      alt="Devices"
                      src="../assets/img/lock_white.svg"
                      style={{
                        width: '30px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')]
                      .device_groups_title
                  }
                  arrow
                >
                  <Link to="/deviceGroups">
                    <img
                      alt="Device Groups"
                      src="../assets/img/lock_multi_white.svg"
                      style={{
                        width: '36px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')].activity_title
                  }
                  arrow
                >
                  <Link to="/activity">
                    <img
                      alt="Activity"
                      src="../assets/img/activity_white.svg"
                      style={{
                        width: '30px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')].support_title
                  }
                  arrow
                >
                  <Link to="/support">
                    <img
                      alt="Support"
                      src="../assets/img/support_white.svg"
                      style={{
                        width: '40px',
                        paddingTop: '3px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={
                    dictionary[localStorage.getItem('language')]
                      .company_settings_title
                  }
                  arrow
                >
                  <Link to="/companySettings">
                    <img
                      alt="Company Settings"
                      src="../assets/img/setting_white.svg"
                      style={{
                        width: '40px',
                        paddingTop: '4px',
                        height: '40px',
                        paddingBottom: '4px',
                      }}
                    />
                  </Link>
                </Tooltip>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Submenu
