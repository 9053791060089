import * as actionTypes from './actionTypes'
import Axios, { setHeaders } from '../../helper/axios'
import { createActivityLog } from '../../helper/commonHelper'

export const isLoading = () => {
  return {
    type: actionTypes.ISLOADING,
  }
}

export const isLoadingEkeyAssign = () => {
  return {
    type: actionTypes.ISLOADINGEKEYASSIGN,
  }
}

export const isPageLoading = () => {
  return {
    type: actionTypes.ISPAGELOADING,
  }
}

export const modiflySuccess = (success) => {
  return {
    type: actionTypes.MODIFY_SUCCESS,
    success: success,
  }
}

export const resetErrorAndSuccessState = () => {
  return {
    type: actionTypes.REST_ERROR_AND_SUCCESS_STATE,
  }
}

//##### Listing #####
export const FailUserListing = (error) => {
  return {
    type: actionTypes.ERROR_USER_LISTING,
    error: error,
  }
}

export const successUserListing = (listing) => {
  return {
    type: actionTypes.USER_LIST,
    listing,
  }
}

export const successUserDetails = (users) => {
  return {
    type: actionTypes.USER_DETAILS,
    payload: users,
  }
}

export const successUserDetailsOld = (users) => {
  return {
    type: actionTypes.USER_DETAILS_OLD,
    payload: users,
  }
}

//#####################################################################33

export const updateUserList = (listing) => {
  return {
    type: actionTypes.UPDATE_USER_LIST,
    listing,
  }
}

//##### Addition #####
export const FailUserAddition = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  }
}

export const additionSuccess = (user) => {
  return {
    type: actionTypes.SUCCESS,
    user,
  }
}

export const SuccessUserAddition = (response) => {
  return response
}

export const SuccessMessageUserAddition = (message) => {
  return {
    type: actionTypes.SUCCESS,
    success: message,
  }
}

export const UpdateUserDetails = (users) => {
  return {
    type: actionTypes.USER_DETAILS,
    payload: users,
  }
}

export const SuccessAssignedEkeyListing = (listing) => {
  return {
    type: actionTypes.ASSIGNED_EKEY_LIST,
    listing,
  }
}

export const SuccessEkeyListing = (listing) => {
  return {
    type: actionTypes.EKEY_LIST,
    listing,
  }
}

export const SuccessTineZoneListing = (listing) => {
  return {
    type: actionTypes.TIMEZONE_LIST,
    listing,
  }
}

export const successDeviceDetails = (details) => {
  return {
    type: actionTypes.DEVICE_DETAILS,
    payload: details,
  }
}

export const successLastReportedLocation = (details) => {
  return {
    type: actionTypes.LAST_REPORTED_LOCATION,
    payload: details,
  }
}

export const Fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  }
}

export const successActivityLogListing = (listing) => {
  return {
    type: actionTypes.ACTIVITY_LOG_LIST,
    listing,
  }
}

export const successActivityReport = (data) => {
  return {
    type: actionTypes.ACTIVITY_LOG_REPORT,
    data: data,
  }
}

export const token = () => {
  return localStorage.getItem('token')
}

export const registerUser = (requestPayload, history) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.post('/user/addUser', requestPayload, authCode)
      .then((response) => {
        requestPayload.id = response.data.payload.id
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          createActivityLog('User Created', '', requestPayload) // create activity log
          localStorage.setItem('tempId', response.data.payload.id)
          history.push({
            pathname: '/modifyUser',
            isLoaderRequired: false,
          })
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const userList = (needLoading = true, includeInactive) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/user/getAllUsers?includeInactive=' + includeInactive, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successUserListing(response))
        } else {
          dispatch(FailUserListing(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserListing(err.response.data.message))
        }
      })
  }
}

export const userDetails = (userId, needLoading = true) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }

    // const params
    setHeaders()
    Axios.get('/user/getUserDetails?id=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successUserDetails(response.data.payload))
          dispatch(successUserDetailsOld(response.data.payload))
        } else {
          dispatch(FailUserListing(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserListing(err.response.data.message))
        }
      })
  }
}

export const modifyUserDetails = (requestPayload, userOldDetails = '') => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.put('/user/modifyUserDetails', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          if (userOldDetails.note != requestPayload.userNotes) {
            // createActivityLog('Notes Added to User', "", requestPayload) // create activity log
            //createActivityLog('User Info Modified', "", {oldDetails: userOldDetails, modiflyedDetails: requestPayload})
          }

          if (
            userOldDetails.isInactive == '0' &&
            requestPayload.isInactive == '1'
          ) {
            //createActivityLog('User De-Activated', "", requestPayload);
            createActivityLog('User De-Activated', '', {
              oldDetails: userOldDetails,
              modiflyedDetails: requestPayload,
            })
          }

          if (
            userOldDetails.isInactive == '1' &&
            requestPayload.isInactive == '0'
          ) {
            createActivityLog('User Activated', '', {
              oldDetails: userOldDetails,
              modiflyedDetails: requestPayload,
            })
          }

          if (
            userOldDetails.displayName != requestPayload.displayName ||
            userOldDetails.phone_number != requestPayload.phoneNumer ||
            userOldDetails.is_sentinel != requestPayload.isSentinel ||
            userOldDetails.is_installer != requestPayload.isInstaller ||
            userOldDetails.is_fob != requestPayload.isFob ||
            userOldDetails.note != requestPayload.userNotes
          ) {
            createActivityLog('User Info Modified', '', {
              oldDetails: userOldDetails,
              modiflyedDetails: requestPayload,
            })
          }
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const modifyUserFlagged = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/user/flaggedUser', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          dispatch(userDetails(requestPayload.userId, true))
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const resendInviteLink = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.post('/user/resendInviteMail', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const sendResetLink = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    setHeaders()
    Axios.post('/user/sendResetLink', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        dispatch(FailUserAddition(err.response.data.message))
      })
  }
}

export const removeUserDevice = (userId) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    // const params
    setHeaders()
    Axios.delete('/user/forgetDevice?id=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          // createActivityLog('Removed User Device', "", userDetails) // create activity log
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const deleteUser = (userId, userDetails = '') => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    // const params
    setHeaders()
    Axios.delete('/user/deleteUser?id=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          createActivityLog('Removed User', '', userDetails) // create activity log
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const assignEkeyToUser = (
  requestPayload,
  eKeyDetails = '',
  userDetails = '',
  oldKeyDetails = ''
) => {
  return (dispatch) => {
    dispatch(isLoadingEkeyAssign())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/user/assignKeyToUser', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(modiflySuccess(response.data.message))
          if (requestPayload.type == 'group') {
            requestPayload.group_id = userDetails.group_id
            requestPayload.group_name = userDetails.group_name
            requestPayload.user_name = userDetails.displayName
            requestPayload.user_id = userDetails.id
            //createActivityLog('eKey Assigned', "", requestPayload, requestPayload.eKey)
            createActivityLog(
              'Assign Device Group to user',
              '',
              requestPayload,
              requestPayload.eKey
            ) // create activity log
            //createActivityLog('Assign Device Group to user', requestPayload.eKey, {keySchedule: requestPayload, eKeyDetails: eKeyDetails, userDetails: userDetails})
          } else {
            if (requestPayload.entryId != '') {
              createActivityLog('eKey Modified', requestPayload.eKey, {
                oldKeySchedule: oldKeyDetails,
                newKeySchedule: requestPayload,
                eKeyDetails: eKeyDetails,
                userDetails: userDetails,
              })
            } else {
              createActivityLog('eKey Assigned', requestPayload.eKey, {
                keySchedule: requestPayload,
                eKeyDetails: eKeyDetails,
                userDetails: userDetails,
              })
            }
          }
        } else {
          dispatch(FailUserAddition(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserAddition(err.response.data.message))
        }
      })
  }
}

export const assignEkeyList = (userId) => {
  return (dispatch) => {
    // dispatch(isLoading());
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/user/getAssignedEkeys?userId=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(SuccessAssignedEkeyListing(response))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const deleteAssignedEkey = (id, keyDetails = '', uDetail) => {
  return (dispatch) => {
    // dispatch(isLoading());
    const authCode = {
      headers: { Authorization: token() },
    }

    if (keyDetails.is_group) {
      setHeaders()
      Axios.delete(
        '/user/deleteAssignedGroup?entryId=' + keyDetails.entry_id,
        authCode
      )
        .then((response) => {
          if (response.status === 200) {
            dispatch(modiflySuccess(response.data.message))
            createActivityLog('eKey Deleted', keyDetails.ekey_id, {
              keyDetails: keyDetails,
              userDetails: uDetail,
            }) // create activity log
          } else {
            dispatch(Fail(response.data.message))
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            dispatch(Fail(err.response.data.message))
          }
        })
    } else {
      // const params
      setHeaders()
      Axios.delete(
        '/user/deleteAssignedEkey?entryId=' + keyDetails.entry_id,
        authCode
      )
        .then((response) => {
          if (response.status === 200) {
            dispatch(modiflySuccess(response.data.message))
            createActivityLog('eKey Deleted', keyDetails.ekey_id, {
              keyDetails: keyDetails,
              userDetails: uDetail,
            }) // create activity log
          } else {
            dispatch(Fail(response.data.message))
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            dispatch(Fail(err.response.data.message))
          }
        })
    }
  }
}

export const ekeyList = (type = 'device', userId = '', needLoading = false) => {
  return (dispatch) => {
    if (needLoading === true) {
      dispatch(isPageLoading())
    }
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/user/getEkeys?type=' + type + '&userId=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(SuccessEkeyListing(response))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const timeZoneList = () => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/user/getAllTimeZone', authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(SuccessTineZoneListing(response))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const getDeviceDetails = (deviceId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    // const params
    setHeaders()
    Axios.get('/user/getDeviceDetails?deviceId=' + deviceId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successDeviceDetails(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const getLastReportedLocation = (deviceId) => {
  return (dispatch) => {
    dispatch(isPageLoading())
    const authCode = {
      headers: { Authorization: token() },
    }

    // const params
    setHeaders()
    Axios.get('/user/getLastReportedLocation?deviceId=' + deviceId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successLastReportedLocation(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const activityLogList = (requestPayload, pageLoading = true) => {
  return (dispatch) => {
    if (pageLoading) {
      dispatch(isPageLoading())
    }
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/user/activityLogs', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successActivityLogListing(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const activityLogReport = (requestPayload, pageLoading = true) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/user/activityReport', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(successActivityReport(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const getActivityLogDetails = (requestPayload, pageLoading = true) => {
  return (dispatch) => {
    if (pageLoading) {
      dispatch(isPageLoading())
    }
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.get('/activity/getActivity', authCode)
      .then((response) => {
        if (response.status === 200) {
          // const asyncRes = await Promise.all(response.data.payload.rows.map(async (i) => {
          //   await sleep(10);
          //   return i + 1;
          // }));

          dispatch(successActivityLogDetails(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const successActivityLogDetails = (details) => {
  return {
    type: actionTypes.ACTIVITY_LOG_DETAILS,
    details,
  }
}

export const success = (success) => {
  return {
    type: actionTypes.SUCCESS,
    success: success,
  }
}

export const createActivityLogNotes = (requestPayload) => {
  return (dispatch) => {
    dispatch(isLoading())
    const authCode = {
      headers: { Authorization: token() },
    }
    setHeaders()
    Axios.post('/user/createActivityLogNotes', requestPayload, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const successActivityLogNotesListing = (listing) => {
  return {
    type: actionTypes.ACTIVITY_LOG_NOTES_LIST,
    listing,
  }
}

export const activityLogNotesList = (id) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    const userId = localStorage.getItem('logedUserId')
    //Axios.get("/user/getUserDetails?id="+userId, authCode)
    setHeaders()
    Axios.get('/user/getActivityLogNotes?id=' + id, authCode)
      .then((response) => {
        dispatch(successActivityLogNotesListing(response))
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(FailUserListing(err.response.data.message))
        }
      })
  }
}

export const is_login = (id) => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    const userId = localStorage.getItem('logedUserId')
    setHeaders()
    Axios.get('/user/getUserDetails?id=' + userId, authCode)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload.isInactive) {
            localStorage.clear()
            window.location = '/login'
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}
