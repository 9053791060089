import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  resetErrorAndSuccessState,
  uploadOta,
} from '../store/actions/otaActions'
import { companyList } from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import { Redirect, Link } from 'react-router-dom'
import { TextField, Button, Select } from '@material-ui/core'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const AddOta = (props) => {
  var { enqueueSnackbar, error, success, loading, is_redirect } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [pageLoading, setPageLoading] = React.useState(true)
  const [imgs, setImgs] = React.useState('')
  let authRedirect = null
  if (is_redirect) {
    authRedirect = <Redirect to="/ota" />
  }

  const { company_List } = useSelector((state) => state.company)

  const [values, setValues] = React.useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  const [formValidation, setFormValidation] = React.useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }
    if (!values.version) {
      isError = true
      formerr.version = 'Required version'
      setFormValidation(formerr)
    }

    if (!imgs) {
      isError = true
      formerr.zip_file = 'Required file'
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value = event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleImageChange = (event) => {
    setImgs(event.target.files[0])
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false
    const Data = {
      version: values.version,
      zip_file: imgs,
      description: values.description,
    }
    dispatch(uploadOta(Data))
  }

  useEffect(() => {
    dispatch(companyList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    resetDeviceGroupForm()
    setValues({ version: '', imgs: '', description: '' })
  }, [success])

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const resetDeviceGroupForm = () => {
    var resetForm = { ...values }
    resetForm['old_password'] = ''
    resetForm['new_password'] = ''
    resetForm['confirm_password'] = ''
    setValues(resetForm)
  }

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      {authRedirect}
      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div className="card-header">
                {/* <div className="row">
                  <div className="col-md-3 "></div>
                  <div className='col-lg-9'>
                    <Link className='btn btn-primary float-right' to='/ota'>View</Link>
                    </div>           
                    </div> */}
                <div className="row" style={{ marginBottom: '170px' }}>
                  <div className="col-md-3 "></div>
                  <div className="col-md-6 ">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="cname"
                          fullWidth
                          label="Version Text (required)"
                          variant="outlined"
                          name="description"
                          value={values.description}
                          onChange={handleChange('description')}
                          error={!!formValidation.description}
                          helperText={formValidation.description}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="cname"
                          type="number"
                          fullWidth
                          label="Version Number (required)"
                          variant="outlined"
                          name="version"
                          value={values.version}
                          onChange={handleChange('version')}
                          error={!!formValidation.version}
                          helperText={formValidation.version}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <TextField
                          id="lname"
                          fullWidth
                          type="file"
                          variant="outlined"
                          name="zip_file"
                          value={values.zip_file}
                          onChange={handleImageChange}
                          error={!!formValidation.zip_file}
                          helperText={formValidation.zip_file}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitClick}
                        >
                          Upload &nbsp;
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{
                              display: props.loading ? 'block' : 'none',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.ota.loading,
    error: state.ota.error,
    is_redirect: state.ota.is_redirect,
  }
}

AddOta.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(AddOta))
