import React, { useEffect, useState, useMemo } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Login from './Login'
import ResetExpiredPassword from './ResetExpiredPassword'
import Master from './Master'
import AuthenticatedRoute from './components/AuthenticatedRoute/index'
import Forgotpassword from './Forgotpassword'
import Resetpassword from './Resetpassword'
import TwoFactorAuth from './TwoFactorAuth'
import { MuiThemeProvider, createTheme } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getSentinelAlerts } from './store/actions/auth'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e4ca1',
    },
  },
})

export default function App() {
  const dispatch = useDispatch()

  let { alert } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getSentinelAlerts())
  }, [dispatch])

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={1}
          dense
          autoHideDuration={5000}
        >
          {(alert !== undefined) & (alert !== null) ? (
            alert.closable == '1' ? (
              <Alert
                id="banner_alert"
                severity={alert.type}
                onClose={() => {
                  document.getElementById('banner_alert').remove()
                }}
              >
                <div className="justify-content-center">
                  <AlertTitle>
                    <strong>{alert.title}</strong>
                  </AlertTitle>
                  {alert.message}
                </div>
              </Alert>
            ) : (
              <Alert id="banner_alert" severity={alert.type}>
                <div className="justify-content-center">
                  <AlertTitle>
                    <strong>{alert.title}</strong>
                  </AlertTitle>
                  {alert.message}
                </div>
              </Alert>
            )
          ) : (
            ''
          )}

          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/resetExpiredPassword"
              component={ResetExpiredPassword}
            />
            <Route exact path="/forgotPassword" component={Forgotpassword} />
            <Route exact path="/resetPassword/:id" component={Resetpassword} />
            <Route
              exact
              path="/twoFactorAuthentication"
              component={TwoFactorAuth}
            />
            <AuthenticatedRoute path="/" component={Master} />
          </Switch>
        </SnackbarProvider>
      </Router>
    </MuiThemeProvider>
  )
}
