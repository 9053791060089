import * as actionTypes from '../actions/actionTypes'
const initialState = {
  company_List: [],
  company_related_device_List: {
    details: [],
    company: '',
  },
  error: '',
  loading: false,
  authRedirectPath: '/',
  success: '',
  message: null,
  pageLoading: false,
  company_Details: {
    company_name: '',
    poc_first_name: '',
    poc_last_name: '',
    poc_e_mail: '',
    poc_country_code: '',
    poc_phone_number: '',
    is_deactive: false,
    company_type: '',
    parent_company_id: '',
    company_id: '',
  },
  companyMailerSetting: {
    id: '',
    company_id: '',
    host_name: '',
    port_name: '',
    user_name: '',
    user_password: '',
  },
  company_Request_List: [],
  var_Panel_List: [],
  company_Stock_List: {
    companyStock: [],
    company: '',
  },
  invoice_List: [],
  company_related_invoice_List: [],
  invoice_Detail_List: {},
  company_Drop_Down_List: [],
  create_Customer_Invoice_detail: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ISLOADING:
      return {
        ...state,
        error: null,
        success: null,
        loading: true,
      }
    case actionTypes.ISPAGELOADING:
      return {
        ...state,
        error: null,
        pageLoading: true,
      }
    case actionTypes.COMPANY_LIST:
      return {
        ...state,
        company_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_RELATED_DEVICE_LIST:
      return {
        ...state,
        company_related_device_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case actionTypes.SUCCESS:
      return {
        ...state,
        success: action.success.message,
        company_Details: action.success.payload,
        loading: false,
      }
    case actionTypes.REST_ERROR_AND_SUCCESS_STATE:
      return {
        ...state,
        error: null,
        success: null,
        loading: false,
        // pageLoading: false
      }
    case actionTypes.COMPANY_DETAILS:
      return {
        ...state,
        company_Details: action.payload,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_MAILER_SETTING:
      return {
        ...state,
        companyMailerSetting: action.payload,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_REQUEST_LIST:
      return {
        ...state,
        company_Request_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_REQUEST_REJECTED:
      return {
        ...state,
        success: action.success.message,
        loading: false,
      }
    case actionTypes.COMPANY_REQUEST_ACCEPTED:
      return {
        ...state,
        success: action.success.message,
        loading: false,
      }
    case actionTypes.VAR_PANEL_LIST:
      return {
        ...state,
        var_Panel_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.INVOICE_LIST:
      return {
        ...state,
        invoice_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_RELATED_INVOICE_LIST:
      return {
        ...state,
        company_related_invoice_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.INVOICE_DETAIL:
      return {
        ...state,
        invoice_Detail_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.CREATE_CUSTOMER_INVOICE_DETAIL:
      return {
        ...state,
        create_Customer_Invoice_detail: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.UPDATE_CREATE_CUSTOMER_INVOICE_LINE_ITEM:
      return {
        ...state,
        create_Customer_Invoice_detail: {
          ...state.create_Customer_Invoice_detail,
          invoice_line_items: action.list,
        },
      }
    case actionTypes.COMPANY_DROP_DOWN_LIST:
      return {
        ...state,
        company_Drop_Down_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    case actionTypes.COMPANY_STOCK_LIST:
      return {
        ...state,
        company_Stock_List: action.listing,
        error: null,
        loading: false,
        pageLoading: false,
      }
    default:
      return state
  }
}

export default reducer
